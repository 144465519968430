import React, { useState, useRef } from 'react';
import { Row, Col } from 'antd';
import OrderDetails from '../OrderDetails';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { usePendingOrders } from '~/hooks/order';
import PendingOrdersSkeleton from './PendingOrdersSkeleton';
import OrderDetailsSkeleton from '../OrderDetails/OrderDetailsSkeleton';
import { ORDER_STATUS_NAME } from '~/constants/defaultValue';
import moment from 'moment';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  UncontrolledAlert
} from 'reactstrap';
import './pending-order.scss';

function AlertInfo({ title, description }) {
  return (
    <UncontrolledAlert
      color="info"
      className="alert-border text-center"
      role="alert"
    >
      <i className="mdi mdi-information d-block display-4 mt-2 mb-3 text-info"></i>
      <h5 className="text-info">{title}</h5>
      <p className="mb-3">{description}</p>
    </UncontrolledAlert>
  );
}

const PendingOrders = () => {
  const myRef = useRef(null);
  const [orders, isLoading, onRefresh] = usePendingOrders();
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
  const selectOrder = (orderNumber, index) => {
    setSelectedOrderNumber(orderNumber);
    if (index >= 4) {
      myRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="page-wraper page-content" ref={myRef}>
      <div className="container-fluid pending-order">
        <Breadcrumb title="Đơn hàng đang chờ" />
        {!isLoading && !orders?.length && (
          <AlertInfo
            title="Không có đơn hàng"
            description="Hiện tại bạn không có đơn hàng nào cần xử lý"
          />
        )}
        <Row gutter={[24, 24]}>
          <Col sm={24} md={8}>
            {isLoading && <PendingOrdersSkeleton />}
            {orders?.map((order, index) => (
              <Card
                style={{cursor: 'pointer'}}
                key={order.orderNumber}
                className={`border order-card ${
                  selectedOrderNumber == order.orderNumber ? 'active' : ''
                }`}
                onClick={() => selectOrder(order.orderNumber, index)}
              >
                <CardBody>
                  <CardTitle className="h4 mt-0">
                    <div>
                      # {order.orderNumber} - {order.shippingAddress?.fullName}
                    </div>
                    <div
                      className={`order-status ${order.status.toLowerCase()}`}
                    >
                      {ORDER_STATUS_NAME[order.status]}
                    </div>
                  </CardTitle>
                  <CardText>
                    <i className="mdi mdi-map-marker-radius-outline" />
                    {`${order.shippingAddress?.street}, ${order.shippingAddress?.ward}, ${order.shippingAddress?.district}, ${order.shippingAddress?.city}`}
                  </CardText>
                  <CardText className="phone">
                    <i className="mdi mdi-phone" />
                    {order.shippingAddress?.phoneNumber}
                  </CardText>
                  <small className="time text-muted">
                    <i className="mdi mdi-clock-outline" />
                    {moment(order.createdAt).format('YYYY-MM-DD HH:mm')}
                  </small>
                </CardBody>
              </Card>
            ))}
          </Col>
          <Col sm={24} md={16}>
            {isLoading && !selectedOrderNumber && <OrderDetailsSkeleton />}
            {selectedOrderNumber && (
              <Card className="border">
                <CardBody className="p-4">
                  <OrderDetails
                    setSelectedOrderNumber={setSelectedOrderNumber}
                    onRefreshList={onRefresh}
                    orderNumber={selectedOrderNumber}
                  />
                </CardBody>
              </Card>
            )}
            {orders?.length && !selectedOrderNumber ? (
              <AlertInfo
                title="Chọn đơn hàng"
                description="Vui lòng chọn đơn hàng bạn muốn xem hoặc cập nhật"
              />
            ) : null}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PendingOrders;
