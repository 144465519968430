import React, { Suspense, useState, lazy } from 'react';
import { Button, Col, Form, Modal, Row, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import POLICY from '~/constants/policy';
import { useMatchOrPolicy } from '~/hooks';
import {
  useDeleteBoard,
  useGetAllBoard,
  useGetPagingTodoList,
  useUpdateWorkFlowParams,
  useWorkFlowQueryParams,
} from '~/hooks/workFlow';
import { Breadcrumb, WithPermission } from '../Common';
import TabBranch from '../Common/TabBranch';
import SkeletonTable from '../Utils/SkeletonTable';
import { useActionColumn, useColumnsBoard } from './constants';
import { useExpandrowTableClick } from '~/utils/helper';

const BoardForm = lazy(() => import('./TaskForm/BoardForm.js'));
const BoardFormDetail = lazy(() => import('./TaskForm/BoardFormDetail.js'));

export default function WorkFlow() {
  const canUpdateAndDelete = useMatchOrPolicy([POLICY.UPDATE_TODOLIST, POLICY.DELETE_TODOLIST]);
  const [form] = Form.useForm();
  const { select, setSelect, onClick } = useExpandrowTableClick();
  const [isOpenForm, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [query] = useWorkFlowQueryParams();
  const [keyword, { onParamChange, setKeyword }] = useUpdateWorkFlowParams(query);
  const [isSubmitLoading, deleteWorkList] = useDeleteBoard();
  const [board, isLoadingList] = useGetAllBoard(query);
  const [openDetail, setOpenDetail] = useState(false);
  const paging = useGetPagingTodoList();
  const handleDelete = (id) => {
    deleteWorkList({ id });
  };
  const handleOpenUpdate = (id) => {
    setOpen(true);
    if (id) {
      setId(id);
    }
  };

  const handleOpenFormDetail = (id) => {
    setOpenDetail(true);
    if (id) {
      setId(id);
    }
  };
  const handleOpenFormCreate = () => {
    setOpen(true);
    setId(null);
  };
  const handleCloseForm = () => {
    setOpen(false);
    form.resetFields();
  };
  const columns = useColumnsBoard({ handleOpenFormDetail });
  const columnsAction = useActionColumn({ handleDelete, handleOpenUpdate });

  return (
    <div className="branch-detail page-wraper page-content page-workflow wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Quản lý không gian làm việc" />

        <div className="page-content__main">
          <div className="page-content__left">
            <Search
              placeholder={`Nhập để tìm...`}
              enterButton
              allowClear
              onSearch={(value) => {
                onParamChange({ keyword: value?.trim() });
              }}
              style={{ maxWidth: '500px' }}
              onChange={(e) => {
                setKeyword(e.target.value);
                if (e.target.value === '') {
                  onParamChange({ keyword: null });
                }
              }}
              value={keyword}
            />
          </div>
          <div className="page-content__right">
            <div className="page-wraper__header">
              <Row gutter={48}>
                <Col span={24}>
                  <WithPermission permission={POLICY.WRITE_TODOLIST}>
                    <Col span={24} align="end">
                      <Button
                        style={{ marginRight: -22 }}
                        type="primary"
                        onClick={handleOpenFormCreate}
                      >
                        Thêm không gian
                      </Button>
                    </Col>
                  </WithPermission>
                </Col>
              </Row>
            </div>
            {isLoadingList && !(board ?? []).length ? (
              <SkeletonTable
                columns={columns.concat(
                  canUpdateAndDelete ? columnsAction : []
                )}
                dataSource={[]}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                onChange={({ current }) => {
                  onParamChange({ page: current });
                }}
              />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns.concat(
                  canUpdateAndDelete ? columnsAction : []
                )}
                dataSource={board}
                onRow={(item) => ({
                  onClick: onClick(item)
                })}
                pagination={false}
                expandable={{
                  expandedRowKeys: select,
                  onExpandedRowsChange: (e) => {
                    setSelect(e);
                  }
                }}
                onChange={({ current }) => {
                  onParamChange({ page: current });
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={isOpenForm}
        footer={null}
        onCancel={() => setOpen(false)}
        width={700}
        destroyOnClose={true}
      >
        <Suspense fallback={<div>...</div>}>
          <BoardForm id={id} handleCloseForm={handleCloseForm} />
        </Suspense>
      </Modal>
      <Modal
        visible={openDetail}
        footer={null}
        onCancel={() => setOpenDetail(false)}
        width={1200}
        destroyOnClose
      >
        <Suspense fallback={<div>...</div>}>
          <BoardFormDetail id={id} setOpenDetail={setOpenDetail} />
        </Suspense>
      </Modal>
    </div>
  );
}
