import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Form, Menu, Modal, Select, Table, Tag, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import Text from 'antd/lib/typography/Text';
import { forIn, get, head, keys } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { STATUS_PARTNER_CANCEL_BILL, TYPE_CANCEL_PARTNER } from '~/constants/defaultValue';
import { useMatchPolicy } from '~/hooks';
import { useDeletePartnerCancelAppointment, usePartnerCancelAppointmentPaging, usePartnerCancelAppointmentQueryParams, usePartnerCancelAppointments, useResetPartnerCancelAppointment, useUpdatePartnerCancelAppointment, useUpdatePartnerCancelAppointmentParams } from '~/hooks/partnerCancelAppointment';
import { Breadcrumb } from '../Common';
import ModalConfirm from '../Common/ModalConfirm';
import SkeletonTable from '../Utils/SkeletonTable';
import FormRefuse from './FormRefuse';
import './index.scss';
import POLICY from '~/constants/policy';
import TabBranch from '../Common/TabBranch';
const listOptionSearch = [
  {
    value: 'billNumber',
    label: 'Mã đơn hàng'
  },
  {
    value: 'requestNumberCancel',
    label: 'Mã yêu cầu'
  },
  {
    value: 'reason',
    label: 'Lý do huỷ'
  },
  {
    value: 'partnerName',
    label: 'Tên đối tác'
  },
  {
    value: 'partnerCodeSeq',
    label: 'Mã đối tác'
  },
];
export default function PartnerCancelAppointment() {

  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHAPPOINTMENTCANCELPARTNER);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHAPPOINTMENTCANCELPARTNER);
  const [form] = Form.useForm()
  const [selectSearch, setSelectSearch] = useState(get(head(listOptionSearch), 'value', ''));
  const [id, setId] = useState(null)
  const [selectRequest, setSelectRequest] = useState(null)
  // hook
  const [query] = usePartnerCancelAppointmentQueryParams(listOptionSearch)
  const [partnerCancelAppointments, isLoading] = usePartnerCancelAppointments(query)
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [keyword, { setKeyword, onParamChange }] = useUpdatePartnerCancelAppointmentParams(query, listOptionSearch)
  const paging = usePartnerCancelAppointmentPaging(query)
  useResetPartnerCancelAppointment()
  //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
  };
  //UPDATE//
  const handleCloseModalConfirm = () => {
    setSelectRequest(null)
    setIsModalConfirmOpen(false)
  }
  const [submitLoading, updatePartnerCancelAppointment] = useUpdatePartnerCancelAppointment(handleCloseModalConfirm);
  const handleUpdateRequest = ({_id, status,messageFromAdmin,dateApproved}) => {
    updatePartnerCancelAppointment({ _id, status,messageFromAdmin ,dateApproved})
  }
  //UPDATE//
  //MODAL CONFIRM//

  const handleOpenModalConfirm = (data) => {
    const {status} = data
    if([STATUS_PARTNER_CANCEL_BILL.APPROVED.value, STATUS_PARTNER_CANCEL_BILL.REFUSE.value].includes(status)){ // open modal
      setSelectRequest(data)
      setIsModalConfirmOpen(true)
    }
    else{
      handleUpdateRequest(data)
    }

  }

    //MODAL CONFIRM//
      ///Delete///
  const handleOpenModalDelete = (idSelect) => {
    setId(idSelect)
    setIsOpenModalDelete(true)
  }
  const handleCloseModalDelete = () => {
    setId(null)
    setIsOpenModalDelete(false)
  }
  const [isSubmitLoading, deletePartnerCancelAppointment] = useDeletePartnerCancelAppointment(handleCloseModalDelete);
  const handleDelete = () => {
    deletePartnerCancelAppointment(id);
  }
  ///Delete///


  //FORM////////////////
  const onValuesChange = (value, allValues) => {
    // forIn(allValues, (values, key, obj) => { obj[key] = null })
    onParamChange({ ...allValues, ...value })
  }
  //FORM////////////////
  ////INIT VALUE
  useEffect(() => {
    const { status, isLate } = query
    form.setFieldsValue({ status, isLate: parseInt(isLate || 0) || null })
  }, [query])
  useEffect(() => {
    let value = listOptionSearch?.find(e => query[e.value])
    setSelectSearch(get(value, 'value', head(listOptionSearch).value))
    setKeyword(query[get(value, 'value', '')])
  }, [])

  ////INIT VALUE
  const columns = [
    {
      title: 'Tên đối tác',
      dataIndex: 'partner',
      key: 'name',
      align: 'center',
      render: (item, record, index) => get(item, 'name', '')
    },
    {
      title: 'Mã đối tác',
      dataIndex: 'partner',
      key: 'partnerCodeSeq',
      align: 'center',
      render: (item, record, index) => `WH${get(item, 'partnerCodeSeq', '')}`
    },
    {
      title: 'Mã phiếu huỷ',
      dataIndex: 'requestNumberCancel',
      key: 'requestNumberCancel',
      align: 'center',
      render: (item) => <Text strong>{item}</Text>
    },
    {
      title: 'Yêu cầu huỷ',
      dataIndex: 'type',
      key: 'type',
      render: (item, record) => {
        if (item === TYPE_CANCEL_PARTNER.appointment.value) return <Text>Huỷ {TYPE_CANCEL_PARTNER.appointment.vi} <a target='_blank' href={`wh-bill-item/${get(record, 'whBillItemId', '')}/update-appointments`}>{get(record, 'whAppointment.code', '')}</a></Text>
        if (item === TYPE_CANCEL_PARTNER.block.value) return <Text>Huỷ {TYPE_CANCEL_PARTNER.block.vi} <Text strong>{get(record, 'blockNumber')}</Text> <a target='_blank' href={`wh-bill-item/${get(record, 'whBillItemId', '')}/update-appointments`}>{get(record, 'whBillItem.code', '')}</a></Text>
        if (item === TYPE_CANCEL_PARTNER.bill.value) return <Text>Huỷ {TYPE_CANCEL_PARTNER.bill.vi} <a target='_blank' href={`wh-bill-item/${get(record, 'whBillItemId', '')}/update-appointments`}>DV{get(record, 'whBill.billNumber', '')}</a></Text>
      }
    },
    {
      title: 'Loại huỷ',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (item) => <Text strong>{get(TYPE_CANCEL_PARTNER,`${item}.vi`,'')}</Text>
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'whBill',
      key: 'whBill',
      align: 'center',
      render: (item) => <a target='_blank' href={`/wh-bill/?page=1&limit=10&keyword=${get(item, 'billNumber')}&status=&withBillItems=true`}>{get(item, 'billNumber')}</a>
    },
    {
      title: 'Lý do huỷ',
      dataIndex: 'reason',
      key: 'reason',
      align : 'center',
      render: (item, record, index) => <>
      <Typography>{item}</Typography>
      <Text type='secondary'>{ moment(get(record,'createdAt')).format("DD-MM-YYYY HH:mm:ss")}</Text>
    </>
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (item, record, index) => <span className={`request-status ${item}`}>{STATUS_PARTNER_CANCEL_BILL[item].vi}</span>
    },
    {
      title: 'Tình trạng',
      dataIndex: 'isLate',
      key: 'isLate',
      align: 'center',
      render: (item) => item ? <Tag color='red'>Huỷ trễ</Tag> : <Tag color='blue'>Huỷ sớm</Tag>
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      width: '100px',
      key: 'action',
      render: (item, record, index) => <Dropdown
        overlayStyle={{ width: 150 }}
        trigger={['click']}
        placement='bottomCenter'
        overlay={
          <Menu>
            {keys(STATUS_PARTNER_CANCEL_BILL)?.map(e => {
              if(!canUpdate) return <></>
              const isDisabled = STATUS_PARTNER_CANCEL_BILL[e].disabled.includes(get(record, 'status'))
              return ![get(record, 'status')].includes(e) && <Menu.Item disabled={isDisabled} key={v4()} onClick={() => handleOpenModalConfirm({ _id: get(record, '_id'), status: e })}>
                <p>{STATUS_PARTNER_CANCEL_BILL[e].vi}</p>
              </Menu.Item>
            })
            }
            {canDelete ?<Menu.Item
              disabled={![STATUS_PARTNER_CANCEL_BILL.APPROVED.value, STATUS_PARTNER_CANCEL_BILL.REFUSE.value].includes(get(record, 'status'))}
              onClick={() => handleOpenModalDelete(get(record,'_id'))}
              key={v4()}
            >

              <p>Xoá</p>
            </Menu.Item> : null}
          </Menu>
        }
      >
        <a className="ant-dropdown-link" href="#">
          Action <CaretDownOutlined />
        </a>
      </Dropdown>
    },
  ]
  let optionStatus = []
  forIn(STATUS_PARTNER_CANCEL_BILL, (value, key) => optionStatus.push({ value: key, label: get(value, 'vi') }))
  return (
    <div className="page-wraper page-content wh-bills-page">
      <TabBranch>
        <div className="container-fluid">
          <Breadcrumb title="Yêu cầu huỷ cuộc hẹn" />
          <div className="page-content__main">
            <div className="page-content__left">
              <Form
                form={form}
                autoComplete="off"
                onFinish={() => {}}
                scrollToFirstError
                onValuesChange={onValuesChange}
                requiredMark={false}
                labelCol={{ sm: 24, md: 24, lg: 24 }}
                layout='vertical'
                // initialValues={{ managementArea: managementAreaQuery }}
              >
                <Form.Item label='Tìm kiếm'>
                  <Search
                    style={{ width: '100%' }}
                    placeholder={`Tìm ${
                      listOptionSearch.find((e) => e.value === selectSearch)
                        .label
                    }`}
                    enterButton
                    allowClear
                    onSearch={async (value) => {
                      onParamChange({ [selectSearch]: value });
                    }}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                </Form.Item>
                <Form.Item label="Tìm kiếm theo:">
                  <Select
                    value={selectSearch}
                    style={{
                      width: '100%'
                    }}
                    onChange={handleChangeSelectSearch}
                    options={listOptionSearch}
                  />
                </Form.Item>
                <Form.Item label="Trạng thái" name="status">
                  <Select
                    allowClear
                    options={optionStatus}
                    placeholder="Hãy chọn trạng thái"
                  />
                </Form.Item>
                <Form.Item label="Tình trạng" name="isLate">
                  <Select
                    placeholder="Hãy chọn tình trạng"
                    allowClear
                    options={[
                      { label: 'Huỷ trễ', value: 1 },
                      { label: 'Huỷ sớm', value: -1 }
                    ]}
                  />
                </Form.Item>
              </Form>
            </div>
            <div className="page-content__right" style={{ width: '80%' }}>
              {/* <div
                className="page-wraper__header"
                style={{ justifyContent: 'space-between', display: 'flex' }}
              >
                <div>
                  <Select
                  value={selectSearch}
                  style={{
                    width: 170,
                    marginRight: 36
                  }}
                  onChange={handleChangeSelectSearch}
                  options={listOptionSearch}
                />
                  <Search
                    style={{ width: 300 }}
                    placeholder={`Tìm ${
                      listOptionSearch.find((e) => e.value === selectSearch)
                        .label
                    }`}
                    enterButton
                    allowClear
                    onSearch={async (value) => {
                      onParamChange({ [selectSearch]: value });
                    }}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                </div>
                <Button onClick={() => handleOpenModal()} type='primary'>Thêm yêu cầu huỷ cuộc hẹn</Button>
              </div> */}
              {isLoading ? (
                <SkeletonTable columns={columns} rowCount={10} />
              ) : (
                <Table
                  rowKey={(rc) => rc._id}
                  columns={columns}
                  //  scroll={{x : 1700}}
                  dataSource={partnerCancelAppointments}
                  size="middle"
                  onChange={({ current }) => onParamChange({ page: current })}
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </TabBranch>
      <ModalConfirm
        text="Xác nhận xoá"
        textOk="Xác nhận"
        type="delete"
        loading={isSubmitLoading}
        visible={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        handleOk={handleDelete}
      />
      <Modal
        title={`Xác nhận chuyển sang ${
          STATUS_PARTNER_CANCEL_BILL[get(selectRequest, 'status')]?.vi
        }`}
        visible={isModalConfirmOpen}
        onCancel={handleCloseModalConfirm}
        destroyOnClose
        footer={null}
      >
        <FormRefuse
          loading={submitLoading}
          selectRequest={selectRequest}
          handleUpdateRequest={handleUpdateRequest}
          onCancel={handleCloseModalConfirm}
        />
      </Modal>
    </div>
  );
}
