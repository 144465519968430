import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Tabs
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { get, isString } from 'lodash';

import { useStaffGroups } from '~/hooks/staffGroup';
import {
  useUpdateWhPartnerParams,
  useWhPartnerQueryParams
} from '~/hooks/whPartner';
import { useWhServices, useWhServiceQueryParams } from '~/hooks/whService';
import { useMatchPolicy, useProfile, useUser } from '~/hooks';

import { Breadcrumb, GeoTreeSelect } from '~/components/Common';
import { PATH_APP } from '~/routes/paths';
import {
  RELATIVE_POSITION,
  WH_COURSE_TRAINING_STATUS,
  WH_COURSE_TRAINING_STATUS_VI,
  WH_PARTNER_STATUS
} from '~/constants/defaultValue';

import WhPartners from './WhPartners';

import './index.scss';
import TimeTraining from './Form/TimeTraining';
import { v4 } from 'uuid';
import LogTimeList from './Form/LogTimeList';
import { useLogCourseTrainingOfCourseAll } from '~/hooks/timeSheet';
import moment from 'moment';
import POLICY from '~/constants/policy';
import ToolTipButtonPermission from '../Utils/ToolTipButtonPermission';
import TabBranch from '../Common/TabBranch';

const { TabPane } = Tabs;
const { Search } = Input;
const optionsSearchTimeSheet = [
  { value: 'partnerCodeSeq', label: 'Mã nhân viên' },
  { value: 'name', label: 'Tên nhân viên' },
  { value: 'phone', label: 'Số điện thoại' },
  { value: 'status', label: 'Trạng thái' },
  { value: 'fullName', label: 'NV chịu trách nhiệm' },
  { value: 'courseTraningId', label: 'Mã khoá đào tạo' },
  { value: 'totalReceived', label: 'Tổng tiền' },
  { value: 'employeeGroup', label: 'Nhóm đối tác' },
  { value: 'date', label: 'Ngày' },
  { value: 'month', label: 'Tháng' },
  { value: 'quarter', label: 'Quý' }
];

const WhPartnerTabs = ({ history }) => {
  const [query] = useWhPartnerQueryParams();
  const [{ onParamChange }] = useUpdateWhPartnerParams(query);
  useEffect(() => {
    if (!query.startDate && !query.endDate) {
      onParamChange({ startDate: null, endDate: null });
    }
  }, [query]);
  const [keyword, setKeyword] = useState('');
  const [searchBy, setSearchBy] = useState('name');
  const [activeKey, setActiveKey] = useState(get(query, 'tabs', '1'));
  const [logTimeSheet, isLoadingTimeSheet, paging, onChangePage] =
    useLogCourseTrainingOfCourseAll();
  const [staffGroups, isStaffGroupLoading] = useStaffGroups();
  const [queryWhService, onPagingWhServiceChange] = useWhServiceQueryParams();
  const [whServices, isWhServicesLoading] = useWhServices(queryWhService);
  const canWrite = useMatchPolicy(POLICY.WRITE_WHPARTNER);
  const [, token] = useUser();
  const [profile] = useProfile(token);
  const isRootAdmin = get(profile, 'isSuperAdmin');
  const managementPaths = useMemo(() => {
    return get(profile, 'managementArea')?.map((item) => get(item, 'path'));
  }, [profile]);
  const onChangeDate = (date, dateString, key) => {
    const startDate = moment(date).startOf(key).format('YYYY-MM-DD');
    const endDate = moment(date).endOf(key).format('YYYY-MM-DD');
    onParamChange({ startDate, endDate });
  };
  const onSearch = () => {
    let searchParams = `?page=${query.page}&limit=${query.limit}&tabs=${activeKey}`;
    let newKeywords = keyword.trim()
    if (keyword) searchParams += `&${searchBy}=${newKeywords}`;
    if (activeKey === '2') {
      searchParams += `&waitingApproval=true`;
    }
    history.push({
      pathname: '/wh-partner',
      search: searchParams
    });
  };
  const onSearchInit = (activeKeyParam) => {
    let searchParams = `?page=${query.page}&limit=${query.limit}&tabs=${
      activeKeyParam || activeKey
    }`;
    if (!activeKeyParam) {
      if (activeKey === '2') {
        searchParams += `&waitingApproval=true`;
      }
    } else {
      if (activeKeyParam === '2') {
        searchParams += `&waitingApproval=true`;
      }
    }
    history.push({
      pathname: '/wh-partner',
      search: searchParams
    });
  };

  useEffect(() => {
    // for following searchBy types, onSearch is triggered immediately
    // when keyword is changed.
    // for `name` and `code`, onSearch is triggered when user hits Enter button.
    if (['employeeGroup', 'services', 'managementArea'].includes(searchBy)) {
      onSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const onTabClick = (key) => {
    setKeyword('');
    setActiveKey(key);
    setSearchBy('name');
    onParamChange({ page: 1 });
    onSearchInit(key);
  };
  useEffect(() => {
    if (get(query, 'tabs') === '3') {
      for (const [key, value] of Object.entries(query)) {
        if (!!value) {
          const queryExist = optionsSearchTimeSheet?.find(
            (item) => item.value === key
          );
          if (queryExist) {
            setKeyword(value);
            setSearchBy(key);
          }
        }
      }
    }
  }, []);
  let listSearch = {};
  optionsSearchTimeSheet?.map((item) => {
    switch (item.value) {
      case 'status':
        return (listSearch[item.value] = (
          <Select
            style={{ width: '100%' }}
            onChange={(value) => onParamChange({ status: value })}
            options={[
              {
                label: WH_COURSE_TRAINING_STATUS_VI.NEW,
                value: WH_COURSE_TRAINING_STATUS.NEW
              },
              {
                label: WH_COURSE_TRAINING_STATUS_VI.APPROVED,
                value: WH_COURSE_TRAINING_STATUS.APPROVED
              },
              {
                label: WH_COURSE_TRAINING_STATUS_VI.DENIED,
                value: WH_COURSE_TRAINING_STATUS.DENIED
              }
            ]}
          />
        ));
      case 'employeeGroup':
        return (listSearch[item.value] = (
          <Select
            allowClear
            style={{ width: '100%' }}
            loading={isStaffGroupLoading}
            showSearch
            autoComplete="off"
            onChange={(e) => setKeyword(e)}
          >
            {staffGroups.map(({ id, _id, name }) => (
              <Option key={id || _id} value={id || _id}>
                {name}
              </Option>
            ))}
          </Select>
        ));
      case 'date':
        return (listSearch[item.value] = (
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Tìm theo ngày..."
            onChange={(date, dateString) =>
              onChangeDate(date, dateString, 'date')
            }
          />
        ));
      case 'month':
        return (listSearch[item.value] = (
          <DatePicker
            picker="month"
            style={{ width: '100%' }}
            placeholder="Tìm theo tháng..."
            onChange={(date, dateString) =>
              onChangeDate(date, dateString, 'month')
            }
          />
        ));
      case 'quarter':
        return (listSearch[item.value] = (
          <DatePicker
            picker="quarter"
            style={{ width: '100%' }}
            placeholder="Tìm theo quý..."
            onChange={(date, dateString) =>
              onChangeDate(date, dateString, 'quarter')
            }
          />
        ));
      default:
        return (listSearch[item.value] = (
          <Search
            allowClear
            style={{ width: '100%' }}
            placeholder={`Tìm theo ${item.label}`}
            enterButton
            onSearch={(value) =>
              onParamChange({ [item.value]: value, tabs: '3' })
            }
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />
        ));
    }
    // if (item.value === 'status') {
    //   return (listSearch[item.value] = (
    //     <Select
    //       style={{ width: '100%' }}
    //       onChange={(value) => onParamChange({status: value})}
    //       options={[
    //         {
    //           label: WH_COURSE_TRAINING_STATUS_VI.NEW,
    //           value: WH_COURSE_TRAINING_STATUS.NEW
    //         },
    //         {
    //           label: WH_COURSE_TRAINING_STATUS_VI.APPROVED,
    //           value: WH_COURSE_TRAINING_STATUS.APPROVED
    //         },
    //         {
    //           label: WH_COURSE_TRAINING_STATUS_VI.DENIED,
    //           value: WH_COURSE_TRAINING_STATUS.DENIED
    //         }
    //       ]}
    //     />
    //   ));
    // }
    // if(item.value === 'date'){
    //   return (listSearch[item.value] = (
    //     <DatePicker style={{width : '100%'}} placeholder='Tìm theo ngày...' onChange={onChangeDate} />
    //   ));
    // } else {
    //   return (listSearch[item.value] = (
    //     <Search
    //       allowClear
    //       style={{ width: '100%' }}
    //       placeholder={`Tìm theo ${item.label}`}
    //       enterButton
    //       onSearch={(value) =>
    //         onParamChange({ [item.value]: value, tabs: '3' })
    //       }
    //       onChange={(e) => setKeyword(e.target.value)}
    //       value={keyword}
    //     />
    //   ));
    // }
  });
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách đối tác" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form 
              layout='vertical'
            >
            {/* <Form.Item label='Thêm mới:'>
              {canWrite ? (
                <Link to={PATH_APP.whPartner.create}>
                  <Button style={{width: '100%'}} type="primary">Thêm mới</Button>
                </Link>
              ) : (
                <ToolTipButtonPermission
                  type="button"
                  titleToolTip="Bạn không có quyền thêm mới"
                  text="Thêm mới"
                />
              )}
            </Form.Item> */}
            <Form.Item vertical label="Tìm kiếm:">
              {searchBy === 'employeeGroup' ? (
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  loading={isStaffGroupLoading}
                  showSearch
                  onChange={(e) => setKeyword(e)}
                >
                  {staffGroups.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              ) : searchBy === 'services' ? (
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  loading={isWhServicesLoading}
                  showSearch
                  onChange={(e) => setKeyword(e)}
                >
                  {whServices.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name?.vi}
                    </Option>
                  ))}
                </Select>
              ) : searchBy === 'managementArea' ? (
                <GeoTreeSelect
                  style={{ width: '100%' }}
                  onChange={(e) => setKeyword(e)}
                  autoClearSearchValue
                  checkablePositions={[
                    RELATIVE_POSITION.IS_CHILD,
                    RELATIVE_POSITION.IS_EQUAL
                  ]}
                  enabledValues={isRootAdmin ? null : managementPaths}
                />
              ) : (
                <Search
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`Tìm theo ${searchBy}`}
                  enterButton
                  onSearch={onSearch}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              )}
            </Form.Item>
            <Form.Item vertical label="Tìm kiếm theo:">
              <Select
                value={searchBy}
                style={{ width: '100%' }}
                onChange={(val) => {
                  setSearchBy(val);
                  setKeyword('');
                  onSearchInit();
                }}
                options={[
                  { value: 'name', label: 'Tên' },
                  { value: 'code', label: 'Mã nhân viên' },
                  { value: 'email', label: 'Email' },
                  { value: 'employeeGroup', label: 'Nhóm đối tác' },
                  { value: 'services', label: 'Dịch vụ' },
                  { value: 'managementArea', label: 'Khu vực hoạt động' }
                ]}
              />
            </Form.Item>
            </Form>
          </div>

          <div className="page-content__right">
            <div className="page-wraper__header">
              <Row justify="end">
                <Col>
                  {canWrite ? (
                    <Link to={PATH_APP.whPartner.create}>
                      <Button type="primary">Thêm mới</Button>
                    </Link>
                  ) : (
                    <ToolTipButtonPermission
                      type="button"
                      titleToolTip="Bạn không có quyền thêm mới"
                      text="Thêm mới"
                    />
                  )}
                </Col>
              </Row>
            </div>

            <Tabs
              onTabClick={onTabClick}
              defaultActiveKey={get(query, 'tabs', '1')}
            >
              <TabPane tab="Tất cả đối tác" key="1">
                <TabBranch>
                  <WhPartners onParamChange={onParamChange} />
                </TabBranch>
              </TabPane>
              <TabPane tab="Đang chờ duyệt" key="2">
                <TabBranch oldQuery="waitingApproval=true">
                  <WhPartners
                    status={WH_PARTNER_STATUS.WAITING}
                    onParamChange={onParamChange}
                  />
                </TabBranch>
              </TabPane>
              <TabPane tab="Thời gian đào tạo" key="3">
                <TabBranch>
                  <TimeTraining
                    searchBy={searchBy}
                    onParamChange={onParamChange}
                  />
                </TabBranch>
              </TabPane>
              <TabPane tab="Tất cả Logs" key="4">
                <TabBranch>
                  <LogTimeList
                    notes={logTimeSheet}
                    LoadingNote={isLoadingTimeSheet}
                  />
                  <Pagination
                    style={{ display: 'flex', justifyContent: 'right' }}
                    showSizeChanger={false}
                    {...paging}
                    size="small"
                    showTotal={(total) => `Tổng cộng: ${total}`}
                    onChange={onChangePage}
                  />
                </TabBranch>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhPartnerTabs;
