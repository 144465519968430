import { CaretDownOutlined } from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Form, Menu, Modal, Select, Switch, Table, Tag, Tooltip, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { forIn, get, head } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { STATUS_GIVE_COUPON } from '~/constants/defaultValue';
import { useMatchPolicy } from '~/hooks';
import {
  useDeleteGiveCoupon, useGiveCouponPaging,
  useGiveCouponQueryParams,
  useGiveCoupons, useResetGiveCoupon, useUpdateGiveCoupon, useUpdateGiveCouponParams
} from '~/hooks/giveCoupon';
import { Breadcrumb } from '../Common';
import ModalConfirm from '../Common/ModalConfirm';
import SkeletonTable from '../Utils/SkeletonTable';
import GiveCouponForm from './GiveCouponForm';
import POLICY from '~/constants/policy';
import { vietnamMoment } from '~/hooks/utils';

const listOptionSearch = [
  {
    value: 'activity',
    label: 'Hoạt động'
  },
  {
    value: 'coupon',
    label: 'Mã tặng'
  },
  {
    value: 'code',
    label: 'Mã hoạt động'
  },

];
const formatTime = 'YYYY-MM-DD HH:mm:ss'
export default function Index() {
    const [form] = Form.useForm()
  const [selectSearch, setSelectSearch] = useState(
    head(listOptionSearch).value
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [id, setId] = useState(null);
  const handleOpenModal = (id) => {
    setIsOpenModal(true);
    if (id) {
      setId(id);
    }
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setId(null);
  };
  // hook
  
  const [query] = useGiveCouponQueryParams();
  const [giveCoupons, isLoading] = useGiveCoupons(query);
  const paging = useGiveCouponPaging(query);
  const [keyword, setKeyword,{onParamChange }] =
    useUpdateGiveCouponParams(query);
    useResetGiveCoupon();
  //
  //POLICY//
  const isMatchPolicyWRITE = useMatchPolicy(POLICY.WRITE_GIVECOUPON);
  const isMatchPolicyUPDATE = useMatchPolicy(POLICY.UPDATE_GIVECOUPON);
  const isMatchPolicyDELETE = useMatchPolicy(POLICY.DELETE_GIVECOUPON);
  //POLICY//
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('');
  };
////////////////////////////////
useEffect(() => {
  const {startDate,mode} = query
  form.setFieldsValue({[mode] : startDate ? moment(startDate) : null})

  let value = listOptionSearch?.find(e => query[e.value])
  setSelectSearch(get(value,'value',head(listOptionSearch).value))
  setKeyword(query[get(value,'value','')])
},[])
// useEffect(() => {
//   let value = listOptionSearch?.find(e => query[e.value])
//   setSelectSearch(get(value,'value',head(listOptionSearch).value))
//   setKeyword(query[get(value,'value','')])
// },[])
//DELETE//
const handleOpenModalDelete = (id) => {
  setId(id)
  setIsOpenModalDelete(true)
}
const handleCloseModalDelete = () => {
  setId(null)
  setIsOpenModalDelete(false)
}
const handleDelete = () => {
  deleteRank(id);
}
const [isSubmitLoading, deleteRank] = useDeleteGiveCoupon(handleCloseModalDelete);
//DELETE//
  const [, updateGiveCoupon] = useUpdateGiveCoupon(handleCloseModal);

////////////////////////////////
  const handleChangeStatus = async(status,record) => {
    const { startDate, endDate,listCoupons } = record;
    const submitData = {
      ...record,
      listCoupons : listCoupons?.map((coupon) => ({couponId : get(coupon,'couponId._id',coupon)})),
      status : status ? STATUS_GIVE_COUPON.ACTIVE : STATUS_GIVE_COUPON.INACTIVE ,
      startDate: startDate ? vietnamMoment(startDate,formatTime) : null,
      endDate: endDate ? vietnamMoment(endDate,formatTime) : null
    };
    await updateGiveCoupon({...submitData,_id : get(record, '_id')})
  }
//   const options = useOptionsSelectAntd(TYPE_RANKING_REFERRAL_VI)
const onValuesChange = (valueOf,allValues) => {
  const key = Object.keys(valueOf)[0]
  const value = valueOf[key]
  if(!value) { // clear all values
    onParamChange({startDate:null,endDate:null,mode:null})
    return
  }
  let startDate =null
  let endDate=null
  switch (key) {
    case 'date':
       startDate = moment(value).startOf('day').format("YYYY-MM-DD HH:mm:ss");
       endDate = moment(value).endOf('d').format("YYYY-MM-DD HH:mm:ss");
      break;
  
    case 'month':
       startDate = moment(value).startOf('month').format("YYYY-MM-DD HH:mm:ss");
       endDate = moment(value).endOf('month').format("YYYY-MM-DD HH:mm:ss");
      break;
    case 'quarter':
       startDate = moment(value).startOf('quarter').format("YYYY-MM-DD HH:mm:ss");
       endDate = moment(value).endOf('quarter').format("YYYY-MM-DD HH:mm:ss");
      break;
    case 'year':
       startDate = moment(value).startOf('year').format("YYYY-MM-DD HH:mm:ss");
       endDate = moment(value).endOf('year').format("YYYY-MM-DD HH:mm:ss");
      break;
  
    default:
      break;
    }
    const listSearch = {}
    listOptionSearch.forEach(e => listSearch[e.value] = null)
    onParamChange({...listSearch,startDate,endDate,mode:key})
    forIn(allValues,(values,key,obj) =>{obj[key] = null})
    form.setFieldsValue({...allValues,...valueOf}) // reset all values and set field for select date
    setKeyword('')
}

  const columns = [
    {
      title: 'Hoạt động',
      dataIndex: 'activity',
      key: 'activity',
      // render: (item, record, index) => item || ''
    },
    {
      title: 'Mã hoạt động',
      dataIndex: 'codeFull',
      key: 'codeFull',
      // render: (item, record, index) => item || ''
    },
    {
      title: 'Mã tặng',
      dataIndex: 'listCoupons',
      key: 'listCoupons',
      render: (item, record, index) => item?.map(e => <Tag style={{maxWidth : '100%',textOverflow : 'ellipsis' , overflow :'hidden'}} color='blue'>{`${get(e,'code',get(e,'couponId.code',''))} - ${get(e,'name',get(e,'couponId.name',''))}`}</Tag>)
    },
    {
      title: 'Ngày bắt đầu chương trình',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (item, record, index) => vietnamMoment(item,formatTime) || ''
    },
    {
      title: 'Ngày kết thúc chương trình',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (item, record, index) => item ? vietnamMoment(item,formatTime) : ''
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width : 100,
      align:'center',
      render: (item, record, index) => isMatchPolicyUPDATE ? <Switch onChange={(checked) => handleChangeStatus(checked,record)}  defaultChecked={item === STATUS_GIVE_COUPON.ACTIVE} />: <Tooltip title="Bạn không có quyền cập nhật" color='red'><Switch disabled defaultChecked={item === STATUS_GIVE_COUPON.ACTIVE} /></Tooltip>
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      width: '100px',
      key: 'action',
      fixed:'right',
      render: (item, record, index) => (
        <Dropdown
          overlayStyle={{ width: 150 }}
          trigger={['click']}
          placement="bottomCenter"
          overlay={
            <Menu>
              {isMatchPolicyUPDATE ?<Menu.Item key={v4()} onClick={() => handleOpenModal(get(record,'_id',''))}>
               <p>Cập nhật</p>
              </Menu.Item> :
              <Menu.Item disabled key={v4()} >
                <Tooltip color='red' title="Bạn không có quyền cập nhât"><p>Cập nhật</p></Tooltip>
              </Menu.Item>}
              {isMatchPolicyDELETE ? <Menu.Item key={v4()} onClick={() => handleOpenModalDelete(get(record,'_id',''))}>
                <p>Xoá</p>
              </Menu.Item> :
              <Menu.Item disabled>
              <Tooltip color='red' title="Bạn không có quyền Xoá"><p>Xoá</p></Tooltip>
              </Menu.Item>}
            </Menu>
          }
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
      )
    },
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Chương trình khuyến mại" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form
              form={form}
              autoComplete="off"
              onFinish={() => {}}
              scrollToFirstError
              onValuesChange={onValuesChange}
              requiredMark={false}
              labelCol={{ sm: 24, md: 24, lg: 24 }}
            >
              <Form.Item>
                <Select
                  value={selectSearch}
                  style={{
                    width: '100%'
                  }}
                  onChange={handleChangeSelectSearch}
                  options={listOptionSearch}
                />
              </Form.Item>

              <Form.Item>
                <Search
                  style={{ width: '100%' }}
                  placeholder={`Tìm ${get(
                    listOptionSearch.find((e) => e.value === selectSearch),
                    'label',
                    ''
                  )}`}
                  enterButton
                  allowClear
                  onSearch={(value) => {
                    const listSearch = {};
                    listOptionSearch.forEach(
                      (e) => (listSearch[e.value] = null)
                    );
                    onParamChange({
                      ...listSearch,
                      startDate: null,
                      endDate: null,
                      mode: null,
                      [selectSearch]: value
                    });
                    form.resetFields();
                  }}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              </Form.Item>

              <Form.Item label="Theo ngày" name="date">
                <DatePicker placeholder="Theo ngày" format="DD-MM-YYYY" />
              </Form.Item>
              <Form.Item label="Theo tháng" name="month">
                <DatePicker placeholder="Theo tháng" picker="month" />
              </Form.Item>
              <Form.Item label="Theo quý" name="quarter">
                <DatePicker placeholder="Theo quý" picker="quarter" />
              </Form.Item>
              <Form.Item label="Theo năm" name="year">
                <DatePicker placeholder="Theo năm" picker="year" />
              </Form.Item>
            </Form>
          </div>
          <div className="page-content__right" style={{ width: '80%' }}>
            <div
              className="page-wraper__header"
              style={{ justifyContent: 'space-between', display: 'flex' }}
            >
              <div></div>
              {isMatchPolicyWRITE ? (
                <Button onClick={() => handleOpenModal()} type="primary">
                  Thêm Chương trình khuyến mãi
                </Button>
              ) : (
                <Tooltip title="Bạn không có quyền tạo mới" color={'red'}>
                  <Button disabled>Thêm Chương trình khuyến mãi</Button>
                </Tooltip>
              )}
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                scroll={{ x: 1500 }}
                dataSource={giveCoupons}
                size="middle"
                onChange={({ current }) => onParamChange({ page: current })}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        title={
          <Typography.Title level={4} style={{ textAlign: 'center' }}>
            {!!id
              ? 'Cập nhật Chương trình khuyến mãi'
              : 'Tạo Chương trình khuyến mãi'}
          </Typography.Title>
        }
        visible={isOpenModal}
        closable={false}
        footer={null}
        onCancel={handleCloseModal}
      >
        <GiveCouponForm
          updateGiveCoupon={updateGiveCoupon}
          id={id}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
      <ModalConfirm
        text="Xác nhận Xoá chương trình"
        textOk="Xác nhận"
        type="delete"
        loading={isSubmitLoading}
        visible={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        handleOk={handleDelete}
      />
    </div>
  );
}
