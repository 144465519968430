import React, { useState } from 'react';
import { PATH_APP } from '~/routes/paths';
import {
  useWhCategories,
  useDeleteWhCategory,
  useUpdateWhCategory,
  useWhCategoryQueryParams,
  useWhCategoryPaging,
  useWhCategoryList,
  useResetWhCategory,
} from '~/hooks/whCategory';
import { Table, Popconfirm, Button, Input, Switch } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import moment from 'moment';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';

const { Search } = Input;

const ColumnActions = ({
  id,
  status,
  deleteWhCategory,
  updateWhCategory,
  isSubmitLoading,
  canUpdate,
  canDelete,
  ...restProps
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_WHSERVICEGROUP}>
        <Link to={`/wh-category/edit/${id}`}>
          {/* <p>Sửa</p> */}
          <EditFilled />
        </Link>
      </WithPermission>
      {canDelete && canUpdate && <p>|</p>}
      {/* <Popconfirm
        title="Bạn muốn xoá nhóm dịch vụ này?"
        onConfirm={() => deleteWhCategory(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '} */}
      <WithPermission permission={POLICY.DELETE_WHSERVICEGROUP}>
        <Switch
          checked={status === 'ACTIVE'}
          onChange={(value) =>
            updateWhCategory({ status: value ? 'ACTIVE' : 'INACTIVE', id })
          }
          // loading={isSubmitLoading}
        />
      </WithPermission>
    </div>
  );
};

const WhCategories = ({ history }) => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHSERVICEGROUP);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHSERVICEGROUP);
  const [query, onPagingChange] = useWhCategoryQueryParams();
  const [whCategories, isLoading] = useWhCategoryList(query);
  const [keyword, setKeyword] = useState(query.name);
  const [, deleteWhCategory] = useDeleteWhCategory();
  const [isSubmitLoading, updateWhCategory] = useUpdateWhCategory();
  const paging = useWhCategoryPaging();
  useResetWhCategory();
  const onSearch = () => {
    let searchParams = `?page=${query.page}&limit=${query.limit}`;
    if (keyword) searchParams += `&name=${keyword}`;

    history.push({
      pathname: '/wh-category',
      search: searchParams
    });
  };

  const columns = [
    {
      title: 'Tên nhóm dịch vụ',
      dataIndex: 'name', // FIXME: i18n
      key: 'name',
      render: (name) => name.vi
    },
    {
      title: 'Tên viết tắt nhóm dịch vụ cho App',
      dataIndex: 'nameApp', // FIXME: i18n
      key: 'nameApp',
      render: (nameApp) => nameApp?.vi
    },
    // {
    //   title: 'Ngày tạo',
    //   dataIndex: 'updatedAt',
    //   key: 'updatedAt',
    //   render: (date) => moment(date).format('DD-MM-YYYY')
    // },
    ...(canDelete || canUpdate
      ? [
          {
            title: 'Thao tác',
            key: 'action',
            width: '110px',
            render: (record) => {
              return (
                <ColumnActions
                  {...record}
                  canDelete={canDelete}
                  canUpdate={canUpdate}
                  deleteWhCategory={deleteWhCategory}
                  updateWhCategory={updateWhCategory}
                  isSubmitLoading={isSubmitLoading}
                />
              );
            }
          }
        ]
      : [])
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách nhóm dịch vụ" />
        <div className="page-content__main">
          <div className="page-content__left">
            <p>Tìm kiếm:</p>
            <Search
              style={{ width: '100%' }}
              placeholder="Tìm nhóm dịch vụ"
              enterButton
              onSearch={onSearch}
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
          </div>

          <div className="page-content__right">
            <div className="page-wraper__header">
              <WithPermission permission={POLICY.WRITE_WHSERVICEGROUP}>
                <Link to={PATH_APP.whCategory.create}>
                  <Button type="primary" style={{ float: 'right' }}>
                    {' '}
                    Thêm mới
                  </Button>
                </Link>
              </WithPermission>
            </div>

            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={whCategories.length} />
            ) : (
              <Table
                className="wh-category-table-striped-rows"
                rowKey={(rc) => rc._id || rc.id}
                columns={columns}
                dataSource={whCategories}
                onChange={onPagingChange}
                pagination={{
                  ...paging,
                  // total: whCategories.length,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhCategories;
