import { Image, Input, Modal, Table, Typography } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { useCallback, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { useJobFormPaging, useJobFormQueryParams, useJobForms, useResetJobForm, useUpdateJobFormParams } from '~/hooks/jobForm';
import { PATH_APP } from '~/routes/paths';
import { formatter } from '~/utils/helper';
import ANQ from './ANQ';
const { Search } = Input;

const JobForm = ({ }) => {
  const [openANQ,setOpenANQ] = useState(false);
  const [dataANQ,setDataANQ] = useState();
  const [query] = useJobFormQueryParams();
  const [JobForms, isLoading] = useJobForms(query);
  const [keyword, setKeyword, { onParamChange }] = useUpdateJobFormParams(query);

  const onOpenANQ = useCallback((data) => {
    setOpenANQ(true);
    data && setDataANQ(data)
  },[]);
  const onCloseANQ = useCallback(() => {
    setOpenANQ(false);
    setDataANQ(null)
  },[]);

  const paging = useJobFormPaging();
  useResetJobForm();


  const columns = [
    {
      title: 'Mã ứng viên',
      dataIndex: 'jobPosting',
      key: 'jobPosting',
      align : 'center',
      render : (jobPosting) => <Typography.Link target='_blank' href={`${PATH_APP.applyJob.root}?keyword=${get(jobPosting,'code')}`}>{get(jobPosting,'code')}</Typography.Link>
    },
    {
      title: 'Cmnd mặt trước',
      dataIndex: 'idCard_front',
      key: 'idCard_front',
      align : 'center',
      render : (idCard_front) => <Image  style={{width : 100}} src={idCard_front}/>
    },
    {
      title: 'Cmnd mặt sau',
      dataIndex: 'idCard_back',
      key: 'idCard_back',
      align : 'center',
      render : (idCard_back) => <Image  style={{width : 100}} src={idCard_back}/>
    },
    {
      title: 'Ảnh 4x6',
      dataIndex: 'idCard_photo',
      key: 'idCard_photo',
      align : 'center',
      render : (idCard_photo) => <Image  style={{width : 100}} src={idCard_photo}/>
    },
    {
      title: 'Chức danh dự tuyển',
      dataIndex: 'jobVacancy',
      key: 'jobVacancy',
      align : 'center',
      render : (jobVacancy) => get(jobVacancy,'title','')
    },
    {
      title: 'Chức danh mong muốn (Khác)',
      dataIndex: 'info_vacancyOther',
      key: 'info_vacancyOther',
      align : 'center',
    },
    {
      title: 'Mức lương mong muốn',
      dataIndex: 'info_salary',
      key: 'info_salary',
      align : 'center',
      render : (info_salary) => formatter(info_salary) 
    },
    {
      title: 'Ngày có thể bắt đầu làm việc',
      dataIndex: 'info_startJob',
      key: 'info_startJob',
      align : 'center',
      render : (info_startJob) => moment(info_startJob).format("DD-MM-YYYY")
    },
    {
      title: 'Câu hỏi và câu trả lời',
      dataIndex: 'ANQ',
      key: 'ANQ',
      align : 'center',
      render : (ANQ) => <Typography.Link onClick={() => onOpenANQ(ANQ)}>Xem chi tiết</Typography.Link>
    },
    

  ];
  const onSearch = (kw) => onParamChange({ keyword: kw?.trim() })
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách câu trả lời của ứng viên" />

        <div className="page-content__main">
          <div className="page-content__left">
            <Search
              allowClear
              style={{ width: '100%' }}
              placeholder="Tìm câu trả lời của ứng viên"
              enterButton
              onSearch={onSearch}
            />
          </div>
          <div className="page-content__right">
            <div className="page-wraper__header">
              <Table
                loading={isLoading}
                className="wh-category-table-striped-rows"
                rowKey={(rc) => rc._id || rc.id}
                columns={columns}
                dataSource={JobForms}
                scroll={{ x: 2000 }}
                onChange={({ current, pageSize }) =>
                  onParamChange({ page: current, limit: pageSize })
                }
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `,
                  showSizeChanger: true
                }}
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={'auto'}
        visible={openANQ}
        onCancel={onCloseANQ}
        centered
        destroyOnClose
        footer={null}
      >
        <ANQ data={dataANQ} />
      </Modal>
    </div>
  );
};

export default JobForm;
