import React, { useState } from 'react';
import { Table, Button, Input, Switch, Modal } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import HospitalListForm from './Form';
import {
  useHospitalsList,
  useUpdateHospitalList,
  useHospitalListQueryParams,
  useUpdateHospitalListParams,
  useHospitalListPaging,
  useDeleteHospitalList
} from '~/hooks/hospitalList';
import { useMatchPolicy } from '~/hooks';
import POLICY from '~/constants/policy';
import { useSpecialityOptions } from '~/hooks/speciality';
import WithPermission from '~/components/Common/WithPermission';
import { get } from 'lodash';
import ModalConfirm from '../Common/ModalConfirm';

const { Search } = Input;
const HospitalList = ({ history }) => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHHEALTHCAREFACILITY);
  const canCreate = useMatchPolicy(POLICY.WRITE_WHHEALTHCAREFACILITY);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHHEALTHCAREFACILITY);
  const [isOpen, setIsOpen] = useState(false);
  const [hospitalListId, setHospitalListId] = useState(null);
  const onClose = () => {
    setIsOpen(false)
    setHospitalListId(null)
  }
  const [query, onPagingChange] = useHospitalListQueryParams();
  const [hospitalsList, isLoading] = useHospitalsList(query);
  const specialityOptions = useSpecialityOptions();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateHospitalListParams(query);
  const [isSubmitLoading, updateHospitalList] = useUpdateHospitalList(onClose);
 
  const paging = useHospitalListPaging();
  //delele//
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const handleOpenModalDelete = (id) => {
    setIdDelete(id)
    setIsOpenModalDelete(true)
  }
  const handleCloseModalDelete = () => {
    setIdDelete(null)
    setIsOpenModalDelete(false)
  }
  const [, deleteHospitalList] = useDeleteHospitalList(handleCloseModalDelete);
  const handleDelete = () => {
    deleteHospitalList(idDelete);
  }

  //
  // const ColumnActions = ({
  //   id,
  //   state,
  //   useDeleteHospitalList,
  //   updateHospitalList,
  //   isSubmitLoading,
  //   record,
  //   ...restProps
  // }) => {
  //   return (
  //     <div className="custom-table__actions">
  //       <WithPermission>
  //         <EditFilled
  //           onClick={() => {
  //             setIsOpen(true);
  //             setHospitalListId(id);
  //           }}
  //           style={{ color: '#1f89e5' }}
  //         />
  //       </WithPermission>
  //       <WithPermission>
  //         <WithPermission>
  //           <p style={{ marginLeft: '10px' }}>|</p>
  //         </WithPermission>
  //       </WithPermission>
  //       {/* <WithPermission> */}
  //       <Switch
  //         defaultChecked={state === 'ACTIVE'}
  //         loading={isSubmitLoading}
  //         onChange={(value) =>
  //           updateHospitalList({ ...record,state: value ? 'ACTIVE' : 'INACTIVE', _id:get(record,'_id') })
  //         }
  //         // loading={isSubmitLoading}
  //       />
  //       {/* </WithPermission> */}
  //     </div>
  //   );
  // };

  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '70px',
      render: (text, record, index) => {
        return (query.page - 1) * query.limit + index + 1;
      }
    },

    {
      title: 'Nơi thực hiện dịch vụ (vi)',
      dataIndex: 'healthcareFacilityId',
      key: 'healthcareFacilityId',
      width: '150px',
      render: (healthcareFacilityId) => {
        return healthcareFacilityId?.name?.vi;
      }
    },

    {
      title: 'Nơi thực hiện dịch vụ (en)',
      dataIndex: 'healthcareFacilityId',
      key: 'healthcareFacilityId',
      width: '150px',
      render: (healthcareFacilityId) => {
        return healthcareFacilityId?.name?.en;
      }
    },
    {
      title: 'Tên bệnh viện (vi)',
      dataIndex: 'nameHealthCare',
      key: 'nameHealthCare',
      width: '150px',
      render: (nameHealthCare) => {
        return get(nameHealthCare,'vi');
      }
    },

    {
      title: 'Tên bệnh viện (en)',
      dataIndex: 'nameHealthCare',
      key: 'nameHealthCare',
      width: '150px',
      render: (nameHealthCare) => {
        return get(nameHealthCare,'en');
      }
    },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      width: '300px',
      render: (value) => {
        var str = '';
        if (value) {
          var strStreet = value.street ? value.street + ', ' : ' ';
          var strWard = value.ward ? value.ward + ', ' : ' ';
          var strDistrict = value.district ? value.district + ', ' : ' ';
          var strCity = value.city ? value.city + ', ' : ' ';
          str = strStreet + strWard + strDistrict + strCity;
        } else {
          return '';
        }
        return str;
      }
    },

    {
      title: 'Ghi chú (vi)',
      dataIndex: 'note',
      key: 'note',
      width: '150px',
      render: (note) => {
        return note?.vi;
      }
    },

    {
      title: 'Ghi chú (vi)',
      dataIndex: 'note',
      key: 'note',
      width: '150px',
      render: (note) => {
        return note?.en;
      }
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (item,record) => {
        return (
          <div className="custom-table__actions">
          <WithPermission permission={POLICY.UPDATE_WHHEALTHCAREFACILITY}>
            <EditFilled
              onClick={() => {
                setIsOpen(true);
                setHospitalListId(get(record,'_id'));
              }}
              style={{ color: '#1f89e5' }}
            />
          </WithPermission>
              <p style={{ marginLeft: '10px' }}>|</p>
          <WithPermission permission={POLICY.DELETE_WHHEALTHCAREFACILITY}>
            <DeleteOutlined
              onClick={() => {
                handleOpenModalDelete(get(record,'_id'));
              }}
              style={{ color: '#1f89e5' }}
            />
          </WithPermission>
              <p style={{ marginLeft: '10px' }}>|</p>
              <WithPermission permission={POLICY.UPDATE_WHHEALTHCAREFACILITY}>

          <Switch
            defaultChecked={get(item,'state','') === 'ACTIVE'}
            loading={isSubmitLoading}
            onChange={(value) =>
              updateHospitalList({ ...record,state: value ? 'ACTIVE' : 'INACTIVE', _id:get(record,'_id') })
            }
          />
              </WithPermission>
        </div>
          // <ColumnActions isSubmitLoading={isSubmitLoading} {...item} record={record} updateHospitalList={updateHospitalList} />
        );
      }
    }
  ];

  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách bệnh viện" />

        <div className="page-content__main">
          <div className="page-content__left">
            <Search
              style={{ width: '100%' }}
              placeholder="Tìm nơi thực hiện"
              enterButton
              allowClear
              onSearch={() => onParamChange({ name: keyword })}
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
          </div>
          <div className="page-content__right">
            <div className="page-content__main" style={{ display: 'block' }}>
              <div className="page-wraper__header">
                <WithPermission permission={POLICY.WRITE_WHHEALTHCAREFACILITY}>
                  <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                      setIsOpen(true);
                      setHospitalListId(null);
                    }}
                  >
                    {' '}
                    Thêm mới
                  </Button>
                </WithPermission>
              </div>
              {isLoading ? (
                <SkeletonTable
                  rowCount={hospitalsList.length}
                  columns={columns}
                  pagination={paging}
                />
              ) : (
                <Table
                  rowKey={(rc) => rc._id || rc.id}
                  columns={columns}
                  dataSource={hospitalsList}
                  onChange={onPagingChange}
                  size="middle"
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={1200}
        visible={isOpen}
        onCancel={onClose}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <HospitalListForm
          updateHospitalList={updateHospitalList}
          isSubmitLoading={isSubmitLoading}
          specialityOptions={specialityOptions}
          hospitalListId={hospitalListId}
          onClose={onClose}
        />
      </Modal>
      <ModalConfirm
        text="Xác nhận xoá"
        textOk="Xác nhận"
        type="delete"
        loading={isSubmitLoading}
        visible={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        handleOk={handleDelete}
      />
    </div>
  );
};

export default HospitalList;
