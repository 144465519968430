import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  Tabs,
  Tooltip
} from 'antd';
import Search from 'antd/lib/input/Search';
import { get, parseInt, transform } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Breadcrumb, WithPermission } from '~/components/Common';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import ConfirmCouponProForm from './ConfirmCouponProForm';
import { useMatchPolicy, useProfile, useUser } from '~/hooks';
import POLICY from '~/constants/policy';
import { useParams } from 'react-router-dom';
import {
  ACTIVE_KEY,
  ACTIVE_WAREHOUSE,
  WAREHOUSE_STATUS
} from '~/constants/defaultValue';
import {
  useDeleteProductList,
  useModalCalendarProduct,
  useProductListPaging,
  useProductListQueryParams,
  useProductLists,
  useResetProductList,
  useStopDeliveryProduct,
  useUpdateProductList,
  useUpdateProductListParams
} from '~/hooks/productList';
import { checkInvalidDate, useQueryParams } from '~/hooks/utils';
import moment, { isMoment } from 'moment';
import ProductListForm from './ProductListForm';
import Information from './Information';
import History from './History';
import { CaretDownOutlined, DeleteOutlined } from '@ant-design/icons';
import CalendarPreColorsDashboard from '~/components/WhBill/CalendarPrefixColors';
import CalendarProduct from './CalendarProduct';
import TabBranch from '~/components/Common/TabBranch';
import { useHistory } from 'react-router-dom';
import api from '~/api';
import ConfirmGetOut from './ConfirmGetOut';

const { Option } = Select;
const { TabPane } = Tabs;

const optionsSearch = [
  { value: 'code', name: 'Mã định danh sản phẩm' },
  { value: 'name', name: 'Tên sản phẩm' },
  { value: 'groupProductName', name: 'Nhóm sản phẩm' },
  { value: 'manufacturer', name: 'Hãng sản xuất' },
  { value: 'supplierName', name: 'Đơn vị cung cấp' },
  { value: 'dateManufacturer', name: 'Ngày sản xuất' },
  // { value: 'createdAt', name: 'Thời gian đăng kí thiết bị' },
  { value: 'valueDepreciation', name: 'Giá trị khấu hao' },
  { value: 'countUsing', name: 'Số lần sử dụng' },
  { value: 'status', name: 'Trạng thái hoạt động' }
];

const ProductistList = () => {
  const [form] = Form.useForm();
  const { param } = useParams();
  const history = useHistory();

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [id, setId] = useState(null);
  const [product, setProduct] = useState({});
  useResetProductList();
  const [branchId] = useUser();

  const [profile] = useProfile();
  const [branchIdSearch, setBranchIdSearch] = useState('all');
  const [optionsBranch, setOptionsBranch] = useState([]);

  const idBranch = useMemo(() => {
    let branchOfs = undefined;
    if (branchId === '99999') {
      if (branchIdSearch === 'all') {
        branchOfs = undefined;
      } else {
        branchOfs = branchIdSearch;
      }
    } else {
      branchOfs = branchId;
    }
    return branchOfs;
  }, [branchIdSearch, branchId]); //

  const [searchBy, setSearchBy] = useState('code');
  const [query] = useProductListQueryParams(searchBy);
  const newQuery = useMemo(
    () => ({
      ...query,
      branchOfs: idBranch
    }),
    [idBranch, query]
  );

  const [keyword, { setKeyword, onParamChange }] =
    useUpdateProductListParams(query);
  const [products, isLoading] = useProductLists(newQuery);
  const [, deleteProduct] = useDeleteProductList();
  const [isStatusProduct, setIsStatusProduct] = useState('');
  const [itemActive, setItemActive] = useState(null);
  const [statusProduct, setStatusProduct] = useState(null);
  const [isOpenFormGetOut, setIsOpenFormGetOut] = useState(false);
  const canUpdateWarehouse = useMatchPolicy(POLICY.UPDATE_WAREHOUSE);
  const canDeleteWarehouse = useMatchPolicy(POLICY.DELELTE_WAREHOUSE);
  const canDeleteDelivery = useMatchPolicy(POLICY.DELETE_PRODUCTDELIVERY);
  const canWriteWarehouse = useMatchPolicy(POLICY.WRITE_PRODUCTDELIVERY);
  const {
    handleCloseCalendar,
    handleOpenCalendar,
    idSelectCalendar,
    isOpenCalendar
  } = useModalCalendarProduct();
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const canReadWarehouse = useMatchPolicy(POLICY.READ_WAREHOUSE);

  const paging = useProductListPaging();
  const defaultDate = useMemo(
    () => ({
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD')
    }),
    []
  );
  const [date, setDate] = useState({
    startDate: null,
    endDate: null
  });

  const getBranches = async () => {
    setIsLoadingBranches(true);
    const res = await api.deliveryVoucher.getAllBranch();
    const mapRes = res.map((item) => {
      return {
        label: item.name,
        value: item._id
      };
    });
    const optionAll = {
      label: 'Tất cả chi nhánh',
      value: 'all'
    };
    setOptionsBranch([optionAll, ...mapRes]);
    setIsLoadingBranches(false);
  };

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    const { startDate, endDate, page, limit, ...rest } = query;
    // setDate(() => ({
    //   startDate: startDate,
    //   endDate: endDate
    // }))
    const keySearch = Object.keys(rest);
    if (keySearch?.length > 0) {
      setSearchBy(keySearch[0]);
      const keyword = Object.values(rest);
      setKeyword(keyword[0]);
    }
  }, [query]);

  const handleOpenForm = (id) => {
    setIsOpenForm(true);
    setId(id);
  };
  const handleOpenFormUpdate = (id) => {
    setIsOpenForm(false);
    setId(id);
  };
  const handleCloseForm = () => {
    setIsOpenForm(false);
    setId(null);
    setStatusProduct(null);
  };
  const handleDelete = (id) => {
    deleteProduct({ id });
  };

  /**
   * Handles the link to another page.
   *
   * @param {Object} record - The record object.
   */
  const handleLinkInToPage = (record) => {
    const warehouseId = record?.warehouse?.warehouseId;
    const branchId = record?.warehouseInfo?.branchId;
    // sessionStorage.setItem("branchId", branchId);
    history.push({
      pathname: `/list-warehouse/products/${warehouseId}`
    });
  };

  /**
   * Call data to get information of the rotation ticket containing the device that requires temporary suspension of rotation
   *
   * @return {type} return stop delivery action
   */

  const actionDeliveryStop = (
    <WithPermission permission={POLICY.DELETE_PRODUCTDELIVERY}>
      <Menu.Item key={'3'}>
        <p
          onClick={() => {
            setIsOpenFormGetOut(true);
          }}
        >
          Tạm ngừng
        </p>
      </Menu.Item>
    </WithPermission>
  );

  // Decleration of action delete and redirect to page
  const actionDelivery = (
    <WithPermission permission={POLICY.WRITE_PRODUCTDELIVERY}>
      <Menu.Item key={'1'}>
        <Popconfirm
          title="Hành động này sẽ chuyển bạn đến trang luân chuyển thiết bị, bạn có đồng ý?"
          onConfirm={() => handleLinkInToPage(product)}
        >
          <p>Chuyển thiết bị</p>
        </Popconfirm>
      </Menu.Item>
    </WithPermission>
  );

  const actionDelete = (
    <WithPermission permission={POLICY.DELETE_WAREHOUSE}>
      <Menu.Item key={'2'}>
        <Popconfirm
          title="Bạn có chắc muốn xóa?"
          onConfirm={() => handleDelete(id)}
        >
          Xoá thiết bị
        </Popconfirm>
      </Menu.Item>
    </WithPermission>
  );

  const columns = [
    {
      title: 'Quản lý thời gian',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (item, record) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => handleOpenCalendar(get(record, '_id'))}
        >
          <CalendarPreColorsDashboard className="home-service-form__form-item--prefix" />
        </div>
      )
    },
    {
      title: 'Mã định danh sản phẩm',
      align: 'start',
      dataIndex: 'code',
      width: '200px',
      key: 'code',
      render: (value, record) => {
        return (
          <Tooltip
            placement="top"
            title={
              record?.status === 'READY' || record?.status === 'UNREADY'
                ? ''
                : `Thiết bị ở trạng thái ${WAREHOUSE_STATUS[
                    record?.status
                  ]?.name?.toLowerCase()} nên không thể cập nhật`
            }
          >
            <Button
              type="link"
              onClick={() => {
                handleOpenForm(record?._id), setIsStatusProduct(record?.status);
                setStatusProduct(record?.status);
              }}
            >
              {value}
            </Button>
          </Tooltip>
        );
      }
    },
    {
      title: 'Tên sản phẩm',
      align: 'start',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Nhóm sản phẩm',
      align: 'start',
      dataIndex: 'groupProductId',
      key: 'groupProductId',
      render: (value, record) => record?.groupProduct?.name?.vi || ''
    },
    {
      title: 'Hãng sản xuất',
      align: 'start',
      dataIndex: 'manufacturer',
      key: 'manufacturer'
    },
    {
      title: 'Đơn vị cung cấp',
      align: 'start',
      dataIndex: 'supplierId',
      key: 'supplierId',
      render: (value, record) => record?.supplierInfo?.name
    },
    {
      title: 'Kho hiện tại',
      align: 'start',
      dataIndex: 'warehouse',
      key: 'warehouse',
      render: (value, record) =>
        record?.warehouseInfo?.name ?? record?.supplierInfo?.name
    },
    {
      title: 'Ngày sản xuất',
      align: 'start',
      dataIndex: 'dateManufacturer',
      key: 'dateManufacturer',
      render: (value) => moment(value).format('YYYY-MM-DD')
    },
    {
      title: 'Ngày đăng kí thiết bị',
      align: 'start',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '180px',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm')
    },
    {
      title: 'Số lần khấu hao',
      align: 'start',
      dataIndex: 'countUsing',
      key: 'countUsing'
    },
    {
      title: 'Giá trị khấu hao',
      align: 'start',
      dataIndex: 'valueDepreciation',
      key: 'valueDepreciation',
      render: (value) => `${value?.toLocaleString() ?? ''}`
    },
    {
      title: 'Đơn vị khấu hao',
      align: 'start',
      dataIndex: 'unitDepreciation',
      key: 'unitDepreciation'
    },
    {
      title: 'Số lần sử dụng',
      align: 'start',
      dataIndex: 'countUsing',
      key: 'countUsing'
    },
    {
      title: 'Trạng thái',
      align: 'start',
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => {
        return record?.warehouse?.status === 'OUT' ? (
          <span style={{ color: 'red' }}>Đang luân chuyển</span>
        ) : (
          <span style={{ color: WAREHOUSE_STATUS[value]?.colorStyle }}>
            {WAREHOUSE_STATUS[value]?.name}
          </span>
        );
      }
    },
    {
      title: 'Action',
      key: 'createdAt',
      fixed: 'right',
      dataIndex: 'createdAt',
      width: '150px',
      render: (text, record, index) => {
        const checkStatus =
          record?.status === 'READY' || record?.status === 'UNREADY';
        const warehouse = record?.warehouse; // Check the device to see if the supplier is WorldHealt warehouse or whPartner
        const showAction = () => {
          if (!warehouse) {
            // If device is a whPartner
            return (
              record?.status === 'UNREADY' &&
              (canUpdateWarehouse ? <Menu>{actionDelete}</Menu> : '')
            );
          } else {
            // If device is a WorldHealth
            if (checkStatus) {
              if (warehouse?.status === 'OUT') {
                return canDeleteDelivery ? (
                  <Menu>{actionDeliveryStop}</Menu>
                ) : (
                  ''
                );
              } else {
                if (record?.status === 'READY') {
                  return canWriteWarehouse ? <Menu>{actionDelivery}</Menu> : '';
                } else {
                  return canWriteWarehouse || canDeleteWarehouse ? (
                    <Menu>{[actionDelivery, actionDelete]}</Menu>
                  ) : (
                    ''
                  );
                }
              }
            }
            return <></>;
          }
        };
        return (
          <Dropdown
            onClick={() => {
              setId(get(record, '_id'));
              setProduct(record);
            }}
            overlayStyle={{ width: '140px' }}
            trigger={['click']}
            overlay={
              record?.status === 'READY' && !warehouse ? '' : showAction()
            }
          >
            <a className="ant-dropdown-link" href="#">
              Action <CaretDownOutlined />
            </a>
          </Dropdown>
        );
      }
    }
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách sản phẩm" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form.Item vertical label="Tìm kiếm">
              {searchBy === 'status' ? (
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Vui lòng chọn trạng thái"
                  optionLabelProp="label"
                  allowClear
                  onChange={(e) => onParamChange({ [searchBy]: e })}
                >
                  {transform(
                    WAREHOUSE_STATUS,
                    (result, value, key) => {
                      result.push({
                        label: get(value, 'name', ''),
                        value: key,
                        color: get(value, 'colorStyle', '')
                      });
                    },
                    []
                  ).map((e) => (
                    <Option key={e.value} value={e.value} label={e.label}>
                      <p style={{ color: e.color }}>{e.label}</p>
                    </Option>
                  ))}
                </Select>
              ) : (
                <Search
                  value={keyword}
                  placeholder={`Tìm ${optionsSearch
                    .find((item) => item.value === searchBy)
                    ?.name.toLowerCase()}`}
                  enterButton
                  allowClear
                  style={{ maxWidth: '500px' }}
                  onSearch={() =>
                    onParamChange({ [searchBy]: keyword, page: 1 })
                  }
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    if (!e.target.value) {
                      onParamChange({ [searchBy]: '' });
                    }
                  }}
                />
              )}
            </Form.Item>
            <Form.Item vertical>
              <Select
                value={searchBy}
                style={{ width: '100%' }}
                options={optionsSearch.map((e) => ({
                  value: e.value,
                  label: e.name
                }))}
                onChange={(val) => {
                  onParamChange({
                    code: null,
                    name: null,
                    groupProductId: null,
                    manufacturer: null,
                    dateManufacturer: null,
                    countDepreciation: null,
                    valueDepreciation: null,
                    unitDepreciation: null,
                    supplierName: null,
                    countUsing: null,
                    status: null,
                    page: 1
                  });
                  setSearchBy(val);
                  setKeyword('');
                }}
                allowClear
              />
            </Form.Item>

            {profile?.branchId === 99999 &&
              branchId === '99999' &&
              canReadWarehouse && (
                <Form.Item vertical>
                  <Select
                    value={branchIdSearch}
                    style={{ width: '100%' }}
                    options={optionsBranch}
                    onChange={(e) => setBranchIdSearch(e)}
                    allowClear
                  />
                </Form.Item>
              )}

            <Form.Item vertical>
              <DatePicker.RangePicker
                placeholder={['Bắt đầu', 'Kết thúc']}
                value={[
                  date[0] ? moment(date[0]) : null,
                  date[1] ? moment(date[1]) : null
                ]}
                onChange={(e, el) => {
                  setDate(el);
                  onParamChange({
                    startDate: el[0],
                    endDate: el[1]
                  });
                }}
              />
            </Form.Item>
          </div>

          <div className="page-content__right">
            <div className="page-wraper__header">
              <Row gutter={48}>
                <Col span={24}>
                  <Row justify="end" align="middle">
                    <WithPermission permission={POLICY.WRITE_WAREHOUSE}>
                      <Button type="primary" onClick={handleOpenForm}>
                        Thêm mới
                      </Button>
                    </WithPermission>
                  </Row>
                </Col>
              </Row>
            </div>

            {isLoading ? (
              <SkeletonTable columns={columns} pagination={paging} />
            ) : (
              <Table
                scroll={{ x: 2000 }}
                columns={columns}
                dataSource={products || []}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                rowKey={(rc) => rc._id}
                size="middle"
                onChange={({ current, pageSize }) =>
                  onParamChange({ page: current, limit: pageSize })
                }
                onExpand={(expanded, record) =>
                  setItemActive(expanded ? record._id : null)
                }
                expandable={{
                  expandedRowRender: (record) => (
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Thông tin" key="1">
                        <Information product={record} form={form} />
                      </TabPane>
                      <TabPane tab="Lịch sử" key="2">
                        <History product={record} form={form} />
                      </TabPane>
                    </Tabs>
                  ),
                  expandedRowKeys: [itemActive]
                }}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        visible={isOpenForm}
        closable={false}
        onCancel={handleCloseForm}
        width={1000}
        title={`${id ? 'Cập nhật' : 'Thêm mới'} sản phẩm`}
        footer={null}
      >
        <ProductListForm
          setIsOpenForm={setIsOpenForm}
          isOpenForm={isOpenForm}
          id={id}
          setId={setId}
          handleCloseForm={handleCloseForm}
        />
      </Modal>

      <Modal
        visible={isOpenCalendar}
        closable={false}
        onCancel={handleCloseCalendar}
        width={1200}
        footer={null}
        destroyOnClose
      >
        <CalendarProduct productId={idSelectCalendar} />
      </Modal>

      <Modal
        title="Xác nhận rút thiết bị khỏi phiếu bàn giao"
        visible={isOpenFormGetOut}
        closable={false}
        onCancel={() => setIsOpenFormGetOut(false)}
        footer={null}
      >
        <ConfirmGetOut id={id} setIsOpenFormGetOut={setIsOpenFormGetOut} />
      </Modal>
    </div>
  );
};

export default ProductistList;
