import React, { useState } from 'react';
import { PATH_APP } from '~/routes/paths';
import {
  useStaffs,
  useDeleteStaff,
  useStaffQueryParams,
  useStaffPaging,
  useUpdateStaff,
  useResetStaff
} from '~/hooks/staff';
import { Table, Tag, Popconfirm, Input, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { WithPermission } from '../Common';
import POLICIES from '~/constants/policy';

const { Search } = Input;

const ColumnActions = ({ id, deleteStaff }) => {
  return (
    <div className="custom-table__actions">
    <WithPermission permission={POLICIES.UPDATE_MEDICALCLINIC}>
      <Link to={`/staff/edit/${id}`}>
        <p>Sửa</p>
      </Link>
    </WithPermission>
      <WithPermission permission={POLICIES.UPDATE_MEDICALCLINIC}>
        <WithPermission permission={POLICIES.DELETE_MEDICALCLINIC}>
          <p>|</p>
        </WithPermission>
      </WithPermission>
      <WithPermission permission={POLICIES.DELETE_MEDICALCLINIC}>
      <Popconfirm
        title="Bạn muốn xoá nhân viên này?"
        onConfirm={() => deleteStaff(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const Staffs = ({ history }) => {
  const [query, onPagingChange] = useStaffQueryParams();
  const [staffs, isLoading] = useStaffs(query);
  const [keyword, setKeyword] = useState(query.keyword);
  const [, deleteStaff] = useDeleteStaff();
  const [, handleUpdateStaff] = useUpdateStaff();
  const paging = useStaffPaging();
  const onSearch = () => {
    let searchParams = `?page=${query.page}&limit=${query.limit}`;
    if (keyword) searchParams += `&keyword=${keyword}`;

    history.push({
      pathname: '/staff',
      search: searchParams
    });

  };

  useResetStaff();

  const columns = [
    {
      title: 'Tên nhân viên',
      dataIndex: 'fullName',
      key: 'fullName'
    },

    {
      title: 'Chứng chỉ',
      dataIndex: 'certification',
      key: 'certification',
      render: (certification) => <p>{certification[0]?.name || ''}</p>
    },
    {
      title: 'Dịch Vụ',
      key: 'speciality',
      dataIndex: 'speciality',
      render: (speciality) => (
        <div className="speciality-tags">
          {speciality.map((spec, index) => {
            return (
              <Tag color="blue" key={index}>
                {spec.name}
              </Tag>
            );
          })}
        </div>
      )
    },
    {
      title: 'Highlight',
      dataIndex: 'highlight',
      key: 'highlight',
      align: 'center',
      render:(_,record)=>{
        return <Checkbox checked={_} onChange={({target:{checked}})=>{
          handleUpdateStaff({...record,highlight:checked})
        }}></Checkbox>
      }
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return <ColumnActions {...record} deleteStaff={deleteStaff} />;
      }
    }
  ];

  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách nhân viên" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Search
              style={{ width: '100%' }}
              placeholder="Tìm nhân viên"
              enterButton
              onSearch={onSearch}
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
          </div>
          <div className="page-content__right">
            <div className="page-wraper__header">
              <WithPermission permission={POLICIES.WRITE_MEDICALCLINIC}>
                <Link to={PATH_APP.staff.create}>
                  <Button type="primary" style={{ float: 'right' }}>
                    {' '}
                    Thêm mới
                  </Button>
                </Link>
              </WithPermission>
            </div>
            {isLoading ? (
              <SkeletonTable
                rowCount={staffs.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={staffs}
                onChange={onPagingChange}
                size="middle"
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staffs;
