import { Button, Col, Input, Modal, Popconfirm, Row, Table, Typography } from 'antd';
import { useCallback, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useConfigWhPartnerRegisterTypeFormPaging, useConfigWhPartnerRegisterTypeFormQueryParams, useConfigWhPartnerRegisterTypeForms, useDeleteConfigWhPartnerRegisterTypeForm, useResetConfigWhPartnerRegisterTypeForm, useUpdateConfigWhPartnerRegisterTypeFormParams } from '~/hooks/configWhPartnerRegisterTypeForm';
import FormRegisterWhPartnerTypeForm from './FormRegisterWhPartnerTypeForm';
const { Search } = Input;

const ConfigWhPartnerRegisterTypeForm = ({ }) => {
    const [id,setId] = useState();
    const [open,setOpen] = useState(false);

    const canUpdate = useMatchPolicy(POLICY.UPDATE_CONFIGWHPARTNERREGISTER);
    const canDelete = useMatchPolicy(POLICY.DELETE_CONFIGWHPARTNERREGISTER);
    const [query] = useConfigWhPartnerRegisterTypeFormQueryParams();
    const [ConfigWhPartnerRegisterTypeForms, isLoading] = useConfigWhPartnerRegisterTypeForms(query);
    console.log(isLoading,'isLoading');
    const [keyword, setKeyword, { onParamChange }] = useUpdateConfigWhPartnerRegisterTypeFormParams(query);

    const [, deleteConfigWhPartnerRegisterTypeForm] = useDeleteConfigWhPartnerRegisterTypeForm();
    const paging = useConfigWhPartnerRegisterTypeFormPaging();
    useResetConfigWhPartnerRegisterTypeForm();

    const onOpen = useCallback((id) => {
        if(id){
          setId(id);
        }
        setOpen(true);
    },[]);
    const onClose = useCallback(() => {
        setId(null);
        setOpen(false);
    },[])
    const columns = [
        {
            title: 'Tiêu đề',
            dataIndex: 'name',
            key: 'name',
        },
        ...(canDelete || canUpdate
            ? [
                {
                    title: 'Thao tác',
                    key: 'action',
                    dataIndex : '_id',
                    width: 150,
                    align: 'center',
                    render: (_id) => {
                        return (
                            <Row justify='center' gutter={16}>
                                <WithPermission permission={POLICY.UPDATE_CONFIGWHPARTNERREGISTER}>
                                <Col>
                                    <Typography.Link onClick={() => onOpen(_id)}>Sửa</Typography.Link>
                                </Col>
                                </WithPermission>
                                {canUpdate && canDelete && <Col>|</Col>}
                                <WithPermission permission={POLICY.DELETE_CONFIGWHPARTNERREGISTER}>
                                <Col>
                                    <Popconfirm title="Xác nhận xoá" onConfirm={() => deleteConfigWhPartnerRegisterTypeForm(_id)}>
                                    <Typography.Link type='danger'>Xoá</Typography.Link>
                                    </Popconfirm>
                                </Col>
                                </WithPermission>
                            </Row>
                        );
                    }
                }
            ]
            : [])
    ];
    const onSearch = (kw) => onParamChange({ keyword: kw?.trim() })
    return (
      <div className="page-wraper page-content wh-bills-page">
        <div className="container-fluid">
          <Breadcrumb title="Danh sách Loại Form" />

          <div className="page-content__main">
            <div className="page-content__left">
              <Search
                allowClear
                style={{ width: '100%' }}
                placeholder="Tìm Loại Form"
                enterButton
                onSearch={onSearch}
                // onSearch={onSearch}
              />
            </div>

            <div className="page-content__right">
              <div className="page-wraper__header">
                <WithPermission
                  permission={POLICY.WRITE_CONFIGWHPARTNERREGISTER}
                >
                  <Button
                    onClick={() => onOpen()}
                    type="primary"
                    style={{ float: 'right' }}
                  >
                    {' '}
                    Thêm mới
                  </Button>
                </WithPermission>
              </div>

              <Table
                loading={isLoading}
                className="wh-category-table-striped-rows"
                rowKey={(rc) => rc._id || rc.id}
                columns={columns}
                dataSource={ConfigWhPartnerRegisterTypeForms}
                onChange={({ current }) => onParamChange({ page: current })}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                size="small"
              />
            </div>
          </div>
        </div>
        <Modal
          title={`${id ? 'Cập nhật' : 'Tạo mới'} Loại Form`}
          visible={open}
          onCancel={onClose}
          centered
          destroyOnClose
          footer={null}
        >
          <FormRegisterWhPartnerTypeForm id={id} onCancel={onClose} />
        </Modal>
      </div>
    );
};

export default ConfigWhPartnerRegisterTypeForm;
