import { Button, Form, Modal, Select, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { get, head, keys } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Breadcrumb, WithPermission } from '~/components/Common';
import TabBranch from '~/components/Common/TabBranch';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { MODE_VOUCHER_WAREHOUSE, REF_COLLECTION_VOUCHER_WAREHOUSE, TYPE_VOUCHER_WAREHOUSE, TYPE_VOUCHER_WAREHOUSE_VI } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { STATUS_WAREHOUSE_VOUCHER_VI } from '~/constants/warehouse';
import { useResetWarehouseVoucher, useUpdateWarehouseVoucherParams, useWarehouseVoucher, useWarehouseVoucherPaging, useWarehouseVoucherQueryParams, useWarehouseVouchers } from '~/hooks/warehouseVoucher';
import FormVoucherWarehouse from '../FormVoucher/FormVoucherWarehouse';
import ExpandableVoucher from './ExpandableVoucher';
const listOptionSearch = [
  {
    value: 'codeCommerPurchVoucher',
    label: 'Mã phiếu'
  },
  {
    value: 'User',
    label: 'Nhân viên'
  },
  {
    value: 'client',
    label: 'Người nhận phiếu'
  },
  {
    value: 'note',
    label: 'Ghi chú'
  },
];
export default function VoucherWareHouse() {
  const [form] = Form.useForm()
  const [selectSearch, setSelectSearch] = useState(
    head(listOptionSearch).value
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [typeVoucher,setTypeVoucher] = useState(null);
  const [id, setId] = useState(null);
  const [itemActive, setItemActive] = useState(null);
  const handleOpenModal = (id) => {
    setIsOpenModal(true);
    if (id) {
      setId(id);
    }
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setId(null);
    setTypeVoucher(null);
  };
  // hook

  const [query] = useWarehouseVoucherQueryParams();
  const [warehouseVouchers, isLoading] = useWarehouseVouchers(query);
  const paging = useWarehouseVoucherPaging(query);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateWarehouseVoucherParams(query, listOptionSearch);
  useResetWarehouseVoucher();
  //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('');
  };
  ////////////////////////////////
  useEffect(() => {
    const { typeVoucher } = query
    form.setFieldsValue({ typeVoucher: typeVoucher || null })
  }, [query])
  useEffect(() => {
    let value = listOptionSearch?.find(e => query[e.value])
    setSelectSearch(get(value, 'value', head(listOptionSearch).value))
    setKeyword(query[get(value, 'value', '')])
  }, [])
  const onValuesChange = (value, allValues) => {
    onParamChange({ ...allValues, ...value })
  }

  const columns = [
    {
      title: 'Loại phiếu',
      dataIndex: 'typeVoucher',
      key: 'typeVoucher',
      render: (item, record, index) => TYPE_VOUCHER_WAREHOUSE_VI[item]
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'codeCommerPurchVoucher',
      key: 'codeCommerPurchVoucher',
      render: (item, record, index) => <Typography.Link onClick={() => handleOpenModal(record._id)}>{get(record, 'typeVoucher', '') + item}</Typography.Link>

    },
    
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align:'center',
      render: (item, record, index) =>   <span className={`confirm-voucher-status ${item?.toLowerCase()}`}>
      {STATUS_WAREHOUSE_VOUCHER_VI[item]}
    </span>
    },

    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Nhân viên',
      dataIndex: 'User',
      key: 'User',
      render: (item, record) => get(item, 'fullName', '')
    },
    {
      title: 'Người nhận phiếu',
      dataIndex: 'client',
      key: 'client',
      render: (item, record) => get(item, 'refCollection') === REF_COLLECTION_VOUCHER_WAREHOUSE.wh_partner ? get(record, 'whPartner.name', '') : get(record, 'customerAccount.name', '')
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (item, record, index) => moment(item).format("DD-MM-YYYY HH:mm:ss")
    },
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <TabBranch>
        <div className="container-fluid">
          <Breadcrumb title="Quản lý phiếu kho" />
          <div className="page-content__main">
            <div className="page-content__left">
              <Form
                form={form}
                autoComplete="off"
                onFinish={() => {}}
                scrollToFirstError
                onValuesChange={onValuesChange}
                requiredMark={false}
                labelCol={{ sm: 24, md: 24, lg: 24 }}
                layout='vertical'
              >
                <Form.Item label="Tìm kiếm">
                  <Search
                    style={{ width: '100%' }}
                    placeholder={`Tìm ${get(
                      listOptionSearch.find((e) => e.value === selectSearch),
                      'label',
                      ''
                    )}`}
                    enterButton
                    allowClear
                    onSearch={(value) => {
                      onParamChange({ [selectSearch]: value });
                    }}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                </Form.Item>
                <Form.Item label="Loại phiếu" name="typeVoucher">
                  <Select
                    placeholder="Hãy chọn loại phiếu"
                    allowClear
                    options={keys(TYPE_VOUCHER_WAREHOUSE)?.map((option) => ({
                      label: TYPE_VOUCHER_WAREHOUSE_VI[option],
                      value: option
                    }))}
                  />
                </Form.Item>

                <Form.Item>
                  <Select
                    value={selectSearch}
                    style={{
                      width: '100%'
                    }}
                    onChange={handleChangeSelectSearch}
                    options={listOptionSearch}
                  />
                </Form.Item>
              </Form>
            </div>
            <div className="page-content__right" style={{ width: '80%' }}>
              <div
                className="page-wraper__header"
                style={{ justifyContent: 'space-between', display: 'flex' }}
              >
                <div></div>
                <WithPermission permission={POLICIES.WRITE_WAREHOUSE}>
                  <div>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleOpenModal();
                        setTypeVoucher(TYPE_VOUCHER_WAREHOUSE.PHT);
                      }}
                    >
                      Tạo phiếu hoàn trả
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleOpenModal();
                        setTypeVoucher(TYPE_VOUCHER_WAREHOUSE.PBG);
                      }}
                    >
                      Tạo phiếu bàn giao
                    </Button>
                  </div>
                </WithPermission>
              </div>

              {isLoading ? (
                <SkeletonTable columns={columns} rowCount={10} />
              ) : (
                <Table
                  rowKey={(rc) => rc._id}
                  columns={columns}
                  dataSource={warehouseVouchers}
                  size="middle"
                  onChange={({ current }) => onParamChange({ page: current })}
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                  onExpand={(expanded, record) => {
                    expanded ? setItemActive(record._id) : setItemActive(null);
                  }}
                  expandable={{
                    expandedRowRender: (record) => (
                      <ExpandableVoucher
                        dataSource={get(record, 'listProduct', [])}
                      />
                    ),
                    expandedRowKeys: [itemActive]
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </TabBranch>
      <Modal
        width={'auto'}
        visible={isOpenModal}
        footer={null}
        onCancel={handleCloseModal}
        destroyOnClose
        className="custom-modal-responsive"
      >
        <FormVoucherWarehouse
          id={id}
          typeVoucher={typeVoucher}
          onCancel={handleCloseModal}
        />
      </Modal>
    </div>
  );
}
