import { Button, Input, Modal, Table, Image, Row, Col, Typography, Popconfirm } from 'antd';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useListSoftwarePaging, useListSoftwareQueryParams, useListSoftwares, useDeleteListSoftware, useResetListSoftware, useUpdateListSoftware, useUpdateListSoftwareParams } from '~/hooks/listSoftware';
import FormSoftware from './FormSoftware';
const { Search } = Input;

const ListSoftware = ({ }) => {
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const [query] = useListSoftwareQueryParams();
  const [ListSoftwares, isLoading] = useListSoftwares(query);
  const [keyword, setKeyword, { onParamChange }] = useUpdateListSoftwareParams(query);

  const [, deleteListSoftware] = useDeleteListSoftware();
  const paging = useListSoftwarePaging();
  useResetListSoftware();

  const onOpen = useCallback((_id) => {
    setOpen(true);
    if (_id) setId(_id);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
    setId(null);
  }, []);
  const columns = [
    {
      title: 'Tên phần mềm VI',
      dataIndex: 'name',
      key: 'name',
      render: (name) => get(name, "vi")
    },
    {
      title: 'Tên phần mềm EN',
      dataIndex: 'name',
      key: 'name',
      render: (name) => get(name, "en")
    },
    {
      title: "Thao tác",
      dataIndex: '_id',
      key: '_id',
      width: 150,
      align: 'center',
      render: (_id) => <Row gutter={16} wrap={false} justify='center'>
        <Col>
          <WithPermission permission={POLICY.UPDATE_SOFTWARE}>
            <Typography.Link onClick={() => onOpen(_id)}>
              Cập nhật
            </Typography.Link>
          </WithPermission>
        </Col>
        <Col>
          <WithPermission permission={POLICY.DELETE_SOFTWARE}>
            <Popconfirm onConfirm={() => deleteListSoftware(_id)} title="Xác nhận xoá">
              <Typography.Link type='danger'>
                Xoá
              </Typography.Link>
            </Popconfirm>
          </WithPermission>
        </Col>
      </Row>
    }
  ];
  const onSearch = (kw) => onParamChange({ keyword: kw?.trim() })
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách Phần mềm" />

        <div className="page-content__main">
          <div className="page-content__left">
            <Search
              allowClear
              style={{ width: '100%' }}
              placeholder="Tìm Phần mềm"
              enterButton
              onSearch={onSearch}
              // onSearch={onSearch}
            />
          </div>
          <div className="page-content__right">
            <div className="page-wraper__header">
              <WithPermission permission={POLICY.WRITE_SOFTWARE}>
                <Button
                  onClick={() => onOpen()}
                  type="primary"
                  style={{ float: 'right' }}
                >
                  {' '}
                  Thêm mới
                </Button>
              </WithPermission>
            </div>

            <Table
              loading={isLoading}
              className="wh-category-table-striped-rows"
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={ListSoftwares}
              onChange={({ current, pageSize }) =>
                onParamChange({ page: current, limit: pageSize })
              }
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `,
                showSizeChanger: true
              }}
              size="small"
            />
          </div>
        </div>
      </div>
      <Modal
        width={800}
        title={(id ? 'Cập nhật' : 'Tạo mới') + ' phần mềm'}
        visible={open}
        destroyOnClose
        centered
        footer={null}
        onCancel={onClose}
      >
        <FormSoftware id={id} onCancel={onClose} />
      </Modal>
    </div>
  );
};

export default ListSoftware;
