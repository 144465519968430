import { Button, Col, Form, Image, Input, Modal, Popconfirm, Radio, Row, Switch, Table, Typography } from 'antd';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useJobQuestionPaging, useJobQuestionQueryParams, useJobQuestions, useDeleteJobQuestion, useResetJobQuestion, useUpdateJobQuestion, useUpdateJobQuestionParams } from '~/hooks/jobQuestion';
import { PATH_APP } from '~/routes/paths';
import JobQuestionForm from './JobQuestionForm';
const { Search } = Input;

const JobQuestion = ({ }) => {
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_JOB);
  const canDelete = useMatchPolicy(POLICY.DELETE_JOB);
  const [query] = useJobQuestionQueryParams();
  const [JobQuestions, isLoading] = useJobQuestions(query);
  const [keyword, setKeyword, { onParamChange }] = useUpdateJobQuestionParams(query);


  const paging = useJobQuestionPaging();
  useResetJobQuestion();

  const onOpen = useCallback((_id) => {
    setOpen(true);
    if (_id) setId(_id);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
    setId(null);
  }, []);
  const [isSubmitLoading, onUpdate] = useUpdateJobQuestion(onClose);
  const [, deleteJobQuestion] = useDeleteJobQuestion();

  const columns = [
    {
      title: 'Câu hỏi',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',
      align : 'center',
      render : (active,rc) => <Switch loading={isSubmitLoading} checked={active === 'ACTIVE'} onChange={(checked) => onUpdate({_id : rc._id , active : checked ? 'ACTIVE' : 'INACTIVE'})}/>
    },
    
    {
      title: "Thao tác",
      dataIndex: '_id',
      key: '_id',
      width: 120,
      align: 'center',
      render: (_id) => <Row gutter={16} wrap={false} justify='center'>
        <Col>
          <WithPermission permission={POLICY.UPDATE_JOB}>
            <Typography.Link onClick={() => onOpen(_id)}>
              Cập nhật
            </Typography.Link>
          </WithPermission>
        </Col>
        <Col>
          <WithPermission permission={POLICY.DELETE_JOB}>
            <Popconfirm onConfirm={() => deleteJobQuestion(_id)} title="Xác nhận xoá">
              <Typography.Link type='danger' >
                Xoá
              </Typography.Link>
            </Popconfirm>
          </WithPermission>
        </Col>
      </Row>
    }

  ];
  const onSearch = (kw) => onParamChange({ keyword: kw?.trim() })
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách bộ câu hỏi tuyển dụng" />

        <div className="page-content__main">
          <div className="page-content__left">
            <Form.Item>
              <Search
                allowClear
                style={{ width: '100%' }}
                placeholder="Tìm bộ câu hỏi tuyển dụng"
                enterButton
                onSearch={onSearch}
              />
            </Form.Item>
          </div>
          <div className="page-content__right">
            <div className="page-wraper__header">
              <Radio.Group
                value={query?.active}
                onChange={(e) => onParamChange({ active: e.target.value })}
              >
                <Radio.Button value={null}>Tất cả</Radio.Button>
                <Radio.Button value={'ACTIVE'}>Hoạt động</Radio.Button>
                <Radio.Button value={'INACTIVE'}>Ngừng hoạt động</Radio.Button>
              </Radio.Group>

              <WithPermission permission={POLICY.WRITE_JOB}>
                <Button
                  onClick={() => onOpen()}
                  type="primary"
                  style={{ float: 'right' }}
                >
                  {' '}
                  Thêm mới
                </Button>
              </WithPermission>
            </div>

            <Table
              loading={isLoading}
              className="wh-category-table-striped-rows"
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={JobQuestions}
              onChange={({ current, pageSize }) =>
                onParamChange({ page: current, limit: pageSize })
              }
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `,
                showSizeChanger: true
              }}
              size="small"
            />
          </div>
        </div>
      </div>
      <Modal
        title={(id ? 'Cập nhật' : 'Tạo mới') + ' câu hỏi tuyển dụng'}
        visible={open}
        destroyOnClose
        centered
        footer={null}
        onCancel={onClose}
      >
        <JobQuestionForm onUpdate={onUpdate} id={id} onCancel={onClose} />
      </Modal>
    </div>
  );
};

export default JobQuestion;
