import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { get, head, keys, uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Breadcrumb } from '~/components/Common';
import TabBranch from '~/components/Common/TabBranch';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { CONFIRM_VOUCHER_STATUS, CONFIRM_VOUCHER_STATUS_VI } from '~/constants/confirmVoucherWarehouse';
import { useModalConfirmVoucher } from '~/hooks';
import { useConfirmVoucherWarehousePaging, useConfirmVoucherWarehouseQueryParams, useConfirmVoucherWarehouses, useResetConfirmVoucherWarehouse, useUpdateConfirmVoucherWarehouseParams } from '~/hooks/confirmVoucherWarehouse';
import ExpandableVoucher from './ExpandableVoucher';
import FormConfirmVoucherWarehouse from './FormConfirmVoucherWarehouse/FormConfirmVoucherWarehouse';
const listOptionSearch = [
  {
    value: 'codeSequence',
    label: 'Mã phiếu'
  },
  {
    value: 'employee',
    label: 'Người tạo phiếu'
  },
  {
    value: 'whPartner',
    label: 'Đối tác'
  },
  {
    value: 'note',
    label: 'Ghi chú'
  },
  {
    value: 'whBill',
    label: 'Mã đơn hàng'
  },
  {
    value: 'whAppointment',
    label: 'Mã cuộc hẹn'
  },
];
export default function ConfirmVoucherWarehouse() {
  const [form] = Form.useForm()
  const [selectSearch, setSelectSearch] = useState(
    head(listOptionSearch).value
  );
  const [itemActive, setItemActive] = useState(null);

  const {idConfirmVoucher,onCloseConfirmVoucher,onOpenConfirmVoucher,visibleConfirmVoucher} = useModalConfirmVoucher();

  // hook

  const [query] = useConfirmVoucherWarehouseQueryParams();
  const [confirmVoucherWarehouse, isLoading] = useConfirmVoucherWarehouses(query);
  const paging = useConfirmVoucherWarehousePaging(query);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateConfirmVoucherWarehouseParams(query, listOptionSearch);
  useResetConfirmVoucherWarehouse();
  //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('');
  };
  ////////////////////////////////
  useEffect(() => {
    const { status } = query
    form.setFieldsValue({ status: status || null })
  }, [query])
  useEffect(() => {
    let value = listOptionSearch?.find(e => query[e.value])
    setSelectSearch(get(value, 'value', head(listOptionSearch).value))
    setKeyword(query[get(value, 'value', '')])
  }, [])
  const onValuesChange = (value, allValues) => {
    onParamChange({ ...allValues, ...value })
  }

  const columns = [
    {
      title: 'Mã phiếu',
      dataIndex: 'codeSequence',
      key: 'codeSequence',
      render: (item, record, index) => <Typography.Link onClick={() => onOpenConfirmVoucher(record._id)}>{item}</Typography.Link>

    },
  
    
    {
      title: 'Người tạo phiếu',
      dataIndex: 'User',
      key: 'User',
      render: (item, record) => get(item, 'fullName', '')
    },
    {
      title: 'Đối tác',
      dataIndex: 'whPartner',
      key: 'whPartner',
      render: (item, record) => get(record, 'whPartner.name', '')
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (item, record, index) => moment(item).format("DD-MM-YYYY HH:mm:ss")
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (item, record, index) =>   <span className={`confirm-voucher-status ${item?.toLowerCase()}`}>
      {CONFIRM_VOUCHER_STATUS_VI[item]}
    </span>
    },
    {
      title: 'Mã cuộc hẹn',
      dataIndex: 'appointmentIds',
      key: 'appointmentIds',
      align : 'center',
      render: (item, record, index) => {
        return uniqBy(item,(e) => get(e,'appointmentId'))?.map(whAppointment => {
            return <p>
               <Typography.Link onClick={() => window.open(`wh-bill-item/${get(whAppointment,'appointment.whBillItemId')}/update-appointments`)} strong>{get(whAppointment,'appointment.code','')}</Typography.Link>
            </p>
        })
      
      }
    },
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <TabBranch>
        <div className="container-fluid">
          <Breadcrumb title="Quản lý phiếu xác nhận sử dụng thiết bị" />
          <div className="page-content__main">
            <div className="page-content__left">
              <Form
                form={form}
                autoComplete="off"
                onFinish={() => {}}
                scrollToFirstError
                onValuesChange={onValuesChange}
                requiredMark={false}
                labelCol={{ sm: 24, md: 24, lg: 24 }}
              >
                <Form.Item label="Tìm kiếm:">
                  <Search
                    style={{ width: '100%' }}
                    placeholder={`Tìm ${get(
                      listOptionSearch.find((e) => e.value === selectSearch),
                      'label',
                      ''
                    )}`}
                    enterButton
                    allowClear
                    onSearch={(value) => {
                      onParamChange({ [selectSearch]: value });
                    }}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                </Form.Item>
                <Form.Item label="Trạng thái" name="status">
                  <Select
                    placeholder="Hãy chọn trạng thái"
                    allowClear
                    options={keys(CONFIRM_VOUCHER_STATUS)?.map((option) => ({
                      label: CONFIRM_VOUCHER_STATUS_VI[option],
                      value: option
                    }))}
                  />
                </Form.Item>

                <Form.Item>
                  <Select
                    value={selectSearch}
                    style={{
                      width: '100%'
                    }}
                    onChange={handleChangeSelectSearch}
                    options={listOptionSearch}
                  />
                </Form.Item>
              </Form>
            </div>
            <div className="page-content__right" style={{ flex: 1 }}>
              <div
                className="page-wraper__header"
                style={{ justifyContent: 'space-between', display: 'flex' }}
              >
                <div></div>
                <Button
                  onClick={() => onOpenConfirmVoucher()}
                  icon={<PlusOutlined />}
                  type="primary"
                >
                  Thêm mới
                </Button>
              </div>
              {isLoading ? (
                <SkeletonTable columns={columns} rowCount={10} />
              ) : (
                <Table
                  rowKey={(rc) => rc._id}
                  columns={columns}
                  dataSource={confirmVoucherWarehouse}
                  size="middle"
                  onChange={({ current }) => onParamChange({ page: current })}
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                  onExpand={(expanded, record) => {
                    expanded ? setItemActive(record._id) : setItemActive(null);
                  }}
                  expandable={{
                    expandedRowRender: (record) => (
                      <ExpandableVoucher
                        dataSource={get(record, 'listProduct', [])}
                      />
                    ),
                    expandedRowKeys: [itemActive]
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </TabBranch>
      <Modal
        width={'auto'}
        visible={visibleConfirmVoucher}
        footer={null}
        onCancel={onCloseConfirmVoucher}
        destroyOnClose
      >
        <FormConfirmVoucherWarehouse
          id={idConfirmVoucher}
          onCancel={onCloseConfirmVoucher}
        />
      </Modal>
    </div>
  );
}
