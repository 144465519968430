import React from 'react';
import moment from 'moment';
import { Form, DatePicker } from 'antd';

const FilterDatePicker = ({ onChange, value, label,disabledDate , showTime = false ,format = 'YYYY-MM-DD'}) => {
  return (
    <Form.Item
      label={`${label ?? 'Ngày:'}`}
      labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
    >
      <DatePicker
      disabledDate={disabledDate}
        onChange={(value) =>
          onChange(value ? moment(value).format(format) : null)
        }
        value={value ? moment(value) : undefined}
        placeholder="Chọn ngày"
        showTime={showTime}
        style={{width: '100%'}}
      />
    </Form.Item>
  );
};

export default FilterDatePicker;
