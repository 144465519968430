import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Menu, Modal, Select, Switch, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { forIn, get, head } from 'lodash';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { KEYWORD_SEARCH_STATUS, PRIORITY_KEYWORD_SEARCH } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import {
  useResetKeywordSearch,
  useKeywordSearchPaging,
  useKeywordSearchQueryParams,
  useKeywordSearchs,
  useUpdateKeywordSearchParams,
  useDeleteKeywordSearch,
  useUpdateKeywordSearch
} from '~/hooks/keywordSearch';
import { Breadcrumb } from '../Common';
import ModalConfirm from '../Common/ModalConfirm';
import SkeletonTable from '../Utils/SkeletonTable';
import KeywordSearchForm from './Form/KeywordSearchForm';
const listOptionSearch = [
  {
    value: 'keyword',
    label: 'Từ khoá'
  },
  {
    value: 'priority',
    label: 'Độ ưu tiên'
  },
];

export default function KeywordSearch() {
  const canCreate = useMatchPolicy(POLICIES.WRITE_KEYWORDSEARCH)
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_KEYWORDSEARCH)
  const canDelete = useMatchPolicy(POLICIES.DELETE_KEYWORDSEARCH)
    const [form] = Form.useForm()
  const [selectSearch, setSelectSearch] = useState(
    head(listOptionSearch).value
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [id, setId] = useState(null);
  const handleOpenModal = (id) => {
    setIsOpenModal(true);
    if (id) {
      setId(id);
    }
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setId(null);
  };
  // hook
  
  const [query] = useKeywordSearchQueryParams();
  const [keywordSearchs, isLoading] = useKeywordSearchs(query);
  const paging = useKeywordSearchPaging(query);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateKeywordSearchParams(query);
    useResetKeywordSearch();
    const [loadingSubmit,updateKeywordSearch] = useUpdateKeywordSearch(handleCloseModal)

  //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('');
  };
////////////////////////////////
// useEffect(() => {
//   const {sortPercentDiscount,sortPoint} = query
//   form.setFieldsValue({sortPercentDiscount:parseInt(sortPercentDiscount) || null,sortPoint:parseInt(sortPoint || 0)  || null})
// },[query])
useEffect(() => {
  let value = listOptionSearch?.find(e => query[e.value])
  setSelectSearch(get(value,'value',head(listOptionSearch).value))
  setKeyword(query[get(value,'value','')])
},[])
const handleOpenModalDelete = (id) => {
    setId(id)
    setIsOpenModalDelete(true)
  }
  const handleCloseModalDelete = () => {
    setId(null)
    setIsOpenModalDelete(false)
  }
  const handleDelete = () => {
    deleteRank(id);
  }
  const handleChangeStatus = (status,record) => {
    const submitData = {...record,status : status ? KEYWORD_SEARCH_STATUS.ACTIVE : KEYWORD_SEARCH_STATUS.INACTIVE}
    updateKeywordSearch(submitData)
  }
  const handleChangeOnTheTop = (status,record) => {
    const submitData = {...record,onTheTop : status}
    updateKeywordSearch(submitData)
  }
  const [isSubmitLoading, deleteRank] = useDeleteKeywordSearch(handleCloseModalDelete);
////////////////////////////////
const onValuesChange = (value,allValues) => {
  forIn(allValues,(values,key,obj) =>{obj[key] = null})
  onParamChange({...allValues,...value})
}

  const columns = [
    {
      title: 'Từ khoá',
      dataIndex: 'keyword',
      key: 'keyword',
      render: (item, record, index) => item || ''
    },
    {
      title: 'Số lượt click',
      dataIndex: 'countClick',
      key: 'countClick',
      render: (item, record, index) => item || ''
    },
    // {
    //   title: 'Độ ưu tiên',
    //   dataIndex: 'priority',
    //   key: 'priority',
    //   render: (item, record, index) => PRIORITY_KEYWORD_SEARCH[item] || ''
    // },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (item, record, index) => <Switch disabled={!canUpdate} loading={loadingSubmit} onChange={(checked) => handleChangeStatus(checked,record)} defaultChecked={item === KEYWORD_SEARCH_STATUS.ACTIVE}/>
    },
    {
      title: 'Ưu tiên đưa lên đầu',
      dataIndex: 'onTheTop',
      key: 'onTheTop',
      render: (item, record, index) => <Switch disabled={!canUpdate} loading={loadingSubmit} onChange={(checked) => handleChangeOnTheTop(checked,record)} defaultChecked={item}/>
    },

    {
      title: 'Thao tác',
      dataIndex: 'action',
      width: '100px',
      key: 'action',
      render: (item, record, index) => (
        <Dropdown
          overlayStyle={{ width: 150 }}
          trigger={['click']}
          placement="bottomCenter"
          overlay={
            <Menu>
              <Menu.Item disabled={!canUpdate} key={v4()} onClick={() => handleOpenModal(get(record,'_id',''))}>
                <p>Cập nhật</p>
              </Menu.Item>
              <Menu.Item disabled={!canDelete} key={v4()} onClick={() => handleOpenModalDelete(get(record,'_id',''))}>
                <p>Xoá</p>
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
      )
    }
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Từ khoá tìm kiếm" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form
              form={form}
              autoComplete="off"
              onFinish={() => {}}
              scrollToFirstError
              onValuesChange={onValuesChange}
              requiredMark={false}
              labelCol={{ sm: 24, md: 24, lg: 24 }}
            >
              <Form.Item label="Tìm kiếm">
                <Search
                  style={{ width: '100%' }}
                  placeholder={`Tìm ${get(
                    listOptionSearch.find((e) => e.value === selectSearch),
                    'label',
                    ''
                  )}`}
                  enterButton
                  allowClear
                  onSearch={(value) => {
                    let listSearch = {};
                    listOptionSearch.map((e) => (listSearch[e.value] = null));
                    onParamChange({ ...listSearch, [selectSearch]: value });
                  }}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              </Form.Item>
              <Form.Item label="Tìm kiếm theo">
                <Select
                  value={selectSearch}
                  style={{
                    width: '100%'
                  }}
                  onChange={handleChangeSelectSearch}
                  options={listOptionSearch}
                />
              </Form.Item>
              <Form.Item label="Sắp xếp Số lượng click" name="sortCountClick">
                <Select
                  allowClear
                  options={[
                    { label: 'Tăng dần', value: 1 },
                    { label: 'Giảm dần', value: -1 }
                  ]}
                />
              </Form.Item>
              {/* <Form.Item label='Sắp xếp Độ ưu tiên' name='sortPriority'>
                <Select allowClear options={[
                    {label:'Tăng dần',value : 1},
                    {label:'Giảm dần',value : -1},
                ]}/>
             </Form.Item> */}
            </Form>
          </div>
          <div className="page-content__right" style={{ width: '80%' }}>
            <div
              className="page-wraper__header"
              style={{ justifyContent: 'space-between', display: 'flex' }}
            >
              <div></div>
              <Button
                disabled={!canCreate}
                onClick={() => handleOpenModal()}
                type="primary"
              >
                Thêm xếp loại
              </Button>
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={keywordSearchs}
                size="middle"
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={isOpenModal}
        closable={false}
        footer={null}
        onCancel={handleCloseModal}
      >
        <KeywordSearchForm
          updateKeywordSearch={updateKeywordSearch}
          id={id}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
      <ModalConfirm
        text="Xác nhận xoá Từ khoá"
        textOk="Xác nhận"
        type="delete"
        loading={isSubmitLoading}
        visible={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        handleOk={handleDelete}
      />
    </div>
  );
}
