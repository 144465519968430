
import {Button, Col, Form, Input, Modal, Row, Table} from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import { useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import TabBranch from '~/components/Common/TabBranch';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { WH_BILL_STATUS } from '~/constants/defaultValue';
import { useDepreciationWhBillPaging, useDepreciationWhBills, useDepreciationWhBillsQueryParams, useUpdateDepreciationWhBillParams } from '~/hooks';
import TabDetail from './TabDetail';

const { Search } = Input;

const Depreciation = () => {
    const [query] = useDepreciationWhBillsQueryParams();
    const [keyword, { setKeyword, onParamChange }] = useUpdateDepreciationWhBillParams(query);
    const [depreciationWhBills, isLoading] = useDepreciationWhBills(query);
    const [isOpenDetail,setIsOpenDetail] = useState(false);
    const [dataDetail,setDataDetail] = useState();
    const paging = useDepreciationWhBillPaging();
    const onOpenDetail = (data) => {
      setIsOpenDetail(true);
      setDataDetail(data);
    };
    const onCloseDetail = () => {
      setIsOpenDetail(false);
      setDataDetail(null);
    };
  const columns = [
    {
      title:"Mã đơn hàng",
      dataIndex:'billNumber',
      key:'billNumber',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      // width : '3%',
      align: 'center',
      render: (value) => (
        <span className={`bill-status ${value.toLowerCase()}`}>
          {WH_BILL_STATUS[value]}
        </span>
      )
    },
    {
      title: 'Giá trị đơn hàng',
      key: 'grandTotal',
      dataIndex: 'grandTotal',
      align: 'center',
      render: (value) => value?.toLocaleString()
    },
    {
      title: 'Tổng khấu hao đơn hàng',
      key: 'totalExtraction',
      dataIndex: 'totalExtraction',
      align: 'center',
      render: (value,record) => {
        const percent = (value/get(record,'grandTotal',0) * 100)?.toFixed(1);
        return <div>
           <p>{value?.toLocaleString()}</p>
           <Text strong>({percent}%)</Text>
        </div>
      }
    },
    {
      title: 'Thao tác',
      key: 'action',
      align: 'center',
      render: (value,record) => 
      <Button 
      style={{ borderRadius: '8px' }} 
      type='primary'
      onClick={() => onOpenDetail(record)}
      >
      Xem chi tiết
      </Button>
    },
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <TabBranch>
        <div className="container-fluid">
          <Breadcrumb title="Danh sách khấu hao đơn hàng" />
          <div className="page-content__main">
            <div className="page-content__left">
              <Form.Item>
                <Search
                  style={{ width: '100%' }}
                  placeholder="Tìm đơn hàng,ID dịch vụ, cuộc hẹn"
                  enterButton
                  allowClear
                  onSearch={() => onParamChange({ keyword })}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    if (e.target.value === '') {
                      onParamChange({ keyword: null });
                    }
                  }}
                  value={keyword}
                />
              </Form.Item>
            </div>
            <div className="page-content__right">
              {isLoading ? (
                <SkeletonTable columns={columns} rowCount={10} />
              ) : (
                <Table
                  rowKey={(rc) => rc._id}
                  columns={columns}
                  dataSource={depreciationWhBills}
                  onChange={({ current }) => onParamChange({ page: current })}
                  pagination={{
                    ...paging,
                    showSizeChanger: false,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                  size="middle"
                />
              )}
            </div>
          </div>
        </div>
      </TabBranch>
      <Modal
        visible={isOpenDetail}
        centered
        onCancel={onCloseDetail}
        destroyOnClose
        width={800}
        footer={[<Button onClick={onCloseDetail}>Huỷ</Button>]}
      >
        <TabDetail data={dataDetail} />
      </Modal>
    </div>
  );
};

export default Depreciation;
