import React, { useEffect, useMemo, useState } from 'react';
import {
  useCoupons,
  useDeleteCoupon,
  useCouponQueryParams,
  useUpdateCouponParams,
  useResetCoupon,
  useCouponPaging,
  useWhServices,
  useCouponServiceCategories,
  useUser,
  useProfile
} from '~/hooks';
import { Table, Popconfirm, Input, Button, Tag, Select, InputNumber, TreeSelect, Row, Col, Space, Modal, Form } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { CARD_STYLE, MAX_PAGINATION_LIMIT, RELATIVE_POSITION, SERVICE_GROUP_TYPES_VI, TYPE_COUPON } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import CouponForm from './Form';
import moment from 'moment';
import { get, head, map } from 'lodash';
import { formatNumberThreeComma } from '~/hooks/utils';
import { floorFormatter } from '~/utils/helper';
import { FilterDatePicker, GeoTreeSelect } from '../Common';
import SearchCustom from '../Common/SearchCustom';
import { v4 } from 'uuid';
import WhBoxServicePartner from '../WhPartner/WhBoxServicePartner';
const { Search } = Input;

const ColumnActions = ({ _id, deleteCoupon, onOpenForm }) => {
  return (
    <div className="custom-table__actions">
    <WithPermission permission={POLICY.UPDATE_DISCOUNTCODE}>
      <p onClick={() => onOpenForm(_id)}>Sửa</p>
    </WithPermission>
    <WithPermission permission={POLICY.UPDATE_DISCOUNTCODE}>
        <WithPermission permission={POLICY.DELETE_DISCOUNTCODE}>
        <p>|</p>
        </WithPermission>
    </WithPermission>
    <WithPermission permission={POLICY.DELETE_DISCOUNTCODE}>
      <Popconfirm
        title="Bạn muốn xoá phiếu giảm giá này?"
        onConfirm={() => deleteCoupon(_id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </WithPermission>
    </div>
  );
};
const listOptionSearch = [
  {
    value: 'name',
    label: 'Tên mã',
    component: 'Input',
  },
  {
    value: 'code',
    label: 'Mã',
    component: 'Input',
  },
  {
    value: 'discountValue',
    label: 'Giá trị giảm',
    component: 'InputNumber',
  },
  {
    value: 'discountPercent',
    label: 'Phần trăm giảm',
    component: 'InputNumber',
  },
  {
    value: 'maxDiscount',
    label: 'Giảm tối đa',
    component: 'InputNumber',
  },
  {
    value: 'minAmountOfBill',
    label: 'Giá trị đơn hàng tối thiểu',
    component: 'InputNumber',
  },
  {
    value: 'distributionType',
    label: 'Loại coupon',
    component: 'Select',
    optionsInput: map(TYPE_COUPON, (item) => ({ label: TYPE_COUPON[item], value: item })),

  },
];

const Coupons = () => {
  const [query, onPagingChange] = useCouponQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateCouponParams(query, listOptionSearch);
  const [coupons, isLoading] = useCoupons(query);
  const paging = useCouponPaging();
  const [couponId, setCouponId] = useState(null);
  const [selectSearch, setSelectSearch] = useState(get(head(listOptionSearch), 'value', ''));

  const [couponServiceCategories, isCouponServiceCateLoading] = useCouponServiceCategories();
  const couponCategoryOptions = useMemo(() => {
    return map(couponServiceCategories, (service) => {
      return { label: SERVICE_GROUP_TYPES_VI[service], value: service }
    })
  }, [couponServiceCategories]);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const numberOfCoupons = coupons.length;
  const queryGetService = useMemo(() => ({ page: 1, limit: MAX_PAGINATION_LIMIT }), [])
  const [whServices, isLoadingGetservice] = useWhServices(queryGetService)
  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfCoupons === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteCoupon] = useDeleteCoupon(onDeleteSuccess);

  const [, token] = useUser();
  const [profile] = useProfile(token);
  const isRootAdmin = get(profile, 'isSuperAdmin') === true;
  const managementPaths = useMemo(() => {
    return get(profile, "managementArea")?.map(item => get(item, "path"));
  }, [profile]);

  const handleOnChangeAddress = (e) => {
      const path = e?.map((item) => {
      return item?.value
      })
      onParamChange({addressPath: path , page: 1})
  }

  useResetCoupon();

  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
  };

  const onOpenForm = (id) => {
    setCouponId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setCouponId(null);
    setIsOpenForm(false);
  };
  useEffect(() => {
    let value = listOptionSearch?.find(e => query[e.value])
    setSelectSearch(get(value, 'value', head(listOptionSearch).value))
    setKeyword(query[get(value, 'value', '')])
  }, [])
  const columns = [
    {
      title: 'Tên',
      key: 'name',
      dataIndex: 'name'
    },

    {
      title: 'Mã',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Loại Coupon',
      dataIndex: 'distributionType',
      key: 'distributionType'
    },
    {
      title: 'Loại Dịch vụ sử dụng',
      dataIndex: 'serviceId',
      key: 'serviceId',
      width : 200,
      // render: (item, record) => <div style={{ maxWidth: '400px' }}>
      //   {get(record, 'whServiceId', [])?.map(e => {
      //     const service = get(record,'listWhService',[])?.find(s => s._id === e)
      //     return <Tag className='tag-threeDot' color='processing'>{`${get(service, 'name.vi', get(service, 'name', ''))} - ${get(service, 'detailName.vi', get(service, 'detailName', ''))}`}</Tag>
      //   })}
      // </div>
      render: (item, record) => <div style={{ maxWidth: '600px' }}><WhBoxServicePartner services={get(record,'listWhService',[])}/></div>
    },
    {
      title: 'Bắt đầu',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (startTime) =>
        startTime ? moment(startTime).format('DD-MM-YYYY HH:mm:ss') : ''
    },

    {
      title: 'Kết thúc',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (endTime) =>
        endTime ? moment(endTime).format('DD-MM-YYYY HH:mm:ss') : ''
    },

    {
      title: 'Thể loại giảm',
      key: 'type',
      render: (record) =>
        record.discountValue ? 'Theo số tiền' : 'Theo phần trăm'
    },

    {
      title: 'Giá trị giảm',
      key: 'value',
      render: (record) => formatNumberThreeComma(get(record, 'discountValue', 0)) || `${formatNumberThreeComma(get(record, 'discountPercent', 0))}%`
    },

    {
      title: 'Giảm tối đa',
      key: 'maxDiscount',
      dataIndex: 'maxDiscount',
      render: (item) => formatNumberThreeComma(item || 0)
    },

    {
      title: 'Lượt sử dụng tối đa',
      key: 'maxUsage',
      dataIndex: 'maxUsage'
    },
    {
      title: 'Giảm phí ship tối đa',
      key: 'maxShippingDiscount',
      dataIndex: 'maxShippingDiscount'
    },
    {
      title: 'Giá trị đơn tối thiểu',
      key: 'minAmountOfBill',
      dataIndex: 'minAmountOfBill',
      render: (item) => floorFormatter(item || 0)
    }
    // {
    //   title: 'Free ship',
    //   key: 'isFreeShipping',
    //   dataIndex: 'isFreeShipping',
    //   render: (isFreeShipping) => <Checkbox checked={isFreeShipping} disabled />
    // }
  ];

  columns.push({
    title: 'Thao tác',
    key: 'action',
    width: '110px',
    render: (record) => {
      return (
        <ColumnActions
          {...record}
          deleteCoupon={deleteCoupon}
          onOpenForm={onOpenForm}
        />
      );
    }
  });
  // listOptionSearch.push(  #FIXME : Call API render
  //   {
  //     value: 'serviceGroupType',
  //     label: 'Loại Dịch vụ sử dụng',
  //     component : 'Select',
  //     optionsInput: couponCategoryOptions
  //   })
    // console.log(listOptionSearch,'listOptionSearch');
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách phiếu giảm giá" />

        <div className="page-content__main">
          <div className="page-content__left" style={CARD_STYLE}>
            <div>
              <Form.Item>
                <SearchCustom
                  keyword={keyword}
                  listOptionSearch={listOptionSearch}
                  onParamChange={onParamChange}
                  selectSearch={selectSearch}
                  setKeyword={setKeyword}
                  key={v4()}
                />
              </Form.Item>

              <Form.Item>
                <Select
                  value={selectSearch}
                  style={{
                    width: '100%'
                  }}
                  onChange={handleChangeSelectSearch}
                  options={listOptionSearch}
                />
              </Form.Item>
            </div>

            <FilterDatePicker
              label="Từ"
              value={query.keyworstartTimed}
              onChange={(value) => onParamChange({ keyworstartTimed: value })}
              showTime={true}
              format="YYYY-MM-DD HH:mm:ss"
            />
            <FilterDatePicker
              label="Đến"
              value={query.endTime}
              onChange={(value) => onParamChange({ endTime: value })}
              showTime={true}
              format="YYYY-MM-DD HH:mm:ss"
            />
            {/* <FilterCity
              value={query.cityId}
              onChange={(value) => onParamChange({ cityId: value })}
              isDisabled={isLoading}
            /> */}
            <Space direction="vertical">
              <span>Tìm theo khu vực</span>
              <GeoTreeSelect
                style={{
                  width: 210
                }}
                label="test"
                autoClearSearchValue
                checkablePositions={[RELATIVE_POSITION.IS_CHILD]}
                // disabled={!isRootAdmin === get(profile, "_id")}\
                enabledValues={isRootAdmin ? null : managementPaths}
                labelInValue={true}
                // listHeight={360}
                listItemHeight={200}
                multiple={true}
                // onSelect={(e)=> onSearchByAddress(e)}
                showCheckedStrategy={TreeSelect.SHOW_ALL}
                showEnabledValuesOnly={true}
                showSearch={true}
                size="large"
                treeCheckStrictly={true}
                treeCheckable={true}
                treeDefaultExpandedKeys={['1', '2', '3']}
                onChange={handleOnChangeAddress}
              />
            </Space>
          </div>

          <div className="page-content__right" style={CARD_STYLE}>
            <div className="page-wraper__header">
              <WithPermission permission={POLICY.WRITE_DISCOUNTCODE}>
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={() => onOpenForm()}
                >
                  Thêm mới
                </Button>
              </WithPermission>
            </div>

            {isLoading ? (
              <SkeletonTable
                rowCount={coupons.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={coupons}
                scroll={{ x: 2000 }}
                onChange={onPagingChange}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={isOpenForm}
        width={1020}
        footer={[]}
        onCancel={onCloseForm}
        className="form-modal"
        destroyOnClose
      >
        <CouponForm
          whServices={whServices}
          onClose={onCloseForm}
          id={couponId}
        />
      </Modal>
    </div>
  );
};

export default Coupons;
