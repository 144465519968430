import { Col, Row, Select, Tabs } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useProfile } from '~/hooks';
import {
  useReportSystemPaging,
  useReportSystems,
  useReportSystemsQueryParams,
  useUpdateReportSystemParams,
} from '~/hooks/reportSystem';
import { FilterDatePicker } from '../Common';
import SelectMultiBranch from '../Common/SelectMultiBranch';
import TableSalesSystem from './TableSalesSystem';
import DebounceSelectByCategory from '../WhCategory/SearchByCategory';
const { TabPane } = Tabs;
const arrTabs = [
  {type : 'receipts',tabs : '1',label : 'Tổng thu'},
  {type : 'payment',tabs : '2',label : 'Tổng chi'},
  {type : 'receiptsCustomer',tabs : '3',label : 'Khoản phải thu'},
  {type : 'paymentCustomer',tabs : '4',label : 'Khoản phải chi'},
  {type : 'customerCancel',tabs : '5',label : 'Phiếu thu (Đơn hàng đã chấm dứt)'},
]
export default function TabTable({
  date, setDate, callback, setArrCheckBox,
  whCategories,
  branchIds,
  setBranchIds,
}) {
  const [profile] = useProfile();
  const [type, setType] = useState('receipts');
  const [categorySelect,setCategorySelect] = useState(null)
  const [query] = useReportSystemsQueryParams(type, date,branchIds);
  const [activeKey, setActiveKey] = useState(get(query,'tabs','1'));
  const [{ onParamChange }] = useUpdateReportSystemParams(query);
  const [dataSource, isLoading] = useReportSystems(query);
  const paging = useReportSystemPaging();
  const [whCategory, setWhCategory] = useState(null);
  
  useEffect(() => {
    if (!whCategory && query?.whCategory) {
      const splitWhCategory = query?.whCategory.split(',');
      setWhCategory(splitWhCategory);
    };
  }, [query]);

  const onChangeValueSelect = (value) => {
    setCategorySelect(value)
    onParamChange({category : value})
  }
  const onTabClick = (key) => {
    setActiveKey(key);
    callback(key)
    
    arrTabs?.some(e => {
      if(get(e,'tabs') === key){
        setType(get(e, 'type'));
        onParamChange({ page: 1, limit: 10, type: get(e,'type',''),tabs : get(e,'tabs'),category:null });
        setCategorySelect(null)
        return true
      }
    })
  };
  const onChangeBranch = (value) => {
    setBranchIds(value);
  }
  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
        {get(profile,'isSuperAdmin') && <>
        <Text>Chi nhánh: </Text>
          <SelectMultiBranch style={{width : '100%'}} value={branchIds} onChange={onChangeBranch}/>
        </>}
          
          <FilterDatePicker
            onChange={(value) => {
              setDate({ ...date, startDate: value });
              onParamChange({ page: 1 });
            }}
            value={get(
              date,
              'startDate',
              moment().startOf('month').format('YYYY-MM-DD')
            )}
            label="Từ"
          />
          <FilterDatePicker
          disabledDate={current => current<= moment(date.startDate)}
            onChange={(value) => {
              setDate({ ...date, endDate: value });
              onParamChange({ page: 1 });
            }}
            value={get(
              date,
              'endDate',
              moment().endOf('month').format('YYYY-MM-DD')
            )}
            label="Đến"
          />
          <Text>Chọn nhóm dịch vụ : </Text>
          {/* <Select
            allowClear
            value={categorySelect}
            showSearch
            placeholder="Tìm kiếm..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.value
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.label
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '100%' }}
            onChange={onChangeValueSelect}
            options={whCategories?.map(e => ({
              value: get(e, 'slug', ''),
              label: get(e, 'name.vi', ''),
            }))}
          /> */}
          <DebounceSelectByCategory
            onChange={(e) => {
              setWhCategory(e);
              onParamChange({ whCategory: e });
            }}
            value={whCategory}
            isFirstFetch={!!whCategory}
            placeholder="Tìm nhóm dịch vụ"
            style={{ minWidth: '100%' }}
          />
          
        </Col>
        <Col span={18}>
          <Tabs
            defaultActiveKey={activeKey || '1'}
            destroyInactiveTabPane
            onChange={() => {}}
            onTabClick={onTabClick}
          >
          {arrTabs?.map(e =>  <TabPane tab={get(e,'label')} key={get(e,'tabs')}>
             <TableSalesSystem
                paging={paging}
                query={query}
                onPagingChange={({ current, pageSize }) => onParamChange({ page: current, limit: pageSize })}
                dataSource={dataSource}
                isLoading={isLoading}
                date={date}
                type={get(e, 'type')}
                callback = {setArrCheckBox}
              />
              
            </TabPane>)}
          </Tabs>
        </Col>
      </Row>
    </>
  );
}
