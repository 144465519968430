import { EditFilled } from '@ant-design/icons';
import { Button, Col, Input, Popconfirm, Row, Switch, Table, Tag, Typography } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import {
  useDeleteJob, useJobPaging, useJobQueryParams, useJobs, useResetJob, useUpdateJob, useUpdateJobParams
} from '~/hooks/job';
import { PATH_APP } from '~/routes/paths';
import ListExpand from '../Common/ListExpand';
import './index.scss'
const { Search } = Input;

const ColumnActions = ({
  id,
  status,
  deleteJob,
  updateJob,
  isSubmitLoading,
  canUpdate,
  canDelete,
  ...restProps
}) => {
  return (
    <Row justify='center' gutter={16} wrap={false}>

      <Col>
      <WithPermission permission={POLICY.UPDATE_JOB}>
        <Link to={`/job-edit/${id}`}>
          Sửa
        </Link>
        {/* <WithPermission permission={POLICY.DELETE_JOB}>
            <span style={{marginLeft : 5}}>|</span>
        </WithPermission> */}
      </WithPermission>
      </Col>

      <Col>
    
      <WithPermission permission={POLICY.DELETE_JOB}>
      <Popconfirm
        title="Bạn muốn xoá tuyển dụng này?"
        onConfirm={() => deleteJob(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <Typography.Paragraph style={{cursor : 'pointer'}} type='danger'>Xoá</Typography.Paragraph>
      </Popconfirm>
      </WithPermission>
      </Col>

      <Col>
      {/* <span style={{marginLeft : 5}}>|</span> */}
      <WithPermission permission={POLICY.UPDATE_JOB}>
         <Switch
          checked={status === 'ACTIVE'}
          onChange={(value) =>
            updateJob({ status: value ? 'ACTIVE' : 'INACTIVE', _id : id })
          }
          loading={isSubmitLoading}
        />
      </WithPermission>
      </Col>
    </Row>
  );
};

const Job = ({ history }) => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_JOB);
  const canDelete = useMatchPolicy(POLICY.DELETE_JOB);
  const [query] = useJobQueryParams();
  const [Jobs, isLoading] = useJobs(query);
  const [keyword, setKeyword,{ onParamChange }] = useUpdateJobParams(query);

  const [, deleteJob] = useDeleteJob();
  const [isSubmitLoading, updateJob] = useUpdateJob();
  const paging = useJobPaging();
  useResetJob();

  const columns = [
    {
      title: 'Tiêu đề',
      dataIndex: 'title', 
      key: 'title',
      width : '35%',
    },
    {
      title: 'Vị trí',
      dataIndex: 'jobVacancy', 
      key: 'jobVacancy',
      width : '20%',
      render : (jobVacancy) => get(jobVacancy,'title','')
    },
    {
      title: 'Công ty',
      dataIndex: 'companyJob', 
      key: 'companyJob',
      width : '20%',
      render : (companyJob) => get(companyJob,'name','')
    },
    {
      title: 'Khu vực',
      dataIndex: 'city', 
      key: 'city',
      width : '10%',
      // render : (city) => city?.map((name) => <Tag style={{marginBottom : 2}} key={name} color='processing'>{name}</Tag>)
      render : (city) => <ListExpand dataSource={city} itemRender={e => <Tag style={{marginBottom : 2}} key={e} color='processing'>{e}</Tag>}/>
    },
    ...(canDelete || canUpdate
      ? [
          {
            title: 'Thao tác',
            key: 'action',
            width: '25%',
            align : 'center',
            render: (record) => {
              return (
                <ColumnActions
                  {...record}
                  id={get(record,'_id')}
                  canDelete={canDelete}
                  canUpdate={canUpdate}
                  deleteJob={deleteJob}
                  updateJob={updateJob}
                  isSubmitLoading={isSubmitLoading}
                />
              );
            }
          }
        ]
      : [])
  ];
  const onSearch = (kw) => onParamChange({keyword : kw?.trim()})
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách tuyển dụng" />

        <div className="page-content__main">
          <div className="page-content__left">
            <Search
              allowClear
              style={{ width: '100%' }}
              placeholder="Tìm tuyển dụng"
              enterButton
              onSearch={onSearch}
              // onSearch={onSearch}
            />
          </div>

          <div className="page-content__right">
            <div className="page-wraper__header">
              <WithPermission permission={POLICY.WRITE_JOB}>
                <Link to={PATH_APP.job.create}>
                  <Button type="primary" style={{ float: 'right' }}>
                    {' '}
                    Thêm mới
                  </Button>
                </Link>
              </WithPermission>
            </div>

            <Table
              loading={isLoading}
              className="wh-category-table-striped-rows"
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={Jobs}
              onChange={({ current, pageSize }) =>
                onParamChange({ page: current, limit: pageSize })
              }
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `,
                showSizeChanger: true
              }}
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Job;
