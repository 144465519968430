import { CaretDownOutlined, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Menu, Modal, Row, Select, Switch, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import Text from 'antd/lib/typography/Text';
import { get, parseInt } from 'lodash';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { Breadcrumb, WithPermission } from '~/components/Common';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { useMatchPolicy, useMedicinePaging } from '~/hooks';
import POLICY from '~/constants/policy';
import { Link } from 'react-router-dom';
import { useDeleteMedicalEquipment, useMedicalEquipmentQueryParams, useMedicalEquipments, useUpdateMedicalEquipment, useUpdateMedicalEquipmentParams } from '~/hooks/medicalEquipment';
import MedicalEquipmentForm from './MedicalEquipmentForm';

// const ColumnActions = ({ id, action, deleteEquipment, updateEquipment,isSubmitLoading,handleOpenFormUpdate, ...restProps }) => {
//   return (
//     <div className="custom-table__actions">
//       <WithPermission permission={POLICY.UPDATE_WAREHOUSE}>
//         <Link
//           onClick={() =>
//           {
//             if (typeof handleOpenFormUpdate === 'function'){
//             handleOpenFormUpdate(id)
//           }
//           }}
//         >
//           {/* <p>Sửa</p> */}
//           <EditFilled />
//         </Link>
//       </WithPermission>
//       <WithPermission permission={POLICY.UPDATE_WAREHOUSE}>
//         <WithPermission permission={POLICY.DELETE_WAREHOUSE}>
//           <p>|</p>
//         </WithPermission>
//       </WithPermission>
//       <WithPermission permission={POLICY.DELETE_WAREHOUSE}>
//         <Switch
//           checked={action === 'ACTIVE'}
//           onChange={(value) => updateEquipment({ action: value ? 'ACTIVE' : 'INACTIVE', id })}
//           loading={isSubmitLoading}
//         />
//       </WithPermission>
//     </div>
//   );
// };

const MedicalEquipments = () => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [searchBy,setSearchBy] = useState("name")
  const [id, setId] = useState(null);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WAREHOUSE);
  const canDelete = useMatchPolicy(POLICY.DELETE_WAREHOUSE);
  const [query] = useMedicalEquipmentQueryParams(searchBy)
  const [keyword, { setKeyword, onParamChange }] = useUpdateMedicalEquipmentParams(query)
  const [equipments, isLoading] = useMedicalEquipments(query)
  const [isSubmitLoading, updateEquipment] = useUpdateMedicalEquipment()
  const [, deleteEquipment] = useDeleteMedicalEquipment()
  const paging = useMedicinePaging()
  const handleOpenForm = () => {
    setIsOpenForm(true);
  };
  const handleOpenFormUpdate = (id) => {
    setIsOpenForm(true);
    setId(id);
  };
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
  };

  const handleCloseForm = () => {
    setIsOpenForm(false);
    setId(null);
  };
  const columns = [
    {
      title: 'STT',
      align: 'center',
      // dataIndex: 'index',
      key: 'index',
      width: "100px",
      render: (item, record, index) => (
        <Text>
          {get(paging, 'pageSize', 0) * (get(paging, 'current', 0) - 1) +
            index + 1}
        </Text>
      )
    },
    {
      title: 'Loại thiết bị y tế',
      align: 'start',
      dataIndex: 'name',
      key: 'name',
      render: (value) =>  value?.vi ?  value?.vi :  value?.en
    },
    // ... (canDelete || canUpdate) ? [{
    //   title: 'Thao tác',
    //   key: 'action',
    //   width: '150px',
    //   render: (record) => {
    //     return <ColumnActions
    //       {...record}
    //       deleteEquipment={deleteEquipment} 
    //       updateEquipment={updateEquipment}
    //       isSubmitLoading={isSubmitLoading}
    //       handleOpenFormUpdate = {handleOpenFormUpdate}
    //     />;
    //   }
    // }] : []
  ];

  
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh mục thiết bị y tế" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Search
              style={{ maxWidth: '100%' }}
              placeholder="Tìm tên thiết bị y tế"
              enterButton
              onSearch={() => onParamChange({ [searchBy]: keyword, page: 1 })}
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
          </div>

          <div className="page-content__right">
            {isLoading || isSubmitLoading ? (
              <SkeletonTable
                // rowCount={Equipments.length}
                columns={columns}
                // pagination={paging}
              />
            ) : (
              <Table
                className="wh-service-table-striped-rows"
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={equipments}
                onChange={({ current }) => {
                  onParamChange({ page: current });
                }}
                size="middle"
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>

        {/* <div className="page-wraper__header">
          
          <WithPermission permission={POLICY.WRITE_WAREHOUSE}>
            <Button
              type="primary" style={{ float: 'right' }}
              onClick = {()=> handleOpenForm()}
            >
                Thêm mới
              </Button>
          </WithPermission>
        </div> */}
      </div>

      <Modal
        visible={isOpenForm}
        closable={false}
        footer={null}
        onCancel={handleCloseForm}
        width={700}
        title={'Thêm mới danh mục thiết bị y tế'}
      >
        <MedicalEquipmentForm
          setIsOpenForm={setIsOpenForm}
          id={id}
          setId={setId}
          handleOpenFormUpdate={handleOpenFormUpdate}
          handleCloseForm={handleCloseForm}
        />
      </Modal>
    </div>
  );
};

export default MedicalEquipments;
