import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined, FallOutlined, RiseOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Menu, Modal, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import Text from 'antd/lib/typography/Text';
import { get, head } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid';
import { TYPE_POINT_SYSTEM_REFERRAL, TYPE_POINT_SYSTEM_REFERRAL_VI, TYPE_REWARD_POINTSYSTEM, WALLET_ACTION_NAME, WITH_DRAW_MONEY_STATUS_VI } from '~/constants/defaultValue';
import { useResetPointSystem, usePointSystemPaging, usePointSystemQueryParams, usePointSystems, useUpdatePointSystem, useUpdatePointSystemParams } from '~/hooks/pointSystem';
import { formatNumberThreeComma, formatNumberThreeCommaToFixed } from '~/hooks/utils';
import { Breadcrumb } from '../Common';
import SkeletonTable from '../Utils/SkeletonTable';
import { FormGroup } from '@material-ui/core';
const listOptionSearch = [
  {
    value: 'types',
    label: 'Loại điểm thưởng'
  },
  {
    value: 'action',
    label: 'Loại điểm ví'
  },
  {
    value: 'point',
    label: 'Số điểm thưởng',
    typeInput : 'InputNumber'
  },
  {
    value: 'money',
    label: 'Số tiền ví'
  },
  {
    value: 'userId',
    label: 'Tên người dùng'
  },
  {
    value: 'desc',
    label: 'Tên chiến dịch'
  }
];
export default function PointSystem() {
  const [selectSearch, setSelectSearch] = useState(head(listOptionSearch).value);
  const [query] = usePointSystemQueryParams()
  const [pointSystems,isLoading] = usePointSystems(query)
  const paging = usePointSystemPaging(query)
  const [keyword ,{setKeyword, onParamChange }] = useUpdatePointSystemParams(query,listOptionSearch)
 useResetPointSystem()
 //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('')
  };
  useEffect(() => {
    let value = listOptionSearch?.find(e => query[e.value])
    setSelectSearch(get(value,'value',head(listOptionSearch).value))
    setKeyword(query[get(value,'value','')])
  },[])
  const columns = [
    {
      title:'Loại',
      dataIndex:'withdrawMoneyStatus',
      key:'withdrawMoneyStatus',
      render: (item,record) => {
        if(get(record,'typeRewardItem') === TYPE_REWARD_POINTSYSTEM.pointSystem.value){
          return  <Text strong>{WITH_DRAW_MONEY_STATUS_VI[item]}</Text>
        }
        if(get(record,'typeRewardItem') === TYPE_REWARD_POINTSYSTEM.wallet.value){
          return <Text strong>{WALLET_ACTION_NAME[get(record,'action')]?.vi}</Text>
        }
        
      }
    },
    {
      title:'Điểm',
      dataIndex:'amount',
      key:'amount',
      render: (item,record) =>  {
        if(get(record,'typeRewardItem') === TYPE_REWARD_POINTSYSTEM.pointSystem.value){
          return get(record,'type') === TYPE_POINT_SYSTEM_REFERRAL.credit ? <Text type='success' strong>{formatNumberThreeCommaToFixed(item)} <ArrowUpOutlined /></Text> : <Text type="danger"  strong>{formatNumberThreeCommaToFixed(item)} <ArrowDownOutlined /></Text>
        }
        if(get(record,'typeRewardItem') === TYPE_REWARD_POINTSYSTEM.wallet.value){
          return get(record,'action') === WALLET_ACTION_NAME.PAY_IN.value ? <Text type='success' strong>{formatNumberThreeCommaToFixed(get(record,'money',0))} <ArrowUpOutlined /></Text> : <Text type="danger"  strong>{formatNumberThreeCommaToFixed(get(record,'money',0))} <ArrowDownOutlined /></Text>
        }
      }
    },
    {
      title:'Ngày',
      dataIndex:'createdAt',
      key:'createdAt',
      render: (item) => moment(item).format('DD-MM-YYYY HH:mm:ss') || 0
    },
    {
      title:'Tên chiến dịch',
      dataIndex:'desc',
      key:'desc',
      render: (item) => item || ''
    },
    {
      title:'Tên người dùng',
      dataIndex:'user',
      key:'user',
      render: (item,record) => get(record,'whPartner.name',get(record,'customerAccount.fullName',''))
    },
  ]
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Điểm thưởng hệ thống" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form layout="vertical">
              <Form.Item>
                <Select
                  value={selectSearch}
                  style={{
                    width: '100%'
                  }}
                  onChange={handleChangeSelectSearch}
                  options={listOptionSearch}
                />
              </Form.Item>
            </Form>
            {selectSearch === 'types' ? (
              <Select
                allowClear
                placeholder="Chọn Loại cần tìm"
                style={{ width: '100%' }}
                value={keyword || ''}
                onChange={(value) => {
                  onParamChange({ [selectSearch]: value });
                  setKeyword(value);
                }}
                options={[
                  {
                    label: TYPE_POINT_SYSTEM_REFERRAL_VI.credit,
                    value: TYPE_POINT_SYSTEM_REFERRAL.credit
                  },
                  {
                    label: TYPE_POINT_SYSTEM_REFERRAL_VI.debit,
                    value: TYPE_POINT_SYSTEM_REFERRAL.debit
                  },
                  { label: 'Tất cả', value: '' }
                ]}
              />
            ) : selectSearch === 'action' ? (
              <Select
                allowClear
                placeholder="Chọn Loại cần tìm"
                style={{ width: '100%' }}
                value={keyword || ''}
                onChange={(value) => {
                  onParamChange({ [selectSearch]: value });
                  setKeyword(value);
                }}
                options={[
                  {
                    label: WALLET_ACTION_NAME.PAY_IN.vi,
                    value: WALLET_ACTION_NAME.PAY_IN.value
                  },
                  {
                    label: WALLET_ACTION_NAME.PAY_OUT.vi,
                    value: WALLET_ACTION_NAME.PAY_OUT.value
                  },
                  { label: 'Tất cả', value: '' }
                ]}
              />
            ) : (
              <Search
                style={{ width: '100%' }}
                placeholder={`Tìm ${
                  listOptionSearch.find((e) => e.value === selectSearch).label
                }`}
                enterButton
                allowClear
                onSearch={(value) => {
                  onParamChange({ [selectSearch]: value?.trim() });
                }}
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
            )}
          </div>
          <div className="page-content__right">
            <div className="page-content__main">
              {/* <div className="page-content__left"> */}
              {/* <Form
             form={form}
             autoComplete="off"
             onFinish={() => {}}
             scrollToFirstError
             onValuesChange={onValuesChange}
             requiredMark={false}
             // initialValues={{ managementArea: managementAreaQuery }}
           >
             <FilterWhQuotationStatus
               value={query.state}
               onChange={(value) => onParamChange({state : value}) }
             />
             <FilterDatePicker
               label="Từ"
               value={query.startDate}
               onChange={(value) =>
               {
                 onParamChange({startDate : value})
                 // dispatch(addQueryFetch({ key: 'startDate', value: value }))
               }
               }
             />
             <FilterDatePicker
               label="Đến"
               value={query.endDate}
               onChange={(value) =>
               {
                   onParamChange({endDate : value})

                 // dispatch(addQueryFetch({ key: 'endDate', value: value }))
                 }
               }
             />
           </Form> */}
              {/* </div> */}
              <div className="page-content__right" style={{ width: '100%' }}>
                <div
                  className="page-wraper__header"
                  style={{ justifyContent: 'space-between', display: 'flex' }}
                >
                  <div></div>
                  {/* <Button onClick={handleOpenModal} type='primary'>Thêm vật phẩm - điểm thưởng</Button> */}
                </div>
                {isLoading ? (
                  <SkeletonTable columns={columns} rowCount={10} />
                ) : (
                  <Table
                    rowKey={(rc) => rc._id}
                    columns={columns}
                    dataSource={pointSystems}
                    size="middle"
                    onChange={({ current }) => onParamChange({ page: current })}
                    pagination={{
                      ...paging,
                      showTotal: (total) => `Tổng cộng: ${total} `
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal visible={isOpenModal} closable={false} footer={null} onCancel={handleCloseModal}>
        <PointSystemForm id={id} handleCloseModal={handleCloseModal}/>
     </Modal> */}
    </div>
  );
}
