import React, { useState } from 'react';
import {
  usePartners,
  useDeletePartner,
  usePartnerQueryParams,
  usePartnerPaging,
  useUpdatePartnerParams,
  useResetPartner
} from '~/hooks';
import { Table, Popconfirm, Input, Button, Form } from 'antd';
import { Link } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import FilterModules from '~/components/Common/FilterModules';
import PartnerForm from './Form';
import { get } from 'lodash';

const { Search } = Input;

const ColumnActions = ({
  _id,
  deletePartner,
  shouldShowDevider,
  onOpenForm,
  permission
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={get(POLICY,`UPDATE_${permission}`)}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      {shouldShowDevider && <p>|</p>}

      <WithPermission permission={get(POLICY,`DELETE_${permission}`)}>
        <Popconfirm
          title="Bạn muốn xoá đối tác này?"
          onConfirm={() => deletePartner(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const Partners = ({ module, name,permission }) => {
  const [query, onPagingChange] = usePartnerQueryParams(module);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdatePartnerParams(query);
  const [partners, isLoading] = usePartners(query);
  const [partnerId, setPartnerId] = useState(null);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const paging = usePartnerPaging();

  const hasUpdate = useMatchPolicy(get(POLICY,`UPDATE_${permission}`));
  const hasDelete = useMatchPolicy(get(POLICY,`DELETE_${permission}`));
  const shouldShowDevider = hasUpdate && hasDelete;

  const numberOfPartners = partners.length;

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfPartners === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deletePartner] = useDeletePartner(onDeleteSuccess);

  useResetPartner();

  const onOpenForm = (id) => {
    setPartnerId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setPartnerId(null);
    setIsOpenForm(false);
  };

  const columns = [
    {
      title: 'Tên Đối tác',
      key: 'partnerName',
      render: (rc) => <Link to={`/partner/detail/${rc._id}`}>{rc.name}</Link>
    },
    {
      title: 'Loại',
      key: 'modules',
      render: (rc) => {
        return Object.values(rc.modules).join(', ');
      }
    },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      render: (address) => (
        <p>
          {address &&
            `${address?.street} - ${address?.ward} - ${address?.district} - ${address?.city}`}
        </p>
      )
    },

    {
      title: 'Điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },

    {
      title: 'Chi nhánh',
      dataIndex: 'count',
      key: 'count'
    }
  ];

  if (hasUpdate || hasDelete) {
    columns.push({
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return (
          <ColumnActions
            {...record}
            deletePartner={deletePartner}
            shouldShowDevider={shouldShowDevider}
            onOpenForm={onOpenForm}
            permission={permission}
          />
        );
      }
    });
  }
  const a = 'name';
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title={`Danh sách đối tác ${name} (lớn)`} />

        <div className="page-content__main">
          <div className="page-content__left" style={CARD_STYLE}>
            <Form layout='vertical'>
              <Form.Item vertical label="Tìm kiếm">
                <Search
                  style={{ width: '100%' }}
                  placeholder={`Tìm ${name}`}
                  enterButton
                  onSearch={() => onParamChange({ keyword })}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              </Form.Item>

              <Form.Item vertical>
                <FilterCity
                  value={query.cityId}
                  onChange={(value) =>
                    onParamChange({
                      cityId: value
                    })
                  }
                  isDisabled={isLoading}
                />
              </Form.Item>
            </Form>

            {/* <FilterModules
              value={query?.modules?.split(',')}
              onChange={(value) => onParamChange({ modules: value })}
            /> */}
          </div>

          <div className="page-content__right" style={CARD_STYLE}>
            <div className="page-wraper__header">
              <WithPermission permission={get(POLICY, `WRITE_${permission}`)}>
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={() => onOpenForm()}
                >
                  Thêm mới
                </Button>
              </WithPermission>
            </div>

            {isLoading ? (
              <SkeletonTable
                columns={columns}
                rowCount={partners.length}
                pagination={paging}
              />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={partners}
                onChange={onPagingChange}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <PartnerForm isOpen={isOpenForm} onClose={onCloseForm} id={partnerId} />
    </div>
  );
};

export default Partners;

