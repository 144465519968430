import { Button, Col, Input, Modal, Popconfirm, Row, Table, Typography } from 'antd';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import {
  useDeletePositionJob, usePositionJobPaging, usePositionJobQueryParams, usePositionJobs, useResetPositionJob, useUpdatePositionJobParams
} from '~/hooks/positionJob';
import LinkFile from '../Common/TypeFile/LinkFileImage';
import FormPositionJob from './FormPositionJob';
import PickQuestion from './PickQuestion';
const { Search } = Input;

const PositionJob = ({ }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [openEmail, setOpenEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [openQuestion, setOpenQuestion] = useState(false);
  const [query] = usePositionJobQueryParams();
  const [PositionJobs, isLoading] = usePositionJobs(query);
  const [keyword, setKeyword, { onParamChange }] = useUpdatePositionJobParams(query);

  const [, deletePositionJob] = useDeletePositionJob();
  const paging = usePositionJobPaging();
  useResetPositionJob();

  const onOpen = useCallback((id) => {
    setOpen(true);
    id && setId(id);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
    setId(null);
  }, []);

  const onOpenQuestion = useCallback((id) => {
    setOpenQuestion(true);
    id && setId(id);
  }, []);
  const onCloseQuestion = useCallback(() => {
    setOpenQuestion(false);
    setId(null);
  }, []);

  const onOpenEmail = useCallback((em) => {
    setOpenEmail(true);
    setEmail(em);
  }, []);
  const onCloseEmail = useCallback(() => {
    setOpenEmail(false);
    setEmail("");
  }, []);
  const columns = [
    {
      title: 'Tên vị trí',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Nội dung gửi email',
      dataIndex: 'email',
      key: 'email',
      render: (email, record) => <Typography.Link onClick={() => onOpenEmail(email)}>
        Xem chi tiết
      </Typography.Link>
    },
    {
      title: 'File đính kèm',
      dataIndex: 'files',
      key: 'files',
      render: (text, record, index) => (
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'start'
          }}
        >
          {text?.map((e) => {
            return (
              <LinkFile
                key={v4()}
                href={get(e, 'response.url')}
                url={get(e, 'name')}
              />
            );
          })}
        </div>
      )
    },
    {
      title: 'Bộ câu hỏi',
      dataIndex: '_id',
      key: 'jobQuestionIds',
      render: (_id) => <Typography.Link onClick={() => onOpenQuestion(_id)}>
        Xem bộ câu hỏi
      </Typography.Link>
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      key: '_id',
      width: 100,
      render: (_id) => <Row gutter={8} justify='space-between'>
        <WithPermission permission={POLICY.UPDATE_JOB}>
          <>
            <Col>
              <Typography.Link onClick={() => onOpen(_id)}>
                Sửa
              </Typography.Link>
            </Col>
            <Col>
              |
            </Col>
          </>
        </WithPermission>
        <WithPermission permission={POLICY.DELETE_JOB}>
          <Col>
            <Popconfirm
              title="Xác nhận xoá"
              onConfirm={() => deletePositionJob(_id)}
            >
              <Typography.Link type='danger'>
                Xoá
              </Typography.Link>
            </Popconfirm>
          </Col>
        </WithPermission>
      </Row>
    },
  ];
  const onSearch = (kw) => onParamChange({ keyword: kw?.trim() })
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách vị trí ứng tuyển" />

        <div className="page-content__main">
          <div className="page-content__left">
            <Search
              allowClear
              style={{ width: '100%' }}
              placeholder="Tìm vị trí ứng tuyển"
              enterButton
              onSearch={onSearch}
              // onSearch={onSearch}
            />
          </div>
          <div className="page-content__right">
            <div className="page-wraper__header">
              <WithPermission permission={POLICY.WRITE_JOB}>
                <Button
                  onClick={() => onOpen()}
                  type="primary"
                  style={{ float: 'right' }}
                >
                  {' '}
                  Thêm mới
                </Button>
              </WithPermission>
            </div>

            <Table
              loading={isLoading}
              className="wh-category-table-striped-rows"
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={PositionJobs}
              onChange={({ current, pageSize }) =>
                onParamChange({ page: current, limit: pageSize })
              }
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `,
                showSizeChanger: true
              }}
              size="small"
            />
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose
        title={id ? 'Cập nhật' : 'Tạo mới' + ' Vị trí tuyển dụng'}
        width={'100%'}
        centered
        visible={open}
        onCancel={onClose}
        footer={null}
      >
        <FormPositionJob onCancel={onClose} id={id} />
      </Modal>
      <Modal
        destroyOnClose
        title={'Nội dung Email'}
        width={800}
        centered
        visible={openEmail}
        onCancel={onCloseEmail}
        footer={null}
      >
        <div dangerouslySetInnerHTML={{ __html: email }} />
      </Modal>
      <Modal
        destroyOnClose
        title={'Bộ câu hỏi'}
        width={'100%'}
        centered
        visible={openQuestion}
        onCancel={onCloseQuestion}
        footer={null}
      >
        <PickQuestion id={id} onCancel={onCloseQuestion} />
      </Modal>
    </div>
  );
};

export default PositionJob;
