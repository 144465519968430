import React, { useCallback, useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";
import api from "~/api";
import { useProfile } from "~/hooks";

function DebounceSelectByCategory({
  debounceTimeout = 500,
  initOptions,
  value,
  isFirstFetch,
  style = {minWidth: '200px'},
  defaultValue = [],
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const [profile] = useProfile();
  const [isApiCalled, setIsApiCalled] = useState(false); // dùng để đánh dấu api đã gọi chưa

  const fetchOptions = useCallback(async (value) => {
    const res = await api.whCategory.getWhCategoryByAuthenByPermission({ keyword: value });
    return res?.map((item) => ({ label: item?.name, value: item?.key }));
  },[]);

  // chỉ call api lần đầu tiên
  const handleFocus = () => {
    if (!isApiCalled) {
      setIsApiCalled(true); // API đã được gọi
      fetchOptions(value).then((newOptions) => {
        setOptions(newOptions);
      });
    }
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      mode="multiple"
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : fetching}
      {...props}
      options={options}
      {...value && {value}}
      showSearch
      style={{...style}}
      onFocus={handleFocus}  // Chỉ gọi API một lần khi nhấn
      defaultValue={defaultValue}
      placeholder='Hãy chọn dịch vụ'
    />
  );
}

export default DebounceSelectByCategory;