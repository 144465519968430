import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Menu, Modal, Select, Switch, Table, Tag, Tooltip, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import Text from 'antd/lib/typography/Text';
import { forIn, get, head } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid';
import { ACTOR_REFERRAL, ACTOR_REFERRAL_VI, CONFIG_CAMPAIGN_STATUS, MAX_PAGINATION_LIMIT, TYPE_OF_MONEY_REWARD_ITEM_VI, TYPE_OF_POINT_REWARD_ITEM_VI, TYPE_REWARD_ITEM_REFERRAL, TYPE_REWARD_ITEM_REFERRAL_VI, USERTYPE_REFERRAL, USERTYPE_REFERRAL_VI } from '~/constants/defaultValue';
import { useMatchPolicy } from '~/hooks';
import { useConfigCampaignPaging, useConfigCampaignQueryParams, useConfigCampaigns, useDeleteConfigCampaign, useMergeConfigCampaignsWithRankings, useResetConfigCampaign, useUpdateConfigCampaign, useUpdateConfigCampaignParams } from '~/hooks/configCampaign';
import { useRankings } from '~/hooks/ranking';
import { useRewardItems } from '~/hooks/rewardItem';
import { formatNumberThreeComma } from '~/hooks/utils';
import { Breadcrumb } from '../Common';
import ModalConfirm from '../Common/ModalConfirm';
import SkeletonTable from '../Utils/SkeletonTable';
import ConfigCampaignForm from './Form/ConfigCampaignForm';
import POLICY from '~/constants/policy';
import ToolTipButtonPermission from '../Utils/ToolTipButtonPermission';
import PermissionToolTip from '../Utils/PermissionToolTip';
const listOptionSearch = [
  {
    value: 'name',
    label: 'Tên chiến dịch'
  },
  {
    value: 'activity',
    label: 'Tên Hoạt động'
  },
  {
    value: 'item',
    label: 'Vật phẩm'
  },
  {
    value: 'point',
    label: 'Điểm thưởng'
  },
];
export default function ConfigCampaign() {
  //PERMISSIONS//
  const canCreate = useMatchPolicy(POLICY.WRITE_REFERRAL);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_REFERRAL);
  const canDelete = useMatchPolicy(POLICY.DELETE_REFERRAL);
  //PERMISSIONS//

  const [form] = Form.useForm()
  const [selectSearch, setSelectSearch] = useState(get(head(listOptionSearch),'value',''));
  const [isOpenModal,setIsOpenModal] = useState(false)
  const [id,setId] = useState(null)
  const [reFetch,setReFetch] = useState(false)
  const triggerFetch = () => setReFetch(!reFetch)
  const handleOpenModal = (id) => {
    setIsOpenModal(true)
    if(id){
      setId(id)
    }
  }
  const handleCloseModal = () => {
    setId(null)
    setIsOpenModal(false)
  }
  // hook

  const [query] = useConfigCampaignQueryParams()
 const [configCampaigns,isLoading] = useConfigCampaigns(query)
 const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
 const [keyword ,{setKeyword, onParamChange }] = useUpdateConfigCampaignParams(query,listOptionSearch)
 const paging = useConfigCampaignPaging(query)
 const queryGetTypeReward = useMemo(() => ({page : 1, limit : MAX_PAGINATION_LIMIT}),[reFetch])
 const queryGetRanking = useMemo(() => ({page : 1, limit : MAX_PAGINATION_LIMIT}),[])
 const [rewardItems,loadingRewardItem] = useRewardItems(queryGetTypeReward)
 const [rankings,loadingRankings] = useRankings(queryGetRanking)
 const mergeListConfigCampaigns = useMergeConfigCampaignsWithRankings(configCampaigns,rankings)
 const [loadingSubmit, updateConfigCampaign] = useUpdateConfigCampaign(handleCloseModal);
 useResetConfigCampaign()
 //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
  };
  const handleChangeStatus = (status,record) => {
    let rankingsUpdate = []
    if(get(record, 'rankings.[0]') !== 'all'){
      get(record, 'rankings',[])?.forEach(e => rankingsUpdate.push(get(e,'_id')))
    }
    else{
      rankingsUpdate.push(get(record, 'rankings.[0]'))
    }
    const submitData = {...record,status : status ? CONFIG_CAMPAIGN_STATUS.ACTIVE : CONFIG_CAMPAIGN_STATUS.INACTIVE,rankings : rankingsUpdate}
    updateConfigCampaign(submitData)
  }

  ///Delete////
  const handleOpenModalDelete = (id) => {
    setId(id)
    setIsOpenModalDelete(true)
  }
  const handleCloseModalDelete = () => {
    setId(null)
    setIsOpenModalDelete(false)
  }
  const [isSubmitLoading, deleteConfigCampaign] = useDeleteConfigCampaign(handleCloseModalDelete);
  const handleDelete = () => {
    deleteConfigCampaign(id);
  }
  ///Delete///


  //FORM////////////////
  const onValuesChange = (value,allValues) => {
    // forIn(allValues,(values,key,obj) =>{obj[key] = null})
    onParamChange({...allValues,...value})
  }
 //FORM////////////////
 //INITVALUE///
 useEffect(() => {
  let value = listOptionSearch?.find(e => query[e.value])
  setSelectSearch(get(value,'value',head(listOptionSearch).value))
  setKeyword(query[get(value,'value','')])
},[])
useEffect(() => {
  const {actor,sortPoint} = query
  form.setFieldsValue({actor:actor || null,sortPoint:parseInt(sortPoint || 0)  || null})
},[query])
 //INITVALUE///
  const columns = [
    {
      title:'Đối tượng',
      dataIndex:'actor',
      key:'actor',
      align:'center',
      render: (item,record,index) => get(ACTOR_REFERRAL_VI,item,'')
    },
    {
      title:'Loại tài khoản áp dụng',
      dataIndex:'rankings',
      key:'rankings',
      align:'center',
      render: (item,record,index) => {
        if(get(record,'rankings[0]','') === 'all') return <Tag color='blue'>Áp dụng mọi đối tượng</Tag>
        return get(record,'rankings',[]).map(rank => <Tag color='red'>{get(rank,'name','unKnow')}</Tag>)
      }
    },
    {
      title:'Tên chiến dịch',
      dataIndex:'name',
      key:'name',
      align:'center',
    },
    {
      title:'Hoạt động',
      dataIndex:'activityId',
      key:'activityId',
      align:'center',
      render: (item,record,index) => get(item,'vi','')
      
    },
    {
      title:'Điều kiện',
      dataIndex:'condition',
      key:'condition',
      width : 300,
      render: (item,record,index) => {
        return <div>
        {/* {get(item,'type','') === CONFIG_CAMPAIGN_CONDITION.WORTH &&<p>Số lượng : <Text strong>{get(item,'count',0)}</Text></p>}
        {get(item,'type','') === CONFIG_CAMPAIGN_CONDITION.RANGE &&<p><Text strong>{get(item,'value.keyValue.min',0)}{" < "}{get(item,'value.keyValue.max',0)}</Text></p>} */}
        <Text strong>{get(item,'description','')}</Text>
        <p>Ngày bắt đầu : <Text strong>{moment(get(item,'startDate',0)).format("YYYY-MM-DD")}</Text></p>
       {get(item,'endDate','') && <p>Ngày kết thúc : <Text strong>{ moment(get(item,'endDate','')).format("YYYY-MM-DD")}</Text></p>}
      </div>
      }
    },
    {
      title:'Loại thưởng',
      dataIndex:'rewardItem',
      key:'rewardItem',
      align:'center',
      render: (item,record,index) => 
      {
        let labelPoint =  TYPE_REWARD_ITEM_REFERRAL_VI[get(item,'types','')]
        if(get(item, 'types', '')=== TYPE_REWARD_ITEM_REFERRAL.point){
          labelPoint =TYPE_OF_POINT_REWARD_ITEM_VI[get(item, 'typesOfPoint', '')] 
        }
        if(get(item, 'types', '')=== TYPE_REWARD_ITEM_REFERRAL.money){
          labelPoint =TYPE_OF_MONEY_REWARD_ITEM_VI[get(item, 'typesOfMoney', '')]
        }
      return labelPoint
      }
    },
    {
      title:'Thưởng',
      dataIndex:'rewardItem',
      key:'rewardItem',
      align:'center',
      render: (item,record,index) => {
        return formatNumberThreeComma(get(item,TYPE_REWARD_ITEM_REFERRAL[get(item,'types','')],''))
      }
    },
   
    {
      title:'Trạng thái',
      dataIndex:'status',
      key:'status',
      align:'center',
      render: (item,record,index) => <Switch loading={loadingSubmit} onChange={(checked) => handleChangeStatus(checked,record)} defaultChecked={item === CONFIG_CAMPAIGN_STATUS.ACTIVE}/>
    },
    {
      title:'Thao tác',
      dataIndex:'action',
      width :100,
      key:'action',
      fixed : 'right',
      render: (item,record,index) =>  <Dropdown
      overlayStyle={{ width: 150 }}
      trigger={['click']}
      placement='bottomCenter'
      overlay={
        <Menu>
          <Menu.Item disabled={!canUpdate} key={v4()} onClick={() => {
            handleOpenModal(get(record,'_id'))
            }}>
          {canUpdate ? <p>Cập nhật</p> : <ToolTipButtonPermission type='text' children={<p>Cập nhật</p>} titleToolTip="Bạn không có quyền cập nhật"/>}
          </Menu.Item>
          <Menu.Item
          disabled={!canDelete}
          onClick={() => handleOpenModalDelete(get(record,'_id'))}
            key={v4()}
            >
           {canDelete ?   <p>Xoá</p> : <ToolTipButtonPermission type='text' children={  <p>Xoá</p>} titleToolTip="Bạn không có quyền xoá"/>}
          
          </Menu.Item>
        </Menu>
      }
    >
      <a className="ant-dropdown-link" href="#">
        Action <CaretDownOutlined />
      </a>
    </Dropdown>
    },
  ]
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Cấu hình chiến dịch" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form
              form={form}
              autoComplete="off"
              onFinish={() => {}}
              scrollToFirstError
              onValuesChange={onValuesChange}
              requiredMark={false}
              labelCol={{ sm: 24, md: 24, lg: 24 }}
              layout='vertical'
              // initialValues={{ managementArea: managementAreaQuery }}
            >
              <Form.Item label='Tìm kiếm'>
                <Search
                  style={{ width: '100%' }}
                  placeholder={`Tìm ${
                    listOptionSearch.find((e) => e.value === selectSearch).label
                  }`}
                  enterButton
                  allowClear
                  onSearch={async (value) => {
                    onParamChange({ [selectSearch]: value?.trim() });
                  }}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              </Form.Item>
              <Form.Item label='Chọn dịch vụ'>
                <Select
                  value={selectSearch}
                  style={{
                    width: '100%'
                  }}
                  onChange={handleChangeSelectSearch}
                  options={listOptionSearch}
                />
              </Form.Item>
              <Form.Item label="Sắp xếp Điểm" name="sortPoint">
                <Select
                  placeholder="Chọn giá trị tăng / giảm"
                  allowClear
                  options={[
                    { label: 'Tăng dần', value: 1 },
                    { label: 'Giảm dần', value: -1 }
                  ]}
                />
              </Form.Item>
              <Form.Item label="Đối tượng" name="actor">
                <Select
                  allowClear
                  options={[
                    {
                      label: ACTOR_REFERRAL_VI.partner,
                      value: ACTOR_REFERRAL.partner
                    },
                    {
                      label: ACTOR_REFERRAL_VI.user,
                      value: ACTOR_REFERRAL.user
                    },
                    { label: 'Tất cả', value: null }
                  ]}
                />
              </Form.Item>
            </Form>
          </div>
          <div className="page-content__right" style={{ width: '80%' }}>
            <div
              className="page-wraper__header"
              style={{ justifyContent: 'space-between', display: 'flex' }}
            >
              <div></div>
              <PermissionToolTip
                permission={POLICY.WRITE_REFERRAL}
                textButton={'Thêm cấu hình chiến dịch'}
                textPermission={'Bạn không có quyền tạo'}
              >
                <Button onClick={() => handleOpenModal()} type="primary">
                  Thêm cấu hình chiến dịch
                </Button>
              </PermissionToolTip>
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={mergeListConfigCampaigns}
                scroll={{ x: 1800 }}
                size="middle"
                onChange={({ current, pageSize }) =>
                  onParamChange({ page: current, limit: pageSize })
                }
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose
        width={1000}
        title={
          <Typography.Title level={5} style={{ textAlign: 'center' }}>
            {id ? 'Cập nhật cấu hình chiến dịch' : 'Tạo cấu hình chiến dịch'}
          </Typography.Title>
        }
        visible={isOpenModal}
        closable={false}
        footer={null}
        onCancel={handleCloseModal}
      >
        <ConfigCampaignForm
          triggerFetch={triggerFetch}
          updateConfigCampaign={updateConfigCampaign}
          loadingRewardItem={loadingRewardItem}
          rewardItems={rewardItems}
          id={id}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
      <ModalConfirm
        text="Xác nhận xoá"
        textOk="Xác nhận"
        type="delete"
        loading={isSubmitLoading}
        visible={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        handleOk={handleDelete}
      />
    </div>
  );
}
