import { Col, Form, Row, Select, Table, Tabs } from 'antd';
import React, { useState } from 'react';
import { Breadcrumb } from '~/components/Common';
import TabBranch from '~/components/Common/TabBranch';
import {
  useUpdateCustomerManagementReferralParams,
  useWhCustomerManagementPaging,
  useWhCustomersManagement,
  useWhCustomersManagementQueryParams,
} from '~/hooks';
import Search from 'antd/lib/input/Search';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Expandable from './Expandable';
const { Option } = Select;

const optionsSearch = [
  { value: 'name', name: 'Tên' },
  { value: 'code', name: 'Mã nhân viên' },
  { value: 'email', name: 'Email' },
  { value: 'employeeGroup', name: 'Nhóm đối tác' }
];

export default function ReferralCustomerManagement() {
  const [searchBy, setSearchBy] = useState('name');
  const [query, onTableChange] = useWhCustomersManagementQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateCustomerManagementReferralParams(query);
  const [whCustomersManagement, isLoading] = useWhCustomersManagement(query);
  const paging = useWhCustomerManagementPaging();
  const [itemActive, setItemActive] = useState([]);

  const columns = [
    {
      title: 'Mã khách hàng',
      dataIndex: 'customerIdFull',
      key: 'customerIdFull'
    },
    {
      title: 'Tên',
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (value, record) => {
        return value?.replace('+84', '0') ?? value;
      }
    }
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách khách hàng / đối tác do khách hàng giới thiệu" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form layout="vertical">
              <Form.Item>
                <Search
                  style={{ width: '100%' }}
                  placeholder="Tên, Loại khách hàng"
                  enterButton
                  allowClear
                  onSearch={() => onParamChange({ keyword })}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    if (!e.target.value) {
                      onParamChange({ keyword: '' });
                    }
                  }}
                  value={keyword}
                />
              </Form.Item>
            </Form>
          </div>
          <div className="page-content__right">
            <div className="page-wraper__header">
              <div className="page-wraper__content">
                {isLoading ? (
                  <SkeletonTable columns={columns} rowCount={3} />
                ) : (
                  <Table
                    dataSource={whCustomersManagement}
                    rowKey={(rc) => rc._id}
                    columns={columns}
                    onChange={onTableChange}
                    size="middle"
                    pagination={{
                      ...paging,
                      showTotal: (total) => `Tổng cộng: ${total}`
                    }}
                    onExpand={(expanded, record) => {
                      expanded
                        ? setItemActive(record._id)
                        : setItemActive(null);
                    }}
                    expandable={{
                      expandedRowRender: (record) => (
                        <Expandable whCustomer={record} />
                      ),
                      expandedRowKeys: [itemActive]
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="page-wraper__header">
          <Row gutter={36}>
            <Col sm={24} md={6}>
                <Select
                  value={searchBy}
                  style={{ width: '100%' }}
                  onChange={(val) => {
                    setSearchBy(val);
                    setKeyword('');
                    onParamChange({
                      name: null,
                      code: null,
                      email: null,
                      employeeGroup: null
                    });
                  }}
                >
                  {optionsSearch.map((value, index) => {
                    return (
                      <Option value={value?.value} key={index}>
                        {value?.name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            <Col sm={24} md={6}>
              <Search
                  allowClear
                  style={{ maxWidth: '500px' }}
                  enterButton
                  placeholder={`Tìm ${optionsSearch
                    .find((item) => {
                      return item?.value === searchBy;
                    })
                    ?.name?.toLowerCase()}`}
                  onSearch={() => {
                    onParamChange({ page: 1, [searchBy]: keyword });
                  }}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    if (!e.target.value) {
                      onParamChange({ [searchBy]: '' });
                    }
                  }}
                  value={keyword}
                  />
                  </Col>
                  </Row>
            </div> */}
      </div>
    </div>
  );
}
