import React, { useEffect, useRef, useState } from 'react';

import { get, set, union } from 'lodash';
import {
  Col,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Tabs,
  Form,
  Button,
  Checkbox
} from 'antd';

import Api from '~/api';
import moment from 'moment';
import { Breadcrumb, FilterDatePicker, WithPermission } from '../Common';
import { Link, useHistory } from 'react-router-dom';
import WhTransactionBill from './WhTransactionBill';
import {
  useTransactions,
  useTransactionsParams,
  useTransactionsPaging,
  useTransactionsQueryParams
} from '~/hooks/transactions';
import { TRANSACTIONS, TRANSFER } from '~/constants/defaultValue';
import ExportExcelButton from '../Common/ExportExcel';
import TabBranch from '../Common/TabBranch';
import SkeletonTable from '../Utils/SkeletonTable';
import POLICIES from '~/constants/policy';
import DebounceSelectByCategory from '../WhCategory/SearchByCategory';
const { Search } = Input;
const Transaction = () => {
  const [searchBy, setSearchBy] = useState('transactionCodes');
  const [arrCheckBox, setArrCheckBox] = useState([])

  const history = useHistory()

  const [query, onPagingChange] = useTransactionsQueryParams(searchBy);
  const [keyword, { setKeyword, onParamChange }] = useTransactionsParams(query);
  const [transactions, loading] = useTransactions(query);
  const paging = useTransactionsPaging();
  const [whBill, setWhBill] = useState([]);
  const [employee, setEmployee] = useState('');
  const [indexItem, setIndexItem] = useState(0);
  const [amountOfMoney, setAmountOfMoney] = useState(0);
  const [payer, setPayer] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [whCategory, setWhCategory] = useState(null);

  // -----------Props of Table---------------

  useEffect(() => {
    if (!whCategory && query?.whCategory) {
      // const splitWhCategory = query?.whCategory.split(',');
      setWhCategory(query?.whCategory);
    };
  }, [query]);
  
  // Get list Ids
  const onChangeCheckBox = (e, id) => {
    if (e) {
      setArrCheckBox([...arrCheckBox, id])
    } else {
      const getIndex = arrCheckBox.findIndex((itemId, index) => itemId === id)
      const newArr = arrCheckBox.toSpliced(getIndex, 1)
      setArrCheckBox(newArr)
    }
  }

  // Update id value when query changes
  const columns = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transactionCodes',
      key: 'transactionCodes',
      render: (value, record, index) => {
        setIndexItem(index);
        return (
          <Button
            type="link"
            onClick={() => {
              setAmountOfMoney(record?.amountOfMoney)
              if (record?.typeSystem === 'PAY_OUT') {
                setWhBill(record?.whPaymentVoucher, 'whPaymentVoucher');
                setPayer(
                  record?.customerAccount?.fullName ??
                  record?.whPartner?.name
                );
              } else {
                setWhBill(record?.whReceiptVoucher);
                !record?.note
                  ? setPayer(record?.whPartner?.name)
                  : setPayer(record?.customerAccount?.fullName);
              }
              setIsOpen(true);
            }}
          >
            {value}
          </Button>
        );
      }
    },
    {
      title: 'Lựa chọn',
      key: 'checkbox',
      width: 80,
      align: 'center',
      render: (item, record) =>
      {
        const id = record._id
        return <Checkbox
        defaultChecked = {arrCheckBox.includes(id)}
        onChange={(e)=>onChangeCheckBox(e.target.checked, id)}
      />}
    },
    {
      title: 'Thời gian',
      key: 'date',
      dataIndex: 'date',
      render: (value) => moment(value).format('HH:mm DD/MM/YYYY')
    },
    {
      title: 'Số tiền',
      dataIndex: 'amountOfMoney',
      key: 'amountOfMoney',
      render: (value) => `${value?.toLocaleString()}`
    },
    {
      title: 'Loại thu chi',
      dataIndex: 'typeSystem',
      key: 'typeSystem',
      render: (value) => TRANSFER[value]
    },
    {
      title: 'Mã phiếu thu chi',
      dataIndex: 'reciptAndPaymentCode',
      key: 'reciptAndPaymentCode',
      render: (value, record) =>
        record?.whPaymentVoucher?.issueNumber ??
        record?.whReceiptVoucher?.issueNumber
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      key: 'code',
      render: (value, record) =>
        record?.tag?.codeBill
    },
    {
      title: 'Người thu/ Người chi',
      dataIndex: 'User',
      key: 'User',
      render: (value, record) => {
        setEmployee(record?.employee.fullName);
        return record?.employee.fullName;
      }
    },
    {
      title: 'Người nộp/ Người nhận',
      dataIndex: 'partnerId',
      key: 'partnerId',
      render: (value, record) => {
        let staff = '';
        // switch (record.typeSystem) {
        //   case 'PAY_OUT':
        //     staff = record?.customerAccount?.fullName ?? record?.whPartner?.name;
        //     break;
        //   case 'PAY_IN':
        //     if (record?.note) { staff = record?.customerAccount?.fullName; }
        //     else { staff = record?.whPartner?.name }
        //     if (query.whPartnerName && !record?.note) {
        //       staff += `(KH: ${record?.customerAccount?.fullName})`
        //     }
        //     else if(query.whPartnerName){
        //       staff += record?.whPartner?.name ? `(KH: ${record?.customerAccount?.fullName})` : ""
        //     }
        //     break;
        //   default:
        //     break
        // }

        if ((record?.typeSystem === 'PAY_OUT' && record?.typePartner === '') || (record?.typeSystem === "PAY_IN" && record?.typePartner === null) ) {
          staff =
            record?.customerAccount?.fullName ?? record?.whPartner?.name;
        } else {
         staff = !record?.note
            ? (staff = record?.whPartner?.name)
            : (staff = record?.customerAccount?.fullName);
        }
        return staff;
      }
    },

    //!query.whPartnerName
    // ? `${record?.whPartner?.name} (KH:${record?.customerAccount?.fullName})`
    // : `${record?.customerAccount?.fullName}(ĐT${record?.whPartner?.name})`
    {
      title: 'Thông tin',
      dataIndex: 'tag',
      key: 'tag',
      width: 450,
      render: (value, record) =>
        record?.whPaymentVoucher?.reason ??
        record?.whReceiptVoucher?.reason
    },
    {
      title: 'Trạng thái  ',
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <span className={`partner__transaction-status ${value.toLowerCase()}`}>
            {TRANSACTIONS[value]}
        </span>
      )
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      width: 200,
    }
  ];

  //props button export file excel
  const searchParams = get(history, 'location.search')

  const propsButton = {
    url: "/transaction",
    keyExport: "Transaction",
    fileName_: 'Giao dịch',
    searchBy: searchParams ? searchParams : `page=${paging?.current}&limit=${paging?.pageSize}` ,
    ids: arrCheckBox,
  }
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách giao dịch" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form.Item label='Tìm kiếm'> 
                {
                  {
                    transactionCodes: (
                      <Search
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Tìm theo mã giao dịch"
                        enterButton
                        onSearch={() =>
                          onParamChange({ [searchBy]: keyword, page: 1 })
                        }
                        onChange={(e) => {
                          setKeyword(e.target.value);
                          if (e.target.value === '') {
                            onParamChange({ [searchBy]: null, page: 1 });
                          }
                        }}
                        value={keyword}
                      />
                    ),
                    date: (
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: '10px',
                          marginRight: '10px'
                        }}
                      >
                        <FilterDatePicker
                          label=""
                          value={query.startTime}
                          onChange={(value) =>
                            onParamChange({ startTime: value, page: 1 })
                          }
                        />
                        <FilterDatePicker
                          label=""
                          value={query.endTime}
                          onChange={(value) =>
                            onParamChange({ endTime: value, page: 1 })
                          }
                        />
                      </div>
                    ),
                    typeSystem: (
                      <Select
                        placeholder="Chọn loại thu/chi"
                        style={{ width: '100%' }}
                        onChange={(val) =>
                          onParamChange({ typeSystem: val, page: 1 })
                        }
                      >
                        <Option value="PAY_IN">Thu</Option>
                        <Option value="PAY_OUT">Chi</Option>
                      </Select>
                    ),
                    amountOfMoney: (
                      <Search
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Tìm theo số tiền"
                        enterButton
                        onSearch={() =>
                          onParamChange({ [searchBy]: keyword, page: 1 })
                        }
                        onChange={(e) => {
                          setKeyword(e.target.value);
                          if (e.target.value === '') {
                            onParamChange({ [searchBy]: null, page: 1 });
                          }
                        }}
                        value={keyword}
                      />
                    )
                    // Handle other filter types like whReceiptVoucherCode, code, etc...
                  }[searchBy]
                }
            </Form.Item>
            <Form.Item vertical>
              <Select
                value={searchBy}
                style={{ width: '100%' }}
                onChange={(val) => {
                  onParamChange({
                    date: null,
                    typeSystem: null,
                    amountOfMoney: null,
                    whReceiptVoucherCode: null,
                    whPaymentVoucherCode: null,
                    customerAccount: null,
                    whPartnerName: null,
                    startTime: null,
                    endTime: null,
                    whCustomerName: null,
                    transactionCodes: null,
                    code: null,
                    page: 1,
                    whCategory: null
                  });
                  setSearchBy(val);
                }}
              >
                <Option value="transactionCodes">Mã giao dịch</Option>
                <Option value="date">Thời gian</Option>
                <Option value="whCategory">Nhóm dịch vụ</Option>
                <Option value="typeSystem">Loại thu chi</Option>
                <Option value="amountOfMoney">Số tiền</Option>
                <Option value="whReceiptVoucherCode">Mã phiếu thu</Option>
                <Option value="whPaymentVoucherCode">Mã phiếu chi</Option>
                <Option value="code">Mã đơn hàng</Option>
                <Option value="customerAccount">Người thu/chi</Option>
                <Option value="whCustomerName">
                  Người nộp/nhận là khách hàng
                </Option>
                <Option value="whPartnerName">Người nộp/nhận là đối tác</Option>
              </Select>
            </Form.Item>
          </div>

          <div className="page-content__right">
            <div className="page-wraper__header">
              <Row gutter={48}>
                <Col align="end" span={24}>
                  <WithPermission permission={POLICIES.DOWNLOAD_TRANSACTIONS}>
                    <ExportExcelButton propsButton={propsButton} />
                  </WithPermission>
                </Col>
              </Row>
            </div>

            {loading ? (
              <SkeletonTable columns={columns} rowCount={5} />
            ) : (
              <Table
                dataSource={transactions ? transactions : []}
                columns={columns}
                onSearch={() => onParamChange({ [searchBy]: keyword })}
                onChange={({ current, pageSize }) =>
                  onPagingChange({ current, pageSize })
                }
                size="middle"
                scroll={{ x: 1500 }}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        visible={isOpen}
        width={1366}
        footer={null}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
      >
        <WhTransactionBill
          onClose={() => {
            setIsOpen(false);
            setWhBill(null);
          }}
          onCancel={() => {
            setIsOpen(false);
            setWhBill(null);
          }}
          payer={payer}
          whBill={whBill}
          amountOfMoney={amountOfMoney}
          employee={employee}
          nameAccount={transactions[indexItem]?.User}
        />
      </Modal>
    </div>
  );
};

export default Transaction;

