import {
  Col,
  Row,
  Input,
  Table,
  Button,
  Modal,
  Form,
  Menu,
  Select,
  Popconfirm
} from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import POLICY from '~/constants/policy';
import { Breadcrumb, WithPermission } from '~/components/Common';
import TabBranch from '~/components/Common/TabBranch';
import {
  useWarehouseLists,
  useDeleteWareHouse,
  useWareHouseListQueryParams,
  useUpdateListWareHouseParams,
  useWareHousetListPaging
} from '~/hooks/listWareHouse';

import { DeleteFilled, EditFilled } from '@ant-design/icons';
import {
  STATUS_WAREHOUSE,
  WAREHOUSE_STATUS,
  ACTIVE_WAREHOUSE
} from '~/constants/defaultValue';
import { v4 } from 'uuid';
import ListWareHouseForm from './ListWareHouseForm';
import { useMatchPolicy, useProfile, useUser } from '~/hooks';
import { get, head, transform } from 'lodash';
import { PATH_APP } from '~/routes/paths';
import ListProductStatusForm from './ListProductStatusForm';
import { Link } from 'react-router-dom';
import api from '~/api';

// import { Form } from 'reactstrap'

export default function ListWareHouse() {
  const listoptenSearch = [
    { value: 'code', label: 'Mã kho' },
    { value: 'name', label: 'Tên kho' },
    { value: 'status', label: 'Trạng thái hoạt động' }
  ];
  const headValue = get(head(listoptenSearch), 'value');

  const [form] = Form.useForm();
  const [searchBy, setSearchBy] = useState(headValue);
  // const [isLoading,setLoading] = useState(false)
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenFormDelete, setIsOpenFormDelete] = useState(false);
  const [id, setId] = useState(null);

    /**
     *
     */
    const [profile] = useProfile();
    const [branchIdSearch, setBranchIdSearch] = useState('all');
    const [branchIdUser] = useUser();
    const idBranch = useMemo(() => {
      let branchId = undefined;
      if (branchIdUser === '99999') {
        if (branchIdSearch === 'all') {
          branchId = undefined;
        } else {
          branchId = branchIdSearch;
        }
      } else {
        branchId = branchIdUser;
      }
      return { branchId };
    }, [branchIdSearch, branchIdUser]);//
  
  const [query] = useWareHouseListQueryParams(idBranch);
  const [keyword, { setKeyword, onParamChange }] = useUpdateListWareHouseParams(query);
  const [isLoadingDelete, deleteWareHouse] = useDeleteWareHouse();
  const [warehouseList, isLoading] = useWarehouseLists(query);
  const [status, setStatus] = useState('');
  const [isOpenFormStatus, setIsOpenFormStatus] = useState(false);
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const canReadWarehouse = useMatchPolicy(POLICY.READ_WAREHOUSE);

  const paging = useWareHousetListPaging();

  const getBranches = async () => {
    setIsLoadingBranches(true);
    const res = await api.deliveryVoucher.getAllBranch();
    const mapRes = res.map((item) => {
      return {
        label: item.name,
        value: item._id
      };
    });
    const optionAll = {
      label: 'Tất cả chi nhánh',
      value: 'all'
    };
    setOptionsBranch([optionAll, ...mapRes]);
    setIsLoadingBranches(false);
  };

  useEffect(() => {
    getBranches();
  }, []);

  const handleOpenUpdate = (id) => {
    setIsOpenForm(true);
    if (id) {
      setId(id);
    }
  };
  const handleOpenFormCreate = () => {
    setIsOpenForm(true);
    setId(null);
  };

  const handleOk = () => {
    setIsOpenForm(false);
  };

  const handleCloseForm = () => {
    setIsOpenForm(false);
    // form.resetFields();
  };

 
  const handleDelete = (id) => {
    deleteWareHouse({ id });
    setIsOpenFormDelete(false);
  };
 
  const handleCloseFormStatus = () => {
    setIsOpenFormStatus(false);
    setId(null);
    setStatus('');
  };
  
  const { Search } = Input;
  const handleChangeOptionSearch = (e) => {
    setSearchBy(e);
    setKeyword(null);
    onParamChange({
      status: null,
      keyword: null,
      code: null,
      page: 1,
    })
  };
  const ColumnActions = ({
    id,
    action,
    isSubmitLoading,
    handleOpenUpdate,
    isAction,
    ...restProps
  }) => {
    const { status, ...rest } = restProps;
    return (
      <div className="custom-table__actions">
        <WithPermission permission={POLICY.UPDATE_WAREHOUSE}>
          <Button
            type="link"
            onClick={() => {
              handleOpenUpdate(id);
            }}
          >
            <EditFilled />
          </Button>
        </WithPermission>
        {
          status === 'INACTIVE' && (
            <>
              <WithPermission>
              <WithPermission permission={POLICY.DELETE_WAREHOUSE}>
                <p>|</p>
              </WithPermission>
            </WithPermission>
            <WithPermission permission={POLICY.DELETE_WAREHOUSE}>
              
              <Popconfirm
                title="Bạn có chắc chắn muốn xoá kho này ?"
                onConfirm={()=>handleDelete(id)}
                okText="Xác nhận"
                cancelText="Huỷ"
              >
                <DeleteFilled style={{color:'#DC3535'}}/>
              </Popconfirm>
            
            </WithPermission>
            </>
            )
        }
      </div>
    );
  };

  const columns = [
    {
      title: 'Mã nhóm kho',
      align: 'start',
      dataIndex: 'code',
      key: 'code',
      render: (value, record) => {
        return (
          <Link target='_blank' to={`/list-warehouse/products/${record._id}`}>
            <Button
              type="link"
            >
              {value}
            </Button>
          </Link>
        );
      }
    },
    {
      title: 'Tên kho',
      align: 'start',
      dataIndex: 'branch',
      key: 'branch',
      render:(value)=> (value?.name?.vi || value?.name)
    },
    {
      title: 'Tên kho',
      align: 'start',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Địa chỉ kho',
      align: 'start',
      dataIndex: 'address',
      key: 'address',
      render: (value, record) => {
        return (
          <span>
            {`${value?.street ? `${value?.street},` : ''} ${value?.ward}, ${
              value?.district
            }, ${value?.city}`}
          </span>
        );
      }
    },
    branchIdSearch === 'all' ?{
      title: 'Chi nhánh',
      align: 'center',
      dataIndex: 'branch',
      key: 'branch',
      render: (value, record) =>  value?.name?.vi ?? value?.name
    } : [],
    {
      title: 'Mô tả',
      key: 'description',
      render: (record) => {
        return record.description;
      }
    },
    {
      title: 'Trạng thái sử dụng',
      align: 'start',
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => {
        return (
          <Button type="link" onClick={() => {}}>
            {value === 'ACTIVE' ? (
              <span style={{ color: '#52c41a' }}>{'Đang sử dụng'}</span>
            ) : (
              <span style={{ color: '#f5222d' }}>{'Ngưng sử dụng'}</span>
            )}
          </Button>
        );
      }
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (value, record) => {
        const isAction =
          record.processing > 0 || record.assigned > 0 ? true : false;
        return (
          <ColumnActions
            {...record}
            id={record._id}
            handleOpenUpdate={handleOpenUpdate}
            isAction={isAction}
          />
        );
      }
    }
  ];

  const onSearch = (value) => console.log(value);
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách kho" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form layout="vertical">
            <Form.Item label="Tìm kiếm">
                {searchBy === 'status' ? (
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Vui lòng chọn trạng thái"
                    optionLabelProp="label"
                    allowClear
                    onChange={(e) => onParamChange({ [searchBy]: e })}
                  >
                    {transform(
                      STATUS_WAREHOUSE,
                      (result, value, key) => {
                        result.push(value);
                      },
                      []
                    ).map((e) => (
                      <Option key={e.value} value={e.value} label={e.name}>
                        <p style={{ color: e.colorStyle }}>{e.name}</p>
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Search
                    value={keyword}
                    placeholder={`Nhập ${
                      listoptenSearch.find((item) => item.value === searchBy)
                        ?.label
                    } để tìm...`}
                    enterButton
                    allowClear
                    onSearch={(value) =>
                      onParamChange({ [searchBy]: value?.trim() })
                    }
                    style={{ maxWidth: '500px' }}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                      if (!e.target.value) {
                        onParamChange({ [searchBy]: '' });
                      }
                    }}
                  />
                )}
            </Form.Item>

            {profile?.branchId === 99999 &&
              branchIdUser === '99999' &&
              canReadWarehouse && (
                <Form.Item label='Chọn chi nhánh' vertical>
                  <Select
                    value={branchIdSearch}
                    style={{ width: '100%' }}
                    options={optionsBranch}
                    onChange={(e) => setBranchIdSearch(e)}
                    allowClear
                  />
                </Form.Item>
              )}

            <Form.Item vertical label='Chọn dịch vụ'>
              <Select
                style={{ width: '100%' }}
                value={searchBy}
                options={listoptenSearch}
                onChange={handleChangeOptionSearch}
                onSearch={onSearch}
                allowClear
              />
            </Form.Item>
            </Form>
          </div>

          <div className="page-content__right">
            <div className="page-wraper__header">
              <Row gutter={48}>
                <Col span={24}>
                  <Row justify="end" align="middle">
                    <WithPermission permission={POLICY.WRITE_WAREHOUSE}>
                      <Button
                        type="primary"
                        onClick={() => handleOpenFormCreate()}
                      >
                        Thêm mới
                      </Button>
                    </WithPermission>
                  </Row>
                </Col>
              </Row>
            </div>

            {isLoading ? (
              <SkeletonTable columns={columns} pagination={paging} />
            ) : (
              <Table
                scroll={{ x: 1000 }}
                columns={columns}
                dataSource={warehouseList}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                onChange={({ current }) => onParamChange({ page: current })}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        onOk={handleOk}
        visible={isOpenForm}
        closable={false}
        onCancel={handleCloseForm}
        width={1000}
        title={`${id ? 'Cập nhật' : 'Thêm mới'} sản phẩm`}
        footer={null}
      >
        <ListWareHouseForm
          id={id}
          setIsOpenForm={setIsOpenForm}
          isOpenForm={isOpenForm}
          handleCloseForm={handleCloseForm}
        />
      </Modal>

      <Modal
        visible={isOpenFormStatus}
        closable={false}
        footer={null}
        onCancel={handleCloseFormStatus}
        width={700}
        title="Danh sách dịch vụ sử dụng thiết bị"
      >
        <ListProductStatusForm
          id={id}
          isOpen={isOpenFormStatus}
          handleCloseFormStatus={handleCloseFormStatus}
          status={status}
          setIsOpenFormStatus={setIsOpenFormStatus}
        />
      </Modal>
    </div>
  );
}
