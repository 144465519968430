import {
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Form, Menu, Modal, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import Text from 'antd/lib/typography/Text';
import { forIn, get, head, isNil, keys } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import {
  MAX_VALUE_CONFIG_CAMPAIGN,
  REQUEST_VOUCHER_REFCOLLECTION,
  REQUEST_VOUCHER_STATUS,
  REQUEST_VOUCHER_STATUS_VI
} from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchPolicy, useResetWhPaymentVoucher } from '~/hooks';
import {
  useResetRequestVoucher,
  useRequestVoucherPaging,
  useRequestVoucherQueryParams,
  useRequestVouchers,
  useUpdateRequestVoucherParams,
  useDeleteRequestVoucher,
  useUpdateRequestVoucher
} from '~/hooks/requestVoucher';
import { floorFormatter } from '~/utils/helper';
import { Breadcrumb } from '../Common';
import ModalConfirm from '../Common/ModalConfirm';
import SkeletonTable from '../Utils/SkeletonTable';
import ModalDeny from './ModalDeny';
import WhPaymentVoucherWithdraw from './WhPaymentVoucherWithdraw';
// import RequestVoucherForm from './Form/RequestVoucherForm';
import ToolTipButtonPermission from '../Utils/ToolTipButtonPermission';
import TabBranch from '../Common/TabBranch';

const listOptionSearch = [
  {
    value: 'name',
    label: 'Tên KH / đối tác'
  },
  {
    value: 'requestNumber',
    label: 'Mã yêu cầu'
  }
];
export default function RequestVoucher() {
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_WHREQUESTVOUCHER)
  const canDelete = useMatchPolicy(POLICIES.DELETE_WHREQUESTVOUCHER)
  const [form] = Form.useForm();
  const [isOpenPaymentVouchersModal, setIsOpenPaymentVouchersModal] =
    useState(false);
  const [idSelect, setIdSelect] = useState(null);
  const [selectSearch, setSelectSearch] = useState(
    head(listOptionSearch).value
  );
  const [isOpenModalDeny, setIsOpenModalDeny] = useState(false);
  // const [isOpenModalDenyDelete, setIsOpenModalDenyDelete] = useState(false);
  const onClosePaymentVouchersModal = () => {
    setIsOpenPaymentVouchersModal(false);
    setIdSelect(null);
  };
  const onOpenPaymentVouchersModal = (id) => {
    setIdSelect(id);
    setIsOpenPaymentVouchersModal(true);
  };
  const [id, setId] = useState(null);
  const [idDeny, setIdDeny] = useState(null);
  const handleOpenModalDeny = (id) => {
    setIsOpenModalDeny(true);
    if (id) {
      setIdDeny(id);
    }
  };
  const handleCloseModalDeny = () => {
    setIsOpenModalDeny(false);
    setIdDeny(null);
  };
  const callbackAfterUpdate = () => {
    handleCloseModalDeny()
    onClosePaymentVouchersModal()
  }
  const [loadingUpdate, updateRequestVoucher] = useUpdateRequestVoucher(callbackAfterUpdate)
  const handleDeny = (reason) => {
    const submitData = {_id : idDeny , status : REQUEST_VOUCHER_STATUS.CANCELLED,...reason}
    updateRequestVoucher(submitData)
  }
  // hook

  const [query] = useRequestVoucherQueryParams();
  const [requestVouchers, isLoading] = useRequestVouchers(query);
  const paging = useRequestVoucherPaging(query);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateRequestVoucherParams(query);
  useResetRequestVoucher();
  useResetWhPaymentVoucher();
 
  //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('');
  };
  ////////////////////////////////
  // useEffect(() => {
  //   const {sortPercentDiscount,sortPoint} = query
  //   form.setFieldsValue({sortPercentDiscount:parseInt(sortPercentDiscount) || null,sortPoint:parseInt(sortPoint || 0)  || null})
  // },[query])
  // useEffect(() => {
  //   let value = listOptionSearch?.find(e => query[e.value])
  //   setSelectSearch(get(value,'value',head(listOptionSearch).value))
  //   setKeyword(query[get(value,'value','')])
  // },[])
  //payment//
  // const handleOpenModalDelete = (id) => {
  //   setId(id);
  //   setIsOpenModalDelete(true);
  // };
  // const handleCloseModalDelete = () => {
  //   setId(null);
  //   setIsOpenModalDelete(false);
  // };
  const handleDelete = () => {
    deleteRank(id);
  };
  // const [isSubmitLoading, deleteRank] = useDeleteRequestVoucher(handleCloseModalDelete);
  ////////////////////////////////
  //   const options = useOptionsSelectAntd(TYPE_RANKING_REFERRAL_VI)
  const onValuesChange = (value, allValues) => {
    const {startDate,endDate} = allValues;
    // forIn(allValues, (values, key, obj) => { //reset params
    //   obj[key] = null;
    // });
    if(startDate) {
      value.startDate = moment(startDate).format("YYYY-MM-DD")
    }
    if(endDate) {
      value.endDate = moment(endDate).format("YYYY-MM-DD")
    }
    onParamChange({ ...allValues, ...value });
  };

  const columns = [
    {
      title: 'Tên khách hàng, đối tác',
      dataIndex: 'refCollection',
      key: 'refCollection',
      render: (item, record, index) => {
        if (item === REQUEST_VOUCHER_REFCOLLECTION.customer_account)
          return get(record, 'customerAccount[0].fullName', '');
        if (item === REQUEST_VOUCHER_REFCOLLECTION.wh_partner)
          return get(record, 'whPartner[0].name', '');
        return '';
      }
    },
    {
      title: 'Mã yêu cầu',
      dataIndex: 'requestNumber',
      key: 'requestNumber'
      // render: (item, record, index) => floorFormatter(item || 0)
    },
    {
      title: 'Số tiền',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (item, record, index) => <Text strong>{`${floorFormatter(item || 0)} đ`}</Text>
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (item, record, index) =>
        moment(item).format('DD-MM-YYYY HH:mm:ss')
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (item, record, index) => (
        <span className={`bill-status ${item?.toLowerCase()}`}>
          {REQUEST_VOUCHER_STATUS_VI[item]}
        </span>
      )
    },
    {
      title: 'Lý do từ chối',
      dataIndex: 'reasonDeny',
      key: 'reasonDeny',
      align: 'center',
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      width: '100px',
      key: 'action',
      render: (item, record, index) => (
        <Dropdown
          overlayStyle={{ width: 150 }}
          trigger={['click']}
          placement="bottomCenter"
          overlay={
            <Menu>
              <Menu.Item
                disabled={get(record, 'status') !== REQUEST_VOUCHER_STATUS.NEW || !canUpdate}
                icon={<CheckOutlined />}
                key={v4()}
                onClick={() =>
                  onOpenPaymentVouchersModal(get(record, '_id', ''))
                }
              >
              {canUpdate ? <p>Duyệt</p> : <ToolTipButtonPermission type='text' children={<p>Duyệt</p>} titleToolTip="Bạn không có quyền duyệt"/>}
              </Menu.Item>
              <Menu.Item
                disabled={get(record, 'status') !== REQUEST_VOUCHER_STATUS.NEW || !canUpdate}
                icon={<CloseOutlined />}
                key={v4()}
                onClick={() => handleOpenModalDeny(get(record, '_id', ''))}
              >
              {canUpdate ? <p>Từ chối</p> : <ToolTipButtonPermission type='text' children={<p>Từ chối</p>} titleToolTip="Bạn không có quyền từ chối"/>}
              </Menu.Item>
              {/* <Menu.Item
                icon={<DeleteOutlined />}
                disabled={get(record, 'status') === REQUEST_VOUCHER_STATUS.NEW}
                key={v4()}
                // onClick={() => handleOpenModalDelete(get(record, '_id', ''))}
              >
                <p>Xoá (NOT yet implement)</p>
              </Menu.Item> */}
            </Menu>
          }
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
      )
    }
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <TabBranch useBoxShadow={false}>
        <div className="container-fluid">
          <Breadcrumb title="Yêu cầu rút tiền" />
          <div className="page-content__main">
            <div className="page-content__left">
              <Form
                form={form}
                autoComplete="off"
                onFinish={() => {}}
                scrollToFirstError
                onValuesChange={onValuesChange}
                requiredMark={false}
                labelCol={{ sm: 24, md: 24, lg: 24 }}
                layout="vertical"
              >
                <Form.Item label="Tìm kiếm">
                  <Search
                    style={{ width: '100%' }}
                    placeholder={`Tìm ${get(
                      listOptionSearch.find((e) => e.value === selectSearch),
                      'label',
                      ''
                    )}`}
                    enterButton
                    allowClear
                    onSearch={(value) => {
                      onParamChange({ [selectSearch]: value });
                    }}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                </Form.Item>

                <Form.Item>
                  <Select
                    value={selectSearch}
                    style={{
                      width: '100%'
                    }}
                    onChange={handleChangeSelectSearch}
                    options={listOptionSearch}
                  />
                </Form.Item>

                <Form.Item
                  label={'Trạng thái'}
                  name="status"
                  labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
                >
                  <Select
                    allowClear
                    autoComplete="off"
                    placeholder="Hãy chọn trạng thái"
                  >
                    {keys(REQUEST_VOUCHER_STATUS_VI).map((status) => (
                      <Select.Option key={status} value={status}>
                        {REQUEST_VOUCHER_STATUS_VI[status]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={'Từ'}
                  name="startDate"
                  labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
                >
                  <DatePicker
                    placeholder="Ngày bắt đầu"
                    format={'YYYY-MM-DD'}
                  />
                </Form.Item>
                <Form.Item
                  label={'Đến'}
                  name="endDate"
                  labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
                >
                  <DatePicker placeholder="Ngày kết thúc" />
                </Form.Item>
              </Form>
            </div>
            <div className="page-content__right" style={{ width: '80%' }}>
              {/* <div
                className="page-wraper__header"
                style={{ justifyContent: 'space-between', display: 'flex' }}
              >
                <div></div>
                <Button onClick={() => handleOpenModal()} type="primary">
                Thêm xếp loại
              </Button>
              </div> */}

              {isLoading ? (
                <SkeletonTable columns={columns} rowCount={10} />
              ) : (
                <Table
                  rowKey={(rc) => rc._id}
                  columns={columns}
                  dataSource={requestVouchers}
                  size="middle"
                  onChange={({ current }) => onParamChange({ page: current })}
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </TabBranch>
      <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={onClosePaymentVouchersModal}
        onOk={() => {}}
        title={`Tạo phiếu chi rút tiền`}
        width={1500}
        visible={isOpenPaymentVouchersModal}
      >
        <WhPaymentVoucherWithdraw
          onCancel={onClosePaymentVouchersModal}
          id={idSelect}
          updateRequestVoucher={updateRequestVoucher}
          loadingUpdate={loadingUpdate}
        />
      </Modal>
      <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={handleCloseModalDeny}
        onOk={() => {}}
        title={`Từ chối rút tiền`}
        visible={isOpenModalDeny}
      >
        <ModalDeny
          isLoading={loadingUpdate}
          handleDeny={handleDeny}
          onClose={handleCloseModalDeny}
        />
      </Modal>
    </div>
  );
}
