import { Col, Row, Input, Table, Button, Modal, Form, Menu, Select, Tooltip } from 'antd'
import React, { useEffect, useState,useMemo,} from 'react'
import SkeletonTable from '~/components/Utils/SkeletonTable'
import POLICY from '~/constants/policy'
import { Breadcrumb, WithOrPermission, WithPermission } from '~/components/Common'
import ConfirmCouponProForm from './ConfirmCouponProForm'
import { STATUS_WAREHOUSE, WAREHOUSE_STATUS,ACTIVE_WAREHOUSE, ACTIVE_KEY } from '~/constants/defaultValue';
import { v4 } from 'uuid';
import { useMatchPolicy } from '~/hooks'
import { get, head,transform } from 'lodash'
import { useProductListPaging, useProductListQueryParams, useProductLists, useResetProductList, useUpdateProductListParams } from '~/hooks/productList'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import { useGetWareHouseById } from '~/hooks/listWareHouse'
import ProductListForm from './ProductListForm'
const { Search } = Input

const optionsSearch = [
  { value: 'code', name: 'Mã định danh sản phẩm' },
  { value: 'name', name: 'Tên sản phẩm' },
  { value: 'groupProductName', name: 'Nhóm sản phẩm' },
  { value: 'manufacturer', name: 'Hãng sản xuất' },
  { value: 'dateManufacturer', name: 'Ngày sản xuất' },
  // { value: 'createdAt', name: 'Thời gian đăng kí thiết bị' },
  { value: 'valueDepreciation', name: 'Giá trị khấu hao' },
  { value: 'countUsing', name: 'Số lần sử dụng' },
  { value: 'status', name: 'Trạng thái hoạt động' }
];

export default function ProductListByWarehouse({name}) {
  const [form] = Form.useForm();
  const { id: idWarehouse } = useParams();
  useResetProductList();
  const [wareHouseId, setWareHouseId] = useState(null);
  const [searchBy, setSearchBy] = useState('code');
  const [wareHouseInfo, isLoadingInfo] = useGetWareHouseById(idWarehouse); // Get list warehouse info of branch
  const [query] = useProductListQueryParams(searchBy, idWarehouse);
  const [keyword, { setKeyword, onParamChange }] = useUpdateProductListParams(query, idWarehouse)
  const [products, isLoading] = useProductLists(query);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFormDelete, setIsOpenFormDelete] = useState(false);
  const [isStatusProduct, setIsStatusProduct] = useState('');
  const [id, setId] = useState(null);

  const paging = useProductListPaging();
  
  const handleOpenFormDeliveryVoucher = () => {
    setIsOpenForm(true);
  };
  const handleCloseFormDeliveryVoucher = () => {
    setIsOpenForm(false);
  };
  const handleOpenForm = (id) => {
    setIsOpen(true);
    setId(id);
  };
  const handleCloseForm = (id) => {
    setIsOpen(false);
    setId(id);
  };

  const columns = [

    {
      title: 'Mã định danh sản phẩm',
      align: 'start',
      dataIndex: 'code',
      width: '200px',
      key: 'code',
      render: (value, record) => {
        // return ( value)
        return (
          <Tooltip
            placement="top"
            title={
              record?.status === 'READY' || record?.status === 'UNREADY'
                ? ''
                : `Thiết bị ở trạng thái ${WAREHOUSE_STATUS[
                    record?.status
                  ]?.name?.toLowerCase()} nên không thể cập nhật`
            }
          >
            <Button
              type="link"
              onClick={() => {
                handleOpenForm(record?._id), setIsStatusProduct(record?.status);
                // setStatusProduct(record?.status);
              }}
            >
              {value}
            </Button>
          </Tooltip>
        );
      }
    },
    {
      title: 'Tên sản phẩm',
      align: 'start',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Nhóm sản phẩm',
      align: 'start',
      dataIndex: 'groupProductId',
      key: 'groupProductId',
      render: (value, record) => record?.groupProduct?.name?.vi || ''
    },
    {
      title: 'Hãng sản xuất',
      align: 'start',
      dataIndex: 'manufacturer',
      key: 'manufacturer'
    },
    {
      title: 'Đơn vị cung cấp',
      align: 'start',
      dataIndex: 'supplierId',
      key: 'supplierId',
      render: (value, record) => record?.supplierInfo?.name ?? 'WorldHealth'
    },
    {
      title: 'Ngày sản xuất',
      align: 'start',
      dataIndex: 'dateManufacturer',
      key: 'dateManufacturer',
      render: (value) => moment(value).format('YYYY-MM-DD')
    },
    {
      title: 'Ngày đăng kí thiết bị',
      align: 'start',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '180px',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm')
    },
    {
      title: 'Số lần khấu hao',
      align: 'start',
      dataIndex: 'countUsing',
      key: 'countUsing'
    },
    {
      title: 'Giá trị khấu hao',
      align: 'start',
      dataIndex: 'valueDepreciation',
      key: 'valueDepreciation',
      render: (value) => `${value?.toLocaleString() ?? ''}`
    },
    {
      title: 'Đơn vị khấu hao',
      align: 'start',
      dataIndex: 'unitDepreciation',
      key: 'unitDepreciation'
    },
    {
      title: 'Số lần sử dụng',
      align: 'start',
      dataIndex: 'countUsing',
      key: 'countUsing'
    },
    {
      title: 'Trạng thái',
      align: 'start',
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => {
        return (
          record?.warehouse?.status === 'OUT' ? (
            <span style={{color: 'red'}}>Đang luân chuyển</span>
          ) :
          (<span style={{ color: WAREHOUSE_STATUS[value]?.colorStyle }}>
            {WAREHOUSE_STATUS[value]?.name}
            </span>)
        );
      }
    },
    // {
    //   title: '',
    //   align: 'start',
    //   dataIndex: 'action',
    //   render: (value, record) => {
    //     const checkAction = record?.action === `${ACTIVE_KEY.INACTIVE}`;
    //     return (
    //       checkAction &&
    //       <WithPermission permission={POLICY.DELETE_WAREHOUSE}>
    //         <Button
    //           onClick={() => {}}
    //           key={v4()}
    //         >
    //           <p>Xoá</p>
    //         </Button>
    //     </WithPermission>
    //     )
    //   }
    // }
  ];
  return (
    <>
      <div className='page-wraper page-content wh-bills-page'>
       
          <div class="container-fluid">
            <Breadcrumb title={`Danh sách trang thiết bị trong kho ${wareHouseInfo ? `/ ${wareHouseInfo?.name}` : ''}`} />
            <div className='page-wraper__header'>
            <Row>
              <Col span={4} style={{marginRight: 36}}>
                <Select
                  value={searchBy}
                  style={{ width: '100%'}}
                  onChange={(val) => {
                    onParamChange({
                      code: null,
                      name: null,
                      groupProductId: null,
                      manufacturer: null,
                      dateManufacturer: null,
                      countDepreciation: null,
                      valueDepreciation: null,
                      unitDepreciation: null,
                      countUsing: null,
                      status: null,
                      page: 1
                    });
                    setSearchBy(val);
                    setKeyword('');
                  }}
                >
                  {optionsSearch.map((value, index) => {
                    return <Option value={value?.value}>{value.name}</Option>;
                  })}
                </Select>
              </Col>
              <Col span={6}>
                {searchBy === 'status' ? (
                  <Select
                    mode="multiple"
                    style={{
                      width: '100%'
                    }}
                    placeholder="Vui lòng chọn trạng thái"
                    optionLabelProp="label"
                    allowClear
                    onChange={(e) => onParamChange({ [searchBy]: e, page: 1 })}
                  >
                    {transform(
                      WAREHOUSE_STATUS,
                      (result, value, key) => {
                        result.push({
                          label: get(value, 'name', ''),
                          value: key,
                          color: get(value, 'colorStyle', '')
                        });
                      },
                      []
                    )?.map((e) => {
                      return (
                        <Option value={get(e, 'value')} label={get(e, 'label')}>
                          <p style={{ color: `${e.color}` }}>{get(e, 'label')}</p>
                        </Option>
                      );
                    })}
                  </Select>
                ) : searchBy === 'dateManufacturer' ? (
                  <DatePicker
                    allowClear={false}
                    onChange={(e) => {
                      const time = moment(e).format('YYYY-MM-DD');
                      onParamChange({ [searchBy]: time, page: 1 });
                    }}
                  />
                ) : (
                  <Search
                    allowClear
                    style={{ maxWidth: '500px' }}
                    enterButton
                    placeholder={`Tìm ${optionsSearch
                      .find((item) => {
                        return item?.value === searchBy;
                      })
                      ?.name?.toLowerCase()}`}
                    onSearch={() => {
                      onParamChange({ [searchBy]: keyword, page: 1 });
                    }}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                      if (!e.target.value) {
                        onParamChange({ [searchBy]: '' });
                      }
                    }}
                    value={keyword}
                  />
                )}
              </Col>
              </Row>
              <WithPermission permission={POLICY.WRITE_PRODUCTDELIVERY}>
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={()=>handleOpenFormDeliveryVoucher()}

                >
                  Tạo phiếu luân chuyển
                </Button>
              </WithPermission>
            </div>
            <div >
              {isLoading ? (
                <SkeletonTable
                  columns={columns}
                  pagination={paging}
                />
              ) : (
                <Table
                scroll={{ x: 2000 }}
                className="wh-service-table-striped-rows"
                  columns={columns}
                  dataSource={products}
                  pagination={{
                   ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                  onChange={({ current }) => {
                    onParamChange({ page: current});
                  }}
                  size="middle"
                />
              )} 
            </div>

          </div>
          </div>
      <Modal
      destroyOnClose
      visible={isOpenForm}
      width={'1000px'}
      title={`${id ? 'Cập nhật' : 'Thêm mới'} phiếu luân chuyển`}
      onOk={()=>  setIsOpenForm(false)}
      onCancel={()=>  setIsOpenForm(false)}
      footer={null}
      >
        <ConfirmCouponProForm
          handleCloseFormDeliveryVoucher = {handleCloseFormDeliveryVoucher}
        />
      </Modal>
      <Modal
        destroyOnClose
        visible={isOpen}
        closable={false}
        onCancel={handleCloseForm}
        width={'1000px'}
        title={`${id ? 'Cập nhật' : 'Thêm mới'} sản phẩm`}
        footer={null}
      >
        <ProductListForm
          setIsOpenForm={setIsOpen}
          isOpenForm={isOpen}
          id={id}
          setId={setId}
          handleCloseForm={handleCloseForm}
          isStatusProduct={isStatusProduct}
        />
      </Modal>
    </>
  )
}
