import { Button, Input, Modal, Popconfirm, Table, Typography } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  useCreateServiceByLocation,
  useDeleteServiceByLocation,
  useServiceByLocationPaging,
  useServiceByLocationQueryParams,
  useServicesByLocation,
  useUpdateServiceByLocationParams
} from '~/hooks';
import { Breadcrumb, WithPermission } from '../Common';
import SkeletonTable from '../Utils/SkeletonTable';
import WhBoxServicePartner from '../WhPartner/WhBoxServicePartner';
import ServiceByLocationForm from './Form';
import POLICIES from '~/constants/policy';

const { Search } = Input;
const ColumnActions = ({
  locationIndex,
  deleteServiceByLocation,
  onOpenForm,
  ...restProps
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICIES.DELETE_LOCATIONSERVICE}>
        <Popconfirm
          title="Bạn muốn xoá tỉnh/ thành phố này?"
          onConfirm={() => deleteServiceByLocation({ locationIndex })}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <Typography.Paragraph style={{ cursor: 'pointer' }} type="danger">
            Xoá
          </Typography.Paragraph>
        </Popconfirm>
      </WithPermission>
    </div>
  );
};

const SearchByLocation = () => {
  const [query, onPagingChange] = useServiceByLocationQueryParams();
  const [data, isLoading] = useServicesByLocation(query);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateServiceByLocationParams(query);
  const paging = useServiceByLocationPaging();

  const onOpenForm = (id) => {
    setIsOpen(true);
    if (id) {
      setId(id);
    }
  };

  const onCloseForm = () => {
    setIsOpen(false);
  };

  const [, deleteServiceByLocation] = useDeleteServiceByLocation();
  const [isSubmitLoading, handleCreate] =
    useCreateServiceByLocation(onCloseForm);

  const columns = [
    {
      title: 'Tỉnh/ Thành phố',
      dataIndex: 'location',
      key: 'location',
      width: 200,
      render: (item, record) => (
        <Link to={`/service-by-location/${record?.locationIndex}`}>
          {item?.full_name}
        </Link>
      )
    },
    {
      title: 'Số lượng dịch vụ',
      dataIndex: 'services',
      key: 'services',
      render: (record) => {
        return record?.length || 0;
      }
    },
    {
      title: 'Dịch vụ',
      dataIndex: 'services',
      key: 'services',
      render: (services) => <WhBoxServicePartner services={services} />
    },
    // {
    //   title: 'Trạng thái',
    //   key: 'status',
    //   dataIndex: 'status',
    //   align: 'center',
    //   width: 200,
    //   render: (status, record) => {
    //     return (
    //       <WithPermission permission={POLICIES.UPDATE_}>
    //         <Switch
    //           checked={status === 'ACTIVE'}
    //           onChange={(value) =>
    //             onChangeStatus(
    //               get(record, '_id'),
    //               value ? 'ACTIVE' : 'INACTIVE',
    //               isLoading,
    //               record
    //             )
    //           }
    //         />
    //       </WithPermission>
    //     );
    //     // ) : (
    //     //   <Switch checked={status === 'ACTIVE'} />
    //     // );
    //   }
    // },
    {
      title: 'Thao tác',
      key: 'action',
      width: 100,
      align: 'center',
      render: (record) => {
        record = {
          ...record,
          id: record._id,
          locationIndex: record.locationIndex
        };
        return (
          <ColumnActions
            {...record}
            deleteServiceByLocation={deleteServiceByLocation}
            onOpenForm={onOpenForm}
          />
        );
      }
    }
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách dịch vụ theo tỉnh/ thành phố" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Search
              style={{ width: '100%' }}
              placeholder="Tìm tỉnh, thành phố"
              enterButton
              allowClear
              onSearch={() => onParamChange({ keyword })}
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
          </div>
          <div className="page-content__right">
            <div className="page-content__main" style={{ display: 'block' }}>
              <div className="page-wraper__header">
                <WithPermission permission={POLICIES.WRITE_LOCATIONSERVICE}>
                  <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                      setIsOpen(true);
                      setId(null);
                    }}
                  >
                    {' '}
                    Thêm mới
                  </Button>
                </WithPermission>
              </div>
              {isLoading ? (
                <SkeletonTable rowCount={data.length} columns={columns} />
              ) : (
                <Table
                  loading={isLoading}
                  rowKey={(rc) => rc._id || rc.id}
                  columns={columns}
                  dataSource={data}
                  onChange={onPagingChange}
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `,
                    showSizeChanger: true
                  }}
                  size="middle"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <ServiceByLocationForm
          onClose={onCloseForm}
          id={id}
          query={query}
          handleCreate={handleCreate}
          isSubmitLoading={isSubmitLoading}
        />
      </Modal>
    </div>
  );
};

export default SearchByLocation;
