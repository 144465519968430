import {
  CaretDownOutlined,
  EditFilled,
  InfoCircleOutlined,
  InfoOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Col, Modal, Row, Select, Switch, Form, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { get, parseInt, transform } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb, WithPermission } from '~/components/Common';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { useMatchPolicy } from '~/hooks';
import POLICY from '~/constants/policy';
import {
  useDeleteProductCatalogue,
  useProductCataloguePaging,
  useProductCatalogueQueryParams,
  useProductCatalogues,
  useResetProductCatalogue,
  useUpdateProductCatalogue,
  useUpdateProductCatalogueParams,
  useUpdateProductCatalogueStatus
} from '~/hooks/productCatalogue';
import ProductCatalogueForm from './ProductCatalogueForm';
import { ACTIVE_WAREHOUSE, WAREHOUSE_STATUS } from '~/constants/defaultValue';
import ListProductByStatus from './ListProductByStatus';
import toastr from 'toastr';
import TabBranch from '~/components/Common/TabBranch';

const { Option } = Select;

const optionsSearch = [
  { value: 'code', name: 'Mã nhóm sản phẩm' },
  { value: 'name', name: 'Tên danh mục sản phẩm' },
  { value: 'action', name: 'Trạng thái hoạt động' }
];

const ColumnActions = ({
  id,
  action,
  deleteProduct,
  updateProduct,
  isSubmitLoading,
  handleOpenFormUpdate,
  isAction,
  ...restProps
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_WAREHOUSE}>
        <Button type="link" onClick={() => handleOpenFormUpdate(id)}>
          <EditFilled />
        </Button>
      </WithPermission>
      <WithPermission permission={POLICY.UPDATE_WAREHOUSE}>
        <WithPermission permission={POLICY.DELETE_WAREHOUSE}>
          <p>|</p>
        </WithPermission>
      </WithPermission>
      <WithPermission permission={POLICY.DELETE_WAREHOUSE}>
        <Switch
          checked={action === 'ACTIVE'}
          onChange={(value) => {
            if (isAction) {
              toastr.error(
                'Không thể thực hiện thao tác này vì có thiết bị đang được sử dụng'
              );
            } else {
              updateProduct({ action: value ? 'ACTIVE' : 'INACTIVE', id });
            }
          }}
          loading={isSubmitLoading}
        />
      </WithPermission>
    </div>
  );
};

const ProductCatalogue = () => {
  const [form] = Form.useForm();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenFormStatus, setIsOpenFormStatus] = useState(false);
  const [searchBy, setSearchBy] = useState('name');
  const [id, setId] = useState(null);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WAREHOUSE);
  const canDelete = useMatchPolicy(POLICY.DELETE_WAREHOUSE);
  const [query] = useProductCatalogueQueryParams();

  const [keyword, { setKeyword, onParamChange }] =
    useUpdateProductCatalogueParams(query);
  const [products, isLoading] = useProductCatalogues(query);
  const [isSubmitLoading, updateProduct] = useUpdateProductCatalogueStatus();
  const [, deleteProduct] = useDeleteProductCatalogue();
  const [status, setStatus] = useState('');

  const paging = useProductCataloguePaging();

  useResetProductCatalogue()

  const handleOpenFormCreate = () => {
    setIsOpenForm(true);
    setId(null);
  };
  const handleOpenFormUpdate = (id) => {
    setIsOpenForm(true);
    setId(id);
  };

  const handleCloseForm = () => {
    setIsOpenForm(false);
    setId(null);
  };

  const handleOpenFormStatus = (id) => {
    setIsOpenFormStatus(true);
    setId(id);
  };
  const handleCloseFormStatus = () => {
    setIsOpenFormStatus(false);
    setId(null);
    setStatus('');
  };
  const columns = [
    {
      title: 'Mã nhóm sản phẩm',
      align: 'start',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Tên danh mục nhóm sản phẩm',
      align: 'start',
      dataIndex: 'name',
      key: 'name',
      render: (value) => value?.vi
    },
    {
      title: 'Loại thiết bị y tế',
      align: 'start',
      dataIndex: 'medicalEquipment',
      key: 'medicalEquipment',
      render: (value, rc) => rc?.deviceInventory?.name?.vi ?? ''
    },
    {
      title: 'Tổng kho',
      align: 'start',
      dataIndex: 'total',
      key: 'total'
    },
    {
      title: 'Chờ sử dụng',
      align: 'start',
      dataIndex: 'ready',
      key: 'ready',
      render: (value, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              handleOpenFormStatus(record._id), setStatus("READY");
            }}
          >
            {value}
          </Button>
        );
      }
    },
    {
      title: 'Đã bàn giao',
      align: 'start',
      dataIndex: 'assigned',
      key: 'assigned',
      render: (value, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              handleOpenFormStatus(record._id), setStatus('ASSIGNED');
            }}
          >
            {value}
          </Button>
        );
      }
    },
    {
      title: 'Đang sử dụng',
      align: 'start',
      dataIndex: 'processing',
      key: 'processing',
      render: (value, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              handleOpenFormStatus(record._id);
              setStatus('PROCESSING');
            }}
          >
            {value}
          </Button>
        );
      }
    },
    {
      title: 'Ngưng sử dụng',
      align: 'start',
      dataIndex: 'unready',
      key: 'unready'
    },

    ...(canDelete || canUpdate
      ? [
          {
            title: 'Thao tác',
            key: 'action',
            width: '150px',
            render: (value, record) => {
              const isAction =
                record.processing > 0 || record.assigned > 0 ? true : false;
              return (
                <ColumnActions
                  {...record}
                  handleOpenFormUpdate={() => handleOpenFormUpdate(record._id)}
                  deleteProduct={deleteProduct}
                  updateProduct={updateProduct}
                  isSubmitLoading={isSubmitLoading}
                  id={record._id}
                  isAction={isAction}
                />
              );
            }
          }
        ]
      : [])
  ];

  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh mục nhóm sản phẩm" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form.Item vertical label="Tìm kiếm:">
              {searchBy !== 'action' ? (
                <Search
                  allowClear
                  style={{ maxWidth: '500px' }}
                  placeholder={`Tìm ${optionsSearch
                    .find((item) => item?.value === searchBy)
                    ?.name?.toLowerCase()}`}
                  enterButton
                  onSearch={() => {
                    onParamChange({ [searchBy]: keyword, page: 1 });
                  }}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    if (!e.target.value) {
                      onParamChange({ [searchBy]: '' });
                    }
                  }}
                  value={keyword}
                />
              ) : (
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Vui lòng chọn trạng thái"
                  optionLabelProp="label"
                  allowClear
                  onChange={(e) => onParamChange({ [searchBy]: e, page: 1 })}
                >
                  {transform(
                    ACTIVE_WAREHOUSE,
                    (result, value, key) => {
                      result.push({
                        label: get(value, 'name', ''),
                        value: key,
                        color: get(value, 'colorStyle', '')
                      });
                    },
                    []
                  ).map((e, index) => (
                    <Option
                      key={index}
                      value={get(e, 'value')}
                      label={get(e, 'label', '')}
                    >
                      <p style={{ color: e.color }}>{get(e, 'label')}</p>
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item vertical label="Tìm kiếm theo:">
              <Select
                value={searchBy}
                style={{ width: '100%' }}
                onChange={(val) => {
                  onParamChange({
                    code: null,
                    name: null,
                    action: null,
                    page: 1
                  });
                  setSearchBy(val);
                  setKeyword(null);
                }}
              >
                {optionsSearch.map((value, index) => (
                  <Option key={index} value={value?.value}>
                    {value.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="page-content__right">
            <div className="page-wraper__header">
              <Row gutter={48}>
                <Col span={24}>
                  <Row justify="end" align="middle">
                    <WithPermission permission={POLICY.WRITE_WAREHOUSE}>
                      <Button
                        type="primary"
                        onClick={() => handleOpenFormCreate()}
                      >
                        Thêm mới
                      </Button>
                    </WithPermission>
                  </Row>
                </Col>
              </Row>
            </div>

            {isLoading || isSubmitLoading ? (
              <SkeletonTable columns={columns} pagination={paging} />
            ) : (
              <Table
                scroll={{ x: 1000 }}
                className="wh-service-table-striped-rows"
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={products}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                onChange={({ current }) => {
                  onParamChange({ page: current });
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        visible={isOpenForm}
        closable={false}
        footer={null}
        onCancel={handleCloseForm}
        width={700}
        title={`${id ? 'Cập nhật' : 'Thêm mới'} danh mục sản phẩm`}
      >
        <ProductCatalogueForm
          setIsOpenForm={setIsOpenForm}
          id={id}
          setId={setId}
          handleCloseForm={handleCloseForm}
          isOpen={isOpenForm}
          products={products}
        />
      </Modal>

      <Modal
        visible={isOpenFormStatus}
        closable={false}
        footer={null}
        onCancel={handleCloseFormStatus}
        width={700}
        title={` ${
          status === 'READY'
            ? 'Danh sách thiết bị chờ sử dụng'
            : 'Danh sách dịch vụ sử dụng thiết bị'
        } `}
      >
        <ListProductByStatus
          setIsOpenForm={setIsOpenForm}
          id={id}
          handleCloseFormStatus={handleCloseFormStatus}
          isOpen={isOpenFormStatus}
          status={status}
        />
      </Modal>
    </div>
  );
};

export default ProductCatalogue;
