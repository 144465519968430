import React, { useMemo, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { FilterDatePicker } from '~/components/Common';
import { Table, Input, Switch, Space, Tabs, Col, Row, Button, message, Tag, Popover } from 'antd';
import {
  useWhCustomersManagement,
  useUpdateWhCustomerManagement,
  useWhCustomersManagementQueryParams,
  useWhCustomerManagementPaging,
  useUpdateCustomerManagementParams,
  useResetWhCustomerManagement,
  useMergeWhCustomerManagementWithRanking,
  useResetPasswordCustomer
} from '~/hooks/whCustomerManagement';
import moment from 'moment';
import { useMatchPolicy } from '~/hooks';
import POLICY from '~/constants/policy';
import WithPermission from '~/components/Common/WithPermission';
import History from './Detail/History';
import Information from './Detail/Information';
import './index.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { floorFormatter, formatPhone } from '~/utils/helper';
import ExpandTabs from './Detail/ExpandTabs';
import { get } from 'lodash';
import Text from 'antd/lib/typography/Text';
// import { Row } from 'reactstrap';
import { useRankings } from '~/hooks/ranking';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import ModalConfirm from '../Common/ModalConfirm';
import api from '~/api';
import toastr from 'toastr';
import { ZoomInOutlined } from '@ant-design/icons';
import TabBranch from '../Common/TabBranch';
import ToolTipButton from '../Utils/ToolTipButtonPermission';
import FormResetPassword from './FormResetPassword';
const { TabPane } = Tabs;
const { Search } = Input;
function callback(key) {
  // console.log(key);
}



const ColumnActions = ({ id, state, isSubmitLoading, updateWhCustomerManagement, ...restProps }) => {
  const [isChecked, setIsChecked] = useState(state === 'ACTIVE')
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_CUSTOMER}>
        <Switch
          checked={isChecked}
          onChange={(value) => {
            setIsChecked(!isChecked)
            updateWhCustomerManagement({ state: value ? 'ACTIVE' : 'INACTIVE', id })

          }}
        />
      </WithPermission>
    </div>
  );
};

const WhCustomersManagement = () => {
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false)
  const [reFetch,setRefetch] = useState(false);
  const [idConvert, setIdConvert] = useState(null)
  const [loadingConvert, setLoadingConvert] = useState(false)
  const [itemActive, setItemActive] = useState(null);
  const [query, onPagingChange] = useWhCustomersManagementQueryParams(reFetch);
  const [isSubmitLoading, updateWhCustomerManagement] = useUpdateWhCustomerManagement();
  const [, resetPassword] = useResetPasswordCustomer();
  const [keyword, { setKeyword, onParamChange }] = useUpdateCustomerManagementParams(query);
  const queryFetchRanking = useMemo(() => ({ page: 1, limit: MAX_PAGINATION_LIMIT, sortPoint: 1 }), [])
  const [rankings, isLoadingRankings] = useRankings(queryFetchRanking)
  const [whCustomersManagement, isLoading] = useWhCustomersManagement(query);
  const mergeRankingWithWhCustomersManagement = useMergeWhCustomerManagementWithRanking(whCustomersManagement, rankings)
  useResetWhCustomerManagement();
  const paging = useWhCustomerManagementPaging();
  const [isHovered,setIsHovered] = useState(false)
  const [cellHouver,setCellHover] = useState(null)
  const handleOpenModalConvert = (id) => {
    setVisibleModalConfirm(true)
    setIdConvert(id)
  };
  const handleMouseEnter = (value) => {
    setCellHover(value._id)
  };
  const handleMouseLeave = () => {
    setCellHover(null)
  };
  const handleCloseModalConvert = () => {
    setVisibleModalConfirm(false)
    setIdConvert(null)
  };
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    message.success('Coppy mã khách hàng thành công!')
  };
  const handleConvert = async () => {
    
    try {
      // Pending
      setLoadingConvert(true);

      // Action
      const res = await api.percentChangeCustomerToPartner.convert(idConvert);

      // Success
      if (get(res, 'status')) {
        toastr.success(get(res, 'message', ''))
        handleCloseModalConvert()
        setRefetch(!reFetch);
      }
      setLoadingConvert(false)
    } catch (error) {
        // Failed
      setLoadingConvert(false)
      toastr.error(get(error, 'response.data.message', "Something went wrong"))
    }
  }
  const columns = [
    {
      title: 'Mã khách hàng',
      dataIndex: 'customerIdFull',
      key: 'customerIdFull',
      width: '300px',
      render: (value, record) =>  (
        <Button
      type='link'
      style={{ padding: '0px 20px' }}
      onMouseEnter={() => handleMouseEnter(record)}
      onMouseLeave={() => handleMouseLeave()}
      onClick={() => window.open(`/customer-info/${record?._id}`)}
    >
      {value}
      {cellHouver === record._id && (
        <Tag color='red' style={{ marginLeft: '10px',zIndex: 999 }} type='link' onClick={(e) => {
          e.stopPropagation(); 
         return handleCopy(value);
        }}>
          Copy
        </Tag>
      )}
    </Button>
      )
    },
    {
      title: 'Tên',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (record) => {
        return record;
      }
    },

    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (record) => {
        return formatPhone(record || '');
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      render: (value) => {
        
        var str = ""
        if (value) {
          var strStreet = value.street ? value.street : ' ';
          var strWard = (value.street && value.ward) ? ', ' + value.ward :  value.ward ?  value.ward : ' ';
          var strDistrict = value.district ? ', ' + value.district : ' ';
          var strCity = value.city ? ', ' + value.city : ' ';
          str = strStreet + strWard + strDistrict + strCity;
        } else {
          return ""
        }
        return str
      }
      
    },
    {
      title: 'Loại khách hàng',
      dataIndex: 'customerType',
      key: 'customerType',
      render : (value,record) => <Text strong>{get(record,'ranking.name','Chưa có')}</Text>
    },
    {
      title: 'Điểm thưởng',
      dataIndex: 'point',
      key: 'point',
      render: (value,record) => <Row>
        <Col span={24}>
          <span>Điểm thưởng hoàn tiền: <Text strong>{floorFormatter(get(record,'typePoint.resultNoCashOut',0))}</Text></span>
        </Col>
        <Col span={24}>
          <span>Điểm thưởng rút tiền: <Text strong>{floorFormatter(get(record,'typePoint.resultListCashOut',0))}</Text></span>
        </Col>
      </Row>
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      render: (record) => {
        return moment(record).format('DD/MM/YYYY');
      }
    },
    {
      title: 'Thao tác',
      key: 'action',
      align: 'center',
      render: (record) => {
        record={
          ...record,
          state:record.state,
          id:record._id,
        }
        return (
          <ColumnActions 
            {...record}
            updateWhCustomerManagement={updateWhCustomerManagement}
             />
        );
      }
    },
    {
      title: 'Chuyển đổi',
      key: 'actions',
      align: 'center',
      width: 220,
      render: (item,record) => {
        const isPartner = get(record,'isPartner');
        return (
        <WithPermission permission={POLICY.UPDATE_CUSTOMER}>
            <ToolTipButton titleToolTip={isPartner ? "Tài khoản này đã là đối tác" : ''} type={'text'}>
            <Button disabled={isPartner}  onClick={() => handleOpenModalConvert(get(item,'_id'))}>Chuyển thành đối tác</Button>
            </ToolTipButton>
        </WithPermission>
        );
      }
    },
    {
      title: 'Tạo lại mật khẩu',
      key: 'actions',
      align: 'center',
      width: 220,
      render: (item,record) => {
        return (
        <WithPermission permission={POLICY.UPDATE_CUSTOMER}>
        <Popover trigger={'click'} content={<FormResetPassword onOk={(values) => resetPassword({phoneNumber : get(record,'phoneNumber'),...values})}/>}>
            <Button>Thay đổi mật khẩu</Button>
        </Popover>
        </WithPermission>
        );
      }
    }
  ]
  return (
    <div className="page-wrapper page-content whCustomerManagements-page">
      <div className="container-fluid">
        <TabBranch useBoxShadow={false}>
          <Breadcrumb title="Danh sách khách hàng" />
          <div className="page-content__main">
            <div className="page-content__left">
              <div className="page-wrapper__header">
                <Search
                  style={{ width: "100%" }}
                  placeholder="Tên, Loại khách hàng"
                  enterButton
                  allowClear
                  onSearch={() => onParamChange({ keyword })}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              </div>
              {/* <FilterWhCustomerManagementStatus
              value={query.state}
              onChange={(value) => onParamChange({ state: value })}
            /> */}
              <FilterDatePicker
                label="Từ"
                value={query.createdAt}
                onChange={(value) => onParamChange({ createdAt: value })}
              />
              <FilterDatePicker
                label="Đến"
                value={query.updatedAt}
                onChange={(value) => onParamChange({ updatedAt: value })}
              />
            </div>

            <div className="page-content__right">
              {isLoading ? (
                //<SkeletonTable columns={columns} rowCount={10} />
                <SkeletonTable
                  columns={columns}
                  rowCount={mergeRankingWithWhCustomersManagement.length}
                  pagination={paging}
                />
              ) : (
                <Table
                  rowKey={(rc) => rc._id}
                  columns={columns}
                  dataSource={mergeRankingWithWhCustomersManagement}
                  onChange={onPagingChange}
                  scroll={{ x: 1600 }}
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                  size="middle"
                  // scroll={{ x: 2000 }}
                  onExpand={(expanded, record) => {
                    expanded ? setItemActive(record._id) : setItemActive(null);
                  }}
                  //   expandable={{
                  //     expandRowByClick: true,
                  //     expandIcon: (record) => {
                  //       return <a style={{padding: '0px 20px'}} target='_blank' href= {`/customer-info/${record?.record?._id}`}>
                  //         </a>
                  //     },
                  //   expandedRowKeys: [itemActive]
                  // }}
                />
              )}
            </div>
          </div>
        </TabBranch>
      </div>
      <ModalConfirm
        textOk="Xác nhận"
        type="approved"
        text="Xác nhận Chuyển đổi khách hàng thành đối tác"
        loading={loadingConvert}
        visible={visibleModalConfirm}
        onCancel={handleCloseModalConvert}
        handleOk={handleConvert}
      />
    </div>
  );
};

export default WhCustomersManagement;
