import React, { useEffect, useMemo, useRef, useState } from 'react';

import { get } from 'lodash';
import { Button, Card, Col, Divider, Form, Input, Popconfirm, Row, Select, Space, Switch, Table, Tag } from 'antd';
import { CopyOutlined, EditFilled } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';

import {
  useWhCategories, useWhCategoryQueryParams,
} from '~/hooks/whCategory';
import {
  useWhPackageLevels,
} from '~/hooks/whPackageLevel';
import {
  useCopyWhService,
  useDeleteWhService,
  useResetWhService,
  useUpdateWhService,
  useUpdateWhServiceQueryParams,
  useWhServicePaging,
  useWhServiceQueryParams,
  useWhServices,
  useWhServicesDashBoard,
} from '~/hooks/whService';
import {
  useWhSessionsOfDay,
  useWhSessionOfDayQueryParams,
} from '~/hooks/whSessionOfDay';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';

import WhServicePackageLevelView from './WhServicePackageLevelView';
import WhServiceQuestionView from './WhServiceQuestionView';

import './index.scss';
import DebounceSelectByCategory from '../WhCategory/SearchByCategory';
import { getExistProp } from '~/utils/helper';
import DebounceSelectByLocation from './DebounceSelectByLocation';
import ExportExcelButtonV2, { useSelectedExport } from '../Export/ExportExcelButtonV2';
import api from '~/api';

const { Search } = Input;

const ColumnActions = ({ id, status, deleteWhService, updateWhService,copyWhService, ...restProps }) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_WHSERVICE}>
        <Link to={`/wh-service/edit/${id}`}>
          {/* <p>Sửa</p> */}
          <EditFilled />
        </Link>
      </WithPermission>
      <WithPermission permission={POLICY.UPDATE_WHSERVICE}>
        <WithPermission permission={POLICY.DELETE_WHSERVICE}>
          <p>|</p>
        </WithPermission>
      </WithPermission>
      {/* <Popconfirm
        title="Bạn muốn xoá dịch vụ này?"
        onConfirm={() => deleteWhService(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '} */}
      <WithPermission permission={POLICY.DELETE_WHSERVICE}>
        <Switch
          checked={status === 'ACTIVE'}
          onChange={(value) => updateWhService({ status: value ? 'ACTIVE' : 'INACTIVE', id })}
          // loading={isSubmitLoading}
        />
      </WithPermission>
      <WithPermission permission={POLICY.UPDATE_WHSETTING}>
        <WithPermission permission={POLICY.WRITE_WHSETTING}>
          <p style={{marginLeft:'10px'}}>|</p>
        </WithPermission>
      </WithPermission>

      <WithPermission permission={POLICY.WRITE_WHSETTING}>
        <Popconfirm
          title="Bạn có chắc chắn muốn sao chép dịch vụ này?"
          onConfirm={()=>copyWhService(id)}
          // onCancel={cancel}
          okText="Đồng ý"
          cancelText="Huỷ"
          >
            <CopyOutlined className='copy-icon'
          />
          </Popconfirm>
      </WithPermission>
    </div>
  );
};

const WhServiceExpanded = ({ whService, whSessionsOfDay }) => {
  const { code } = whService;
  return (
    <div className='wh-service-detail'>
      <Space direction='vertical' size="middle" style={{ width: "100%" }}>
        <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Col span={12}>
            <Row justify='space-between'>
              <div>Mã dịch vụ:</div>
              <div>{get(whService, 'code')}</div>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify='space-between'>
              <div>Nhóm dịch vụ:</div>
              <div>{get(whService, 'whCategory.name.vi')}</div>
            </Row>
          </Col>
        </Row>

        <Row className='wh-service-detail__row'>
          <Col span={24} lg={24}>
            {/* <Card title="Mô tả dịch vụ"> */}
              {/* <Title level={2}> */}
              {/* <h3>
                Mô tả dịch vụ
              </h3> */}
              <Divider plain>
                <h3>
                  Mô tả dịch vụ
                </h3>
              </Divider>
              {/* </Title> */}
              {/* <TextArea
                value={get(whService, 'description.vi')}
                placeholder={'Mo ta dich vu'}
                bordered={true}
                contentEditable={false}
              /> */}
              <p
                className='wh-service-detail__description--paragraph'
              >
                {get(whService, 'description.vi')}
              </p>
            {/* </Card> */}
          </Col>
        </Row>

        <Row className='wh-service-detail__row'>
          <Col span={24}>
            <Card className='card'>
              <h3>Khai báo tình trạng</h3>
              {whService.questions?.map((item, index) => (
                <WhServiceQuestionView key={index} question={item} />
              ))}
            </Card>
          </Col>
        </Row>
        <Row className='wh-service-detail__row'>
          <Col span={24}>
            <Space direction='vertical' size="middle" style={{ width: "100%" }}>
            {whService.packages?.map((item, index) => (
              // <Card key={index}>
              <WhServicePackageLevelView key={index} packageLevel={item} whSessionsOfDay={whSessionsOfDay}  />
              // </Card>
            ))}
            </Space>
          </Col>
        </Row>
      </Space>
    </div>
  )
}

const WhServices = ({ history }) => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHSERVICE);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHSERVICE);
  const canDownload = useMatchPolicy(POLICY.DOWNLOAD_WHSERVICE);
  const [query, onPagingChange] = useWhServiceQueryParams();
  const historys = useHistory()
  const prePage  = useRef(null)
  // if(historys.action === "POP"){
  //   const querys = historys.location.search;
  //   const start = (querys.indexOf("="));
  //   const end = (querys.indexOf("&"));
  //   const prePageHistory = querys.slice(start+1,end)
  //   prePage.current = parseInt(prePageHistory)
  //    }
    //  useEffect(() => {
    //   if(prePage.current)onPagingChange({current:prePage.current})
    //  },[prePage.current])
  // useEffect(() => {
  //   let queries = '';
  //   queries = query.keyword ? `?page=${query?.page || 1}&limit=${query.limit || 10}&keyword=${query.keyword}` : `?page=${query?.page || 1}&limit=${query.limit || 10}`
  //   if (query.whCategory) queries += `&whCategory=${query.whCategory}`;
  //   if (query.locationIndexes) queries += `&locationIndexes=${query.locationIndexes}`
  //   historys.push(`/wh-service${queries}`);
  //   prePage.current = null
  // }, [query, historys]);
  
  const [whServices, isLoading] = useWhServicesDashBoard(query);
  const [queryCategories] = useWhCategoryQueryParams(MAX_PAGINATION_LIMIT);
  const [whCategories] = useWhCategories(queryCategories);
  const [whPackageLevels] = useWhPackageLevels();
  const [querySessionsOfDay,] = useWhSessionOfDayQueryParams(MAX_PAGINATION_LIMIT);

  const querySessions = useMemo(() => ({
    page: 1,
    limit: MAX_PAGINATION_LIMIT
  }), []); // 

  const [whSessionsOfDay, isWhSessionsOfDayLoading] = useWhSessionsOfDay(querySessions);
  // const [keyword, setKeyword] = useState(query.keyword);
  const [, deleteWhService] = useDeleteWhService();
  const [isSubmitLoading, updateWhService] = useUpdateWhService();
  const [,copyWhService] = useCopyWhService()
  const paging = useWhServicePaging();
  const [itemActive, setItemActive] = useState(null);
  const [whCategory, setWhCategory] = useState(null);
  const [locationIndex, setLocationIndex] = useState(null);
  const [arrCheckBox, onSelectCheckBox] = useSelectedExport();
  const [keyword ,{setKeyword, onParamChange }] = useUpdateWhServiceQueryParams(query);
  const [searchBy, setSearchBy] = useState('status');

  useResetWhService()
  const mergedWhServices = useMemo(() => {
    return whServices.map(whService => {
      return {
        ...whService,
        whCategory: whCategories?.find(whCategory => whCategory.id === whService.whCategoryId),
        packages: whService.packages?.map(item => {
          return {
            ...item,
            whPackageLevel: whPackageLevels?.find(whPackageLevel => item.whPackageLevelId === whPackageLevel._id),
            sessionPrices: item.sessionPrices?.map(item => {
              return {
                ...item,
                whSessionOfDay: whSessionsOfDay?.find(whSessionOfDay => item.whSessionOfDayId === whSessionOfDay._id),
              }
            })
          }
        }),
      }
    })
  }, [whServices, whCategories, whPackageLevels, whSessionsOfDay])

  useEffect(() => {
    if (!whCategory && query?.whCategory) {
      const splitWhCategory = query?.whCategory.split(',');
      setWhCategory(splitWhCategory);
    };

    if (!locationIndex && query?.locationIndexes) {
      const splitLocationIndex = query?.locationIndexes.split(',');
      setLocationIndex(splitLocationIndex);
    };
  }, [query]);

  // const onSearch = (e,category) => {
  //   let searchParams = `?page=${query?.page || 1}&limit=${query?.limit || 10}`;
  //   if (keyword) searchParams += `&keyword=${keyword}`;
  //   if (category || whCategory) {
  //     searchParams += `&whCategory=${e || whCategory}`
  //   };
  //   history.push({
  //     pathname: '/wh-service',
  //     search: searchParams
  //   });
  // };
  // const onSearchLocation = (e, location) => {
  //   let searchParams = `?page=${query?.page || 1}&limit=${query?.limit || 10}`;
  //   if (keyword) searchParams += `&keyword=${keyword}`;
  //   if (location || locationIndex) {
  //     searchParams += `&locationIndexes=${e || locationIndex}`
  //   };
  //   history.push({
  //     pathname: '/wh-service',
  //     search: searchParams
  //   });
  // };

  const columns = [
    {
      title: 'Mã dịch vụ',
      dataIndex: 'code',
      key: 'code',
      width: '150px',
      fixed: 'left',
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      render: (name) => get(name, "vi")
    },
    {
      title: 'Tên dịch vụ chi tiết',
      dataIndex: 'detailName',
      key: 'detailName',
      width: '400px',
      render: (detailName) => get(detailName, 'vi')
    },
    {
      title: 'Nhóm dịch vụ',
      dataIndex: 'category',
      key: 'category',
      width: '300px',
      // render: (whCategoryId) => {
      //   return whCategories.find(item => item.id === whCategoryId)?.name?.vi
      // }
      render: (category) => {
        return category?.name?.vi
      }
    },
    {
      title: 'Tỉnh, thành phố',
      key: 'location',
      dataIndex: 'location',
      width: '300px',
      render: (location) => (
        <div className="box-service-partner">
          {location.map((item, index) => {
            return (
              <Tag color="blue" key={index}>
                {item.name}
              </Tag>
            );
          })}
        </div>
      )
    },
    {
      title: 'Trực thuộc chi nhánh',
      dataIndex: 'branchOwner',
      key: 'branchOwner',
      width: '100px',
      align: 'center',
      
    },

    // {
    //   title: 'Chứng chỉ',
    //   dataIndex: 'certification',
    //   key: 'certification',
    //   render: (certification) => <p>{certification[0]?.name}</p>
    // },
    // {
    //   title: 'Dịch Vụ',
    //   key: 'speciality',
    //   dataIndex: 'speciality',
    //   render: (speciality) => (
    //     <div className="speciality-tags">
    //       {speciality.map((spec, index) => {
    //         return (
    //           <Tag color="blue" key={index}>
    //             {spec.name}
    //           </Tag>
    //         );
    //       })}
    //     </div>
    //   )
    // },
    // {
    //   title: 'Số điện thoại',
    //   dataIndex: 'phoneNumber',
    //   key: 'phoneNumber'
    // },
    ... (canDelete || canUpdate) ? [{
      title: 'Thao tác',
      key: 'action',
      width: '150px',
      render: (record) => {
        return <ColumnActions {...record} deleteWhService={deleteWhService} updateWhService={updateWhService} copyWhService = {copyWhService} />;
      }
    }] : []
  ];

  return (
    <div className="page-wraper page-content orders-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách dịch vụ" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form.Item label='Tìm kiếm'>
              <Search
                style={{ width: '100%' }}
                placeholder="Tìm dịch vụ"
                enterButton
                onSearch={(e) =>
                  onParamChange({ ...query, keyword: e?.trim() })
                }
                onChange={(e) => setKeyword(e.target.value)}
                value={query?.keyword}
                allowClear
              />
            </Form.Item>
            <Form.Item vertical label="Trạng thái">
              <Select
                style={{ width: '210px' }}
                placeholder="Hãy chọn trạng thái"
                allowClear
                options={[
                  { value: 'ACTIVE', label: 'Đang hoạt động' },
                  { value: 'INACTIVE', label: 'Không hoạt động' }
                ]}
                onChange={(e) => {
                  onParamChange({ ...query, status: e });
                }}
                value={query?.status}
              />
            </Form.Item>
            <Form.Item vertical label="Nhóm dịch vụ">
              <DebounceSelectByCategory
                onChange={(e) => {
                  setWhCategory(e);
                  onParamChange({ ...query, whCategory: e });
                }}
                value={query?.whCategory}
                isFirstFetch={!!whCategory}
                placeholder="Tìm nhóm dịch vụ"
                style={{ maxWidth: '300px', minWidth: '200px' }}
              />
            </Form.Item>
            <Form.Item vertical label="Tỉnh, thành phố">
              <DebounceSelectByLocation
                onChange={(e) => {
                  setLocationIndex(e);
                  onParamChange({ ...query, locationIndexes: e });
                }}
                value={query?.locationIndexes}
                isFirstFetch={!!locationIndex}
                placeholder="Tìm tỉnh, thành phố"
                style={{ maxWidth: '300px', minWidth: '200px' }}
              />
            </Form.Item>
          </div>
          <div className="page-content__right">
            <div className="page-wraper__header">
              <Row gutter={48}>
                <Col span={24}>
                  <Row justify="end" align="middle">
                    <WithPermission permission={POLICY.WRITE_WHSERVICE}>
                      <Link to={PATH_APP.whService.create}>
                        <Button type="primary"> Thêm mới</Button>
                      </Link>
                    </WithPermission>
                    {canDownload && (
                      <ExportExcelButtonV2
                        stylesButton={{ float: 'right', margin: '0px 10px' }}
                        api={'/api/v1/export-wh-service'}
                        fileName={'Danh sách dịch vụ'}
                        query={query}
                        ids={arrCheckBox}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
            {isLoading || isWhSessionsOfDayLoading ? (
              <SkeletonTable
                rowCount={whServices.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className="wh-service-table-striped-rows"
                rowKey={(rc) => rc._id}
                columns={columns}
                // dataSource={whServices}
                dataSource={mergedWhServices}
                onChange={onPagingChange}
                scroll={{ x: 'max-content' }}
                size="middle"
                pagination={{
                  ...paging,
                  showSizeChanger: true,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                rowSelection={{
                  type: 'checkbox',
                  onChange: (selectedRowKeys) => {
                    onSelectCheckBox(selectedRowKeys);
                  },
                  selectedRowKeys: arrCheckBox
                }}
                // Read more: https://ant.design/components/table/#expandable
                // expandedRowKeys, defaultExpandedRowKeys, defaultExpandAllRows
                // expandedRowClassName
                // expandedRowRender
                // expandRowByClick
                // rowExpandable: boolean
                // onExpand, onExpandedRowsChange
                onExpand={(expanded, record) => {
                  expanded ? setItemActive(record._id) : setItemActive(null);
                }}
                expandable={{
                  expandedRowRender: (record) => (
                    <WhServiceExpanded
                      whService={record}
                      whSessionsOfDay={whSessionsOfDay}
                    />
                  ),
                  rowExpandable: (record) => true,
                  // defaultExpandAllRows: true,
                  expandedRowKeys: [itemActive]
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhServices;
