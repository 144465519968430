import { Col, Form, Row, Select, Table, Tabs } from 'antd';
import React, { useState } from 'react';
import { Breadcrumb } from '~/components/Common';
import TabBranch from '~/components/Common/TabBranch';
import {
  useWhPartnerPaging,
  useWhPartnerQueryParams,
  useWhPartnerReferralParams,
  useWhPartners
} from '~/hooks';
import Search from 'antd/lib/input/Search';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Expandable from './Expandable';
const { Option } = Select;

const optionsSearch = [
  { value: 'name', name: 'Tên' },
  { value: 'code', name: 'Mã nhân viên' },
  { value: 'email', name: 'Email' },
  { value: 'employeeGroup', name: 'Nhóm đối tác' }
];

export default function Referral() {
  const [searchBy, setSearchBy] = useState('name');
  const [query, onTableChange] = useWhPartnerQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useWhPartnerReferralParams();
  const [whPartners, isLoading] = useWhPartners(query);
  const paging = useWhPartnerPaging();
  const [itemActive, setItemActive] = useState([]);

  const columns = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'partnerCode',
      key: 'partnerCode'
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone'
    }
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách nhân sự / khách hàng đối tác giới thiệu" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form layout="vertical">
              <Form.Item label="Tìm kiếm">
                <Search
                  allowClear
                  style={{ maxWidth: '100%' }}
                  enterButton
                  placeholder={`Tìm ${optionsSearch
                    .find((item) => {
                      return item?.value === searchBy;
                    })
                    ?.name?.toLowerCase()}`}
                  onSearch={() => {
                    onParamChange({ page: 1, [searchBy]: keyword });
                  }}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    if (!e.target.value) {
                      onParamChange({ [searchBy]: '' });
                    }
                  }}
                  value={keyword}
                />
              </Form.Item>

              <Form.Item label="Tìm kiếm theo">
                <Select
                  value={searchBy}
                  style={{ width: '100%' }}
                  onChange={(val) => {
                    setSearchBy(val);
                    setKeyword('');
                    onParamChange({
                      name: null,
                      code: null,
                      email: null,
                      employeeGroup: null
                    });
                  }}
                >
                  {optionsSearch.map((value, index) => {
                    return (
                      <Option value={value?.value} key={index}>
                        {value?.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>
          
          <div className="page-content__right">
            <div className="page-wraper__content">
              {isLoading ? (
                <SkeletonTable columns={columns} rowCount={3} />
              ) : (
                <Table
                  dataSource={whPartners}
                  rowKey={(rc) => rc._id}
                  columns={columns}
                  onChange={onTableChange}
                  size="middle"
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total}`
                  }}
                  onExpand={(expanded, record) => {
                    expanded ? setItemActive(record._id) : setItemActive(null);
                  }}
                  expandable={{
                    expandedRowRender: (record) => (
                      <Expandable whPartner={record} />
                    ),
                    expandedRowKeys: [itemActive]
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
