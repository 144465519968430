import { Button, Col, Image, Input, Modal, Popconfirm, Row, Table, Typography } from 'antd';
import { useCallback, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useCoreValuePaging, useCoreValueQueryParams, useCoreValues, useDeleteCoreValue, useResetCoreValue, useUpdateCoreValueParams } from '~/hooks/coreValue';
import CoreValueForm from './CoreValueForm';
const { Search } = Input;

const CoreValue = ({ }) => {
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_JOB);
  const canDelete = useMatchPolicy(POLICY.DELETE_JOB);
  const [query] = useCoreValueQueryParams();
  const [CoreValues, isLoading] = useCoreValues(query);
  const [keyword, setKeyword, { onParamChange }] = useUpdateCoreValueParams(query);

  const [, deleteCoreValue] = useDeleteCoreValue();
  const paging = useCoreValuePaging();
  useResetCoreValue();

  const onOpen = useCallback((_id) => {
    setOpen(true);
    if (_id) setId(_id);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
    setId(null);
  }, []);
  const columns = [
    {
      title: 'Nội dung',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'avatar',
      key: 'avatar',
      render(h) {
        return <Image src={h} alt="avatar" width={50} />;
      },
    },
    {
      title: "Thao tác",
      dataIndex: '_id',
      key: '_id',
      width: 150,
      align: 'center',
      render: (_id) => <Row gutter={16} wrap={false} justify='center'>
        <Col>
          <WithPermission permission={POLICY.UPDATE_JOB}>
            <Typography.Link onClick={() => onOpen(_id)}>
              Cập nhật
            </Typography.Link>
          </WithPermission>
        </Col>
        <Col>
          <WithPermission permission={POLICY.DELETE_JOB}>
            <Popconfirm onConfirm={() => deleteCoreValue(_id)} title="Xác nhận xoá">
              <Typography.Link type='danger'>
                Xoá
              </Typography.Link>
            </Popconfirm>
          </WithPermission>
        </Col>
      </Row>
    }
  ];
  const onSearch = (kw) => onParamChange({ keyword: kw?.trim() })
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách giá trị cốt lõi" />

        <div className="page-content__main">
          <div className="page-content__left">
            <Search
              allowClear
              style={{ width: '100%' }}
              placeholder="Tìm giá trị cốt lõi"
              enterButton
              onSearch={onSearch}
              // onSearch={onSearch}
            />
          </div>
          <div className="page-content__right">
            <div className="page-wraper__header">
              <WithPermission permission={POLICY.WRITE_JOB}>
                <Button
                  onClick={() => onOpen()}
                  type="primary"
                  style={{ float: 'right' }}
                >
                  {' '}
                  Thêm mới
                </Button>
              </WithPermission>
            </div>

            <Table
              loading={isLoading}
              className="wh-category-table-striped-rows"
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={CoreValues}
              onChange={({ current, pageSize }) =>
                onParamChange({ page: current, limit: pageSize })
              }
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `,
                showSizeChanger: true
              }}
              size="small"
            />
          </div>
        </div>
      </div>
      <Modal
        title={(id ? 'Cập nhật' : 'Tạo mới') + ' giá trị cốt lõi'}
        visible={open}
        destroyOnClose
        centered
        footer={null}
        onCancel={onClose}
      >
        <CoreValueForm id={id} onCancel={onClose} />
      </Modal>
    </div>
  );
};

export default CoreValue;
